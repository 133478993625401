<template>
  <div>
    <sowings-planning-header :disabled-actions="disabledActions" />
    <div class="df-flex-l df-flex-col sowings-program-home">
      <section class="d-flex align-center home__filters">
        <p class="filter__item">{{ $t('SowingsPlanning.home.filter_by') }}:</p>
        <df-filter-select
          ref="periodFilter"
          v-model="filteredPeriod"
          :disabled="disabledActions"
          :items="periods"
          :label="$t('SowingsPlanning.home.period')"
          :loading="loadingPeriod"
          @click="selectItem"
        />
        <df-filter-select
          ref="fieldFilter"
          v-model="filteredFields"
          clearable
          multiple
          search-bar
          :disabled="disabledActions"
          :items="formattedFields"
          :loading="loadingFields"
          :placeholder="$t('SowingsPlanning.home.field')"
          :search-placeholder="$t('SowingsPlanning.search_for_field')"
          @apply-filter="
            requestFieldSowingsPlanning(
              events.sowingsPlanningModule.SowingsPlanning.field_filter
            )
          "
        />
        <df-filter-select
          ref="planningFilter"
          v-model="filteredPlannings"
          clearable
          multiple
          :disabled="disabledActions"
          :items="plannings"
          :loading="loadingPlannings"
          :placeholder="$t('SowingsPlanning.home.plannings')"
          @apply-filter="
            requestFieldSowingsPlanning(
              events.sowingsPlanningModule.SowingsPlanning.planning_filter
            )
          "
        />
        <df-filter-select
          ref="cropFilter"
          v-model="filteredCrops"
          clearable
          multiple
          search-bar
          :disabled="disabledActions"
          :items="formattedCrops"
          :loading="loadingCrops"
          :placeholder="$t('SowingsPlanning.home.crop')"
          :search-placeholder="$t('SowingsPlanning.search_for_crop')"
          @apply-filter="
            requestFieldSowingsPlanning(
              events.sowingsPlanningModule.SowingsPlanning.crop_filter
            )
          "
        />
        <df-button
          v-if="showCleanFiltersButton"
          class="filters__clean"
          variant="text"
          @click="clearFilters"
        >
          {{ $t('SowingsPlanning.home.clean_filters') }}
        </df-button>
      </section>
      <section>
        <div v-if="loadingFieldSowingsPlanning" class="df-flex-l df-flex-col">
          <v-skeleton-loader
            v-for="(_, index) in fieldSowingsPlanning.length || 5"
            :key="index"
            height="68px"
            type="image"
            width="auto"
          />
        </div>
        <without-item-card
          v-else-if="!formattedFields.length"
          data-id="without-fields"
          name="add-field"
          text="SowingsPlanning.home.without_field_text"
          title="SowingsPlanning.home.without_field_title"
        />
        <without-item-card
          v-else-if="!fieldSowingsPlanning.length"
          name="filter"
          text="SowingsPlanning.filter_not_found_text"
          title="SowingsPlanning.filter_not_found_title"
        />
        <v-expansion-panels
          v-else
          class="df-flex-l df-flex-col"
          accordion
          flat
          multiple
        >
          <field-sowing-planning
            v-for="fieldSowingPlanning in fieldSowingsPlanning"
            :key="fieldSowingPlanning.field_id"
            :current-farm-id="currentFarmId"
            :data-id="'item' + fieldSowingPlanning.field_id"
            :field-sowing-planning="fieldSowingPlanning"
            :period-filter="filteredPeriod"
          />
        </v-expansion-panels>
      </section>
    </div>
    <custom-period-modal
      v-if="isCustomDateModalOpened"
      @apply-filter="filterCustomPeriod"
      @close="closeCustomDateModal"
    />
  </div>
</template>

<script>
const CustomPeriodModal = () =>
  import('@/modules/sowingsPlanning/components/modal/CustomPeriodModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfFilterSelect from '@/lib/components/FilterSelect/DfFilterSelect.vue'
import FieldSowingPlanning from '@/modules/sowingsPlanning/components/list/FieldSowingPlanning.vue'
import i18n from '@/language'
import SowingsPlanningHeader from '@/modules/sowingsPlanning/components/SowingsPlanningHeader.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

const INITIAL_FILTERED_PERIOD = {
  name: i18n.t('SowingsPlanning.home.filters.next_6_months'),
  isDefault: true,
  value: '6m',
}

export default {
  name: 'SowingsPlanningHome',

  components: {
    CustomPeriodModal,
    DfButton,
    DfFilterSelect,
    FieldSowingPlanning,
    SowingsPlanningHeader,
    WithoutItemCard,
  },

  data() {
    return {
      crops: [],
      events,
      fields: [],
      fieldSowingsPlanning: [],
      filteredCrops: [],
      filteredFields: [],
      filteredPeriod: INITIAL_FILTERED_PERIOD,
      filteredPlannings: [],
      formattedFields: [],
      isCustomDateModalOpened: false,
      loadingCrops: false,
      loadingFields: false,
      loadingFieldSowingsPlanning: false,
      loadingPeriod: false,
      loadingPlannings: false,
    }
  },

  provide() {
    return {
      getFields: this.getFields,
      getFormattedCrops: this.getFormattedCrops,
      getFormattedFields: this.getFormattedFields,
      requestFieldSowingsPlanning: this.requestFieldSowingsPlanning,
    }
  },

  async created() {
    logEvent(events.sowingsPlanningModule.SowingsPlanning.visited_page)
    this.loadingCrops = true
    this.loadingFields = true
    this.loadingFieldSowingsPlanning = true
    try {
      const fieldsParams = { farm_id: this.currentFarmId, is_active: true }
      const fieldSowingsPlanningParams = {
        farm_id: this.currentFarmId,
        date_period: this.filteredPeriod.value,
        group_by: ['field', 'variety'],
        include: 'programs',
        is_planning: true,
      }
      const [allCrops, allFields, allFieldSowingsPlanning] =
        await Promise.allSettled([
          sowingsPlanningService.getCrops(),
          sowingsPlanningService.getFields(fieldsParams),
          sowingsPlanningService.getFieldSowingsPlanning(
            fieldSowingsPlanningParams
          ),
        ])
      if (allFieldSowingsPlanning.status === 'fulfilled') {
        this.fieldSowingsPlanning = allFieldSowingsPlanning.value.data
      }
      if (allCrops.status === 'fulfilled') {
        this.crops = allCrops.value.data
      }
      if (allFields.status === 'fulfilled') {
        this.fields = allFields.value.data
        this.formattedFields = this.fields.map((field) => {
          return {
            name: field.name,
            value: String(field.id),
          }
        })
      }
    } catch (error) {
      console.error(error)
      this.$root.$emit(
        'notify',
        'error',
        this.$t('exception.unexpected_error_title'),
        this.$t('SowingsPlanning.exceptions.list_error')
      )
    } finally {
      this.loadingCrops = false
      this.loadingFields = false
      this.loadingFieldSowingsPlanning = false
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    disabledActions() {
      return (
        !this.fieldSowingsPlanning.length &&
        !(
          this.filteredFields.length ||
          this.filteredPlannings.length ||
          this.filteredCrops.length
        )
      )
    },
    formattedCrops() {
      return this.crops.map((crop) => {
        return {
          name: this.$t(crop.message_key),
          value: String(crop.id),
        }
      })
    },
    periods() {
      return [
        INITIAL_FILTERED_PERIOD,
        {
          name: this.$t('SowingsPlanning.home.filters.next_12_months'),
          isDefault: false,
          value: '12m',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.next_24_months'),
          isDefault: false,
          value: '24m',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.previous_sowings'),
          isDefault: false,
          value: 'previous_sowings',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.custom_date'),
          isDefault: false,
          value: 'custom_date',
        },
      ]
    },
    plannings() {
      return [
        {
          name: this.$t('SowingsPlanning.phytosanitary_category.disease'),
          value: 'disease',
        },
        {
          name: this.$t('SowingsPlanning.phytosanitary_category.insect'),
          value: 'insect',
        },
        {
          name: this.$t('SowingsPlanning.phytosanitary_category.nematode'),
          value: 'nematode',
        },
        {
          name: this.$t('SowingsPlanning.phytosanitary_category.weed'),
          value: 'weed',
        },
      ]
    },
    showCleanFiltersButton() {
      return (
        this.filteredPeriod.value != '6m' ||
        this.filteredFields.length ||
        this.filteredCrops.length ||
        this.filteredPlannings.length
      )
    },
  },

  methods: {
    clearFilters() {
      this.filteredPeriod = INITIAL_FILTERED_PERIOD
      this.$refs.periodFilter.cleanFilter(INITIAL_FILTERED_PERIOD)
      this.filteredFields = []
      this.$refs.fieldFilter.cleanFilter()
      this.filteredCrops = []
      this.$refs.cropFilter.cleanFilter()
      this.filteredPlannings = []
      this.$refs.planningFilter.cleanFilter()
      this.requestFieldSowingsPlanning()
    },
    closeCustomDateModal() {
      this.isCustomDateModalOpened = false
    },
    async filterCustomPeriod(period) {
      const startDate = this.moment(period.startDate).format('DD/MM/YY')
      const endDate = this.moment(period.endDate).format('DD/MM/YY')
      this.filteredPeriod.name = `${startDate} - ${endDate}`
      this.filteredPeriod.value = `${period.startDate}_${period.endDate}`
      await this.requestFieldSowingsPlanning()
      this.filteredPeriod.value = 'custom_date'
    },
    getFields() {
      return this.fields
    },
    getFormattedCrops() {
      return this.formattedCrops
    },
    getFormattedFields() {
      return this.formattedFields
    },
    async requestFieldSowingsPlanning(event = null) {
      if (event) logEvent(event)
      this.loadingCrops = true
      this.loadingFields = true
      this.loadingFieldSowingsPlanning = true
      this.loadingPeriod = true
      this.loadingPlannings = true
      const fieldSowingsPlanningParams = {
        crop_id: this.filteredCrops,
        date_period: this.filteredPeriod.value,
        farm_id: this.currentFarmId,
        field_id: this.filteredFields,
        program_categories: this.filteredPlannings,
      }
      try {
        const { data } = await sowingsPlanningService.getFieldSowingsPlanning(
          fieldSowingsPlanningParams
        )
        this.fieldSowingsPlanning = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingCrops = false
        this.loadingFields = false
        this.loadingFieldSowingsPlanning = false
        this.loadingPeriod = false
        this.loadingPlannings = false
      }
    },
    async selectItem({ value }) {
      logEvent(events.sowingsPlanningModule.SowingsPlanning.period_filter)
      if (value === 'custom_date') {
        this.isCustomDateModalOpened = true
      } else {
        this.$nextTick(() => this.requestFieldSowingsPlanning())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sowings-program-home {
  max-width: 1472px;
  padding: 0px 16px 32px 16px;
  margin: 0px auto;

  @include d(m) {
    padding: 0px 8px 16px 8px;
  }
  .home__filters {
    padding: 16px 0px;
    gap: 8px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
    .filter__item {
      flex: 0 0 auto;
    }
    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
::v-deep .filters__clean .v-btn__content .text-personalized {
  color: #39af49 !important;
}
</style>
