<template>
  <div
    class="program-sowing-infos"
    :style="{ maxHeight: maxHeight, minHeight: maxHeight }"
  >
    <div class="df-flex-xl df-flex-col">
      <section class="df-flex-none justify-space-between align-center">
        <h2>{{ geneticName }}</h2>
        <span>
          {{ $t('DiseasesControl.execute_program_modal.program_of') }}
          {{ programType }}
        </span>
      </section>
      <div class="divider"></div>
      <section class="df-flex-l df-flex-col">
        <h3>
          {{ $t('DiseasesControl.execute_program_modal.program_objective') }}
        </h3>
        <button-group
          :labels="buttonOptionsLabels"
          @change="handleSowingChange"
        />
        <choose-sowing-content
          v-if="chooseSowing"
          :field-sowings-list="fieldSowingsList"
          :loading-field-sowing-list="loadingFieldSowingList"
        />
        <new-sowing-content
          ref="newSowingContent"
          v-else
          :crop-id="cropId"
          :variety-id="program.variety_id"
        />
      </section>
    </div>
  </div>
</template>

<script>
import ChooseSowingContent from '@/modules/diseasesControl/components/modal/ChooseSowingContent.vue'
import NewSowingContent from '@/modules/diseasesControl/components/modal/NewSowingContent.vue'
import sowingsListService from '@/modules/sowingsList/services/sowingsListService'
import { getCustomDate } from '@/utils/time'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import '@/components/ButtonGroup/ButtonGroup.js'

export default {
  name: 'ProgramSowingInfos',

  components: {
    ChooseSowingContent,
    NewSowingContent,
  },

  props: {
    maxHeight: {
      required: true,
      type: String,
    },
    program: {
      required: true,
      type: Object,
    },
  },

  inject: {
    getVarietyId: { from: 'getVarietyId' },
  },

  data() {
    return {
      chooseSowing: true,
      fieldSowingsList: [],
      loadingFieldSowingList: false,
    }
  },

  created() {
    this.getFieldSowingList()
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    buttonOptionsLabels() {
      return JSON.stringify([
        this.$t('DiseasesControl.execute_program_modal.choose_sowing'),
        this.$t('DiseasesControl.execute_program_modal.new_sowing'),
      ])
    },
    cropId() {
      return this.fieldSowingsList[0].sowings[0].crop_id
    },
    geneticName() {
      let genetic = this.$t('DiseasesControl.execute_program_modal.loading')
      if (this.fieldSowingsList.length) {
        const sowing = this.fieldSowingsList[0].sowings[0]
        genetic = `${this.$t(sowing.crop_message_key)} | ${sowing.variety_name}`
      }
      return genetic
    },
    programType() {
      return this.$t(
        `DiseasesControl.execute_program_modal.${this.program.sprayings[0].type}`
      )
    },
  },

  methods: {
    async getFieldSowingList() {
      this.loadingFieldSowingList = true
      const fieldSowingsListParams = {
        farm_id: this.currentFarmId,
        date_period: `${getCustomDate(-180)}_${getCustomDate(300)}`,
        variety_id: this.getVarietyId(),
      }
      try {
        const { data } = await sowingsListService.getFieldSowingsList(
          fieldSowingsListParams
        )
        this.fieldSowingsList = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingFieldSowingList = false
      }
    },
    handleSowingChange(option) {
      if (
        option.detail ===
        this.$t('DiseasesControl.execute_program_modal.choose_sowing')
      ) {
        logEvent(events.diseasesControlModule.associate_sowing)
        this.chooseSowing = true
        this.getFieldSowingList()
      } else {
        logEvent(events.diseasesControlModule.new_sowing_date)
        this.chooseSowing = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.program-sowing-infos {
  padding: 24px;
  overflow-y: auto;

  @include d(m) {
    padding: 24px 12px;
    overflow-y: none;
  }

  h2 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  span {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .divider {
    height: 1px;
    border-bottom: 1px dashed #e6e9e6;
  }
  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
</style>
