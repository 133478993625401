import http from '@/utils/http'
import {
  farmIdQuery,
  queryBuilder,
  sameNameParamQuery,
} from '@/utils/apiQueryBuilder'

const sowingsList = {
  getFieldSowingsList: ({
    crop_id,
    date_period,
    farm_id,
    field_id,
    status,
    variety_id,
  }) =>
    http.get(
      `/season-sowings${queryBuilder(
        farmIdQuery(farm_id),
        sameNameParamQuery({ date_period }),
        sameNameParamQuery({ crop_id }),
        sameNameParamQuery({ field_id }),
        sameNameParamQuery({ status }),
        sameNameParamQuery({ variety_id })
      )}`
    ),
  getCrops: () => http.get('/crops'),
  getFields: ({ farm_id }) => {
    const baseUrl = `/fields${queryBuilder(farmIdQuery(farm_id))}`
    return http.get(baseUrl)
  },
  getHarvestDateLimits: ({ sowing_id }) =>
    http.get(
      `harvest-date-limits${queryBuilder(sameNameParamQuery({ sowing_id }))}`
    ),
  editSowing: ({ crop_zone, sowing_id, task }) => {
    return http.patch(`/season-sowing/${sowing_id}`, {
      crop_zone,
      task,
    })
  },
  createExecutedSowing: ({ crop_zone, task }) => {
    return http.post('/season-sowing', {
      crop_zone,
      task,
    })
  },
  copyExecutedSowing: ({ field_ids, sowing_id }) => {
    return http.post(`/season-sowing/${sowing_id}/copy`, {
      // CM: ver parametros que backend precisa
      // is_planning: is_planning,
      // estimated_start_date: sowing_date,
      // estimated_completed_date: sowing_date,
      field_id: field_ids,
    })
  },
  registerHarvest: ({
    desiccation,
    harvest_date,
    lost_harvest,
    quantity,
    sowing_id,
  }) => {
    // CM: ver qual a url correta
    return http.post(`/register-harvest/${sowing_id}`, {
      // CM: ver parametros que backend precisa
      desiccation,
      harvest_date,
      lost_harvest,
      quantity,
    })
  },
  deleteSowing: ({ sowing_id }) => {
    // CM: ver qual a url correta
    return http.delete(`/delete-season-sowing/${sowing_id}`)
  },
}

export default sowingsList
