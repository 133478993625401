<template>
  <div>
    <v-form ref="form" class="df-flex-xl df-flex-col sowing-modal">
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-select
            v-model="selectedFields"
            clearable
            multiple
            search-bar
            :disabled="isEditing"
            :items="fields"
            :label="$t('SowingsPlanning.sowing_form.field')"
            :placeholder="$t('SowingsPlanning.sowing_form.select_one_field')"
            :search-placeholder="$t('SowingsPlanning.search_for_field')"
            :rules="[required]"
          />
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-select
            v-model="selectedCrop"
            search-bar
            :disabled="isEditing"
            :items="crops"
            :label="$t('SowingsPlanning.sowing_form.crop')"
            :placeholder="$t('SowingsPlanning.sowing_form.select_one_crop')"
            :search-placeholder="$t('SowingsPlanning.search_for_crop')"
            :rules="[required]"
            @click="cropSelected"
          />
        </div>
      </div>
      <div class="df-flex-l df-flex-col">
        <div class="df-flex-xm df-flex-col">
          <df-select
            v-if="isCropSoybeanOrWheat"
            v-model="selectedGenetic"
            search-bar
            :disabled="shouldDisableGenetic"
            :items="filteredGenetics"
            :label="$t('SowingsPlanning.sowing_form.genetic')"
            :loading="loadingGenetics"
            :placeholder="$t('SowingsPlanning.sowing_form.select_one_genetic')"
            :search-placeholder="$t('SowingsPlanning.search_for_crop')"
            :rules="[required]"
            @click="fetchEstimatedCycle"
          />
          <df-input
            v-else
            v-model="genetic"
            :disabled="isEditing"
            :label="$t('SowingsPlanning.sowing_form.genetic')"
            :placeholder="$t('SowingsPlanning.sowing_form.insert_genetic')"
            :rules="[required]"
          />
          <div>
            <span class="genetic__infos">
              {{ $t('SowingsPlanning.sowing_form.genetic_infos') }}
            </span>
            <a class="genetic__modal" @click="openCatalog">
              {{ $t('SowingsPlanning.sowing_form.click_here') }}
            </a>
          </div>
        </div>
        <div
          v-if="isCropSoybeanOrWheat"
          class="df-flex-sm align-center form__genetic-not-found"
        >
          <font-awesome-icon icon="dna" />
          <p>
            {{ $t('SowingsPlanning.sowing_form.genetic_not_found') }}
          </p>
          <df-button variant="text-green" @click="openRequestGeneticsModal">
            {{ $t('SowingsPlanning.sowing_form.request') }}
          </df-button>
        </div>
        <df-input
          v-else
          v-model="companyName"
          :disabled="isEditing"
          :label="$t('SowingsPlanning.sowing_form.company')"
          :placeholder="$t('SowingsPlanning.sowing_form.insert_company')"
        />
      </div>
      <slot name="sowing-info"></slot>
      <div class="df-flex-l">
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <slot name="secondary-sowing-date">
            <df-date-picker
              v-model="sowingEstimatedDate"
              :allowed-dates="allowedDates"
              :disabled="shouldDisableSowingEstimatedDate"
              :events="eventDates"
              :hint="
                $t(
                  'SowingsPlanning.date_picker_component.estimated_sowing_hint'
                )
              "
              :label="
                $t(
                  'SowingsPlanning.date_picker_component.estimated_sowing_date'
                )
              "
              :loading="loadingGeneticInfos"
              :placeholder="
                $t('SowingsPlanning.date_picker_component.select_date')
              "
              :max="maxEstimatedSowingDate"
              :min="minEstimatedSowingDate"
              :rules="[required]"
            >
              <template #caption>
                <div class="df-flex-xm df-flex-col align-start date__caption">
                  <div class="df-flex-m align-center justify-end">
                    <div class="green__circle"></div>
                    <p>
                      {{ $t('SowingsPlanning.sowing_form.recommended_dates') }}
                    </p>
                  </div>
                  <div class="df-flex-m align-center justify-end">
                    <div class="yellow__circle"></div>
                    <p>
                      {{
                        $t('SowingsPlanning.sowing_form.has_existing_sowing')
                      }}
                    </p>
                  </div>
                </div>
              </template>
            </df-date-picker>
          </slot>
        </div>
        <div class="df-col-6 df-col-12-t df-col-12-m">
          <df-input
            v-model="cycle"
            type="number"
            :disabled="shouldDisableCycle"
            :label="$t('SowingsPlanning.sowing_form.estimated_cycle')"
            :loading="loadingGeneticInfos"
            :placeholder="
              $t('SowingsPlanning.sowing_form.enter_estimated_cycle')
            "
            :suffix="$t('SowingsPlanning.sowing_form.days')"
            :rules="[required]"
          />
        </div>
      </div>
    </v-form>
    <request-genetic-modal
      v-if="isGeneticModalOpen"
      @close="closeRequestGeneticModal"
      @close-both-modals="closeAll"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfSelect from '@/lib/components/Select/DfSelect.vue'
import RequestGeneticModal from '@/modules/sowingsPlanning/components/modal/RequestGeneticModal.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { SOYBEAN_ID, WHEAT_ID } from '@/utils/crops'
import { required } from '@/utils/formRules'
import { isDateBetween } from '@/utils/time'
import { mapGetters } from 'vuex'

export default {
  name: 'SowingForm',

  components: {
    DfButton,
    DfDatePicker,
    DfInput,
    DfSelect,
    RequestGeneticModal,
  },

  props: {
    sowingId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      companyName: null,
      crops: [],
      cycle: null,
      datesWithPlanningSowing: [],
      fields: [],
      filteredGenetics: [],
      formattedOriginalGenetics: [],
      genetic: null,
      isCropSoybeanOrWheat: true,
      isGeneticModalOpen: false,
      loadingEstimatedCycle: false,
      loadingEstimatedSowingDates: false,
      loadingGenetics: false,
      loadingExistingSowings: false,
      maxEstimatedSowingDate: '',
      maxRecommendedDate: '',
      minEstimatedSowingDate: '',
      minRecommendedDate: '',
      originalGenetics: [],
      required,
      selectedCrop: {},
      selectedFields: [],
      selectedGenetic: {},
      sowingEstimatedDate: null,
    }
  },

  inject: {
    fieldObj: {
      default: {},
      from: 'fieldObj',
    },
    getFormattedCrops: {
      default: () => {},
      from: 'getFormattedCrops',
    },
    getFormattedFields: {
      default: () => {},
      from: 'getFormattedFields',
    },
  },

  async created() {
    this.loadingGenetics = true
    this.$emit('loading', true)
    if (this.getFormattedCrops) this.crops = this.getFormattedCrops()
    else this.fetchFormattedCrops()
    if (this.getFormattedFields) this.fields = this.getFormattedFields()
    else this.fetchFormattedFields()
    try {
      const { data } = await sowingsPlanningService.getGenetics()
      this.originalGenetics = data
      this.formattedOriginalGenetics = this.originalGenetics.map((genetic) => {
        return {
          name: genetic.name,
          value: String(genetic.id),
        }
      })
      this.filteredGenetics = [...this.formattedOriginalGenetics]
      if (this.isEditing) {
        const params = {
          sowing_id: this.sowingId,
        }
        const currentSowing =
          await sowingsPlanningService.getFieldSowingsPlanning(params)
        this.$emit('fill-input-fields', currentSowing)
        this.fillInputFields(currentSowing)
      } else if (this.fieldObj && this.fieldObj.value) {
        this.selectedFields.push(this.fieldObj)
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingGenetics = false
      this.$emit('loading', false)
    }
  },

  watch: {
    async selectedFields() {
      if (
        this.selectedFields.length &&
        this.selectedGenetic.value &&
        !this.isEditing
      )
        await this.fetchGeneticInfos()
    },
    async selectedGenetic() {
      if (
        this.selectedFields.length &&
        this.selectedGenetic.value &&
        !this.isEditing
      )
        await this.fetchGeneticInfos()
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('user', ['currentUser']),
    isEditing() {
      return !!this.sowingId
    },
    loadingGeneticInfos() {
      return (
        this.loadingEstimatedCycle ||
        this.loadingExistingSowings ||
        this.loadingEstimatedSowingDates
      )
    },
    shouldDisableGenetic() {
      return this.isEditing || !this.selectedCrop.value
    },
    shouldDisableSowingEstimatedDate() {
      return (
        (!this.selectedGenetic.value && !this.genetic) ||
        !this.selectedFields.length
      )
    },
    shouldDisableCycle() {
      return !this.selectedGenetic.value && !this.genetic
    },
  },

  methods: {
    allowedDates(date) {
      return (
        !this.datesWithPlanningSowing.includes(date) &&
        isDateBetween(
          date,
          0,
          0,
          this.minEstimatedSowingDate,
          this.maxEstimatedSowingDate
        )
      )
    },
    closeAll() {
      this.closeRequestGeneticModal()
      this.$emit('close')
    },
    closeRequestGeneticModal() {
      this.isGeneticModalOpen = false
    },
    cropSelected(crop) {
      if (crop?.value) {
        this.$emit('crop-selected', this.selectedCrop.value)
        if (crop.value == SOYBEAN_ID || crop.value == WHEAT_ID) {
          this.filteredGenetics = this.originalGenetics.reduce(
            (acc, genetic) => {
              if (genetic.crop_id == crop.value) {
                acc.push({
                  name: genetic.name,
                  value: String(genetic.id),
                })
              }
              return acc
            },
            []
          )
          this.isCropSoybeanOrWheat = true
        } else {
          this.isCropSoybeanOrWheat = false
        }
      } else {
        this.filteredGenetics = [...this.formattedOriginalGenetics]
      }
    },
    eventDates(date) {
      const currentDate = new Date(date)
      const maxDate = new Date(this.maxRecommendedDate)
      const minDate = new Date(this.minRecommendedDate)

      const isInInterval = minDate <= currentDate && currentDate <= maxDate
      const hasSowing = this.datesWithPlanningSowing.includes(date)

      if (isInInterval && hasSowing) {
        return ['#39AF49', '#F2CD12']
      } else if (isInInterval) {
        return '#39AF49'
      } else if (hasSowing) {
        return '#F2CD12'
      }
    },
    async fetchEstimatedCycle() {
      this.loadingEstimatedCycle = false
      const params = {
        variety_id: this.selectedGenetic.value,
      }
      try {
        const { data } = await sowingsPlanningService.getEstimatedCycle(params)
        this.cycle = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingEstimatedCycle = false
      }
    },
    async fetchEstimatedSowingDates() {
      this.loadingEstimatedSowingDates = true
      const params = {
        variety_id: this.selectedGenetic.value,
      }
      try {
        const { data } = await sowingsPlanningService.getEstimatedSowingDates(
          params
        )
        this.minEstimatedSowingDate = data.limit_dates[0]
        this.maxEstimatedSowingDate = data.limit_dates[1]
        this.minRecommendedDate = data.recommended_dates[0]
        this.maxRecommendedDate = data.recommended_dates[1]
        this.$emit('estimated-sowing-dates', data)
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingEstimatedSowingDates = false
      }
    },
    async fetchExistingSowings() {
      this.loadingExistingSowings = true
      const fieldIds = this.selectedFields.map((field) => field.value)
      const params = {
        field_ids: fieldIds,
        variety_id: Number(this.selectedGenetic.value),
        range: [this.minEstimatedSowingDate, this.maxEstimatedSowingDate],
      }
      try {
        const { data } = await sowingsPlanningService.getPlanningSowings(params)
        data.forEach((sowing) => {
          this.datesWithPlanningSowing.push(sowing.estimated_date)
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingExistingSowings = false
      }
    },
    async fetchFormattedCrops() {
      try {
        const { data } = await sowingsPlanningService.getCrops()
        this.crops = data.map((crop) => {
          return {
            name: this.$t(crop.message_key),
            value: String(crop.id),
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async fetchFormattedFields() {
      try {
        const fieldsParams = { farm_id: this.currentFarmId, is_active: true }
        const { data } = await sowingsPlanningService.getFields(fieldsParams)
        this.fields = data.map((field) => {
          return {
            name: field.name,
            value: String(field.id),
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async fetchGeneticInfos() {
      await this.fetchEstimatedSowingDates()
      await this.fetchExistingSowings()
    },
    async fillInputFields(sowing) {
      const cropZone = sowing.data.crop_zone
      const task = sowing.data.task
      this.selectedFields = this.fields.filter(
        (field) => field.value == cropZone.field_id
      )
      this.selectedCrop = this.crops.find(
        (crop) => crop.value == cropZone.crop_id
      )
      this.cropSelected(this.selectedCrop)
      this.selectedGenetic = this.filteredGenetics.find(
        (genetic) => genetic.value == cropZone.variety_id
      )
      await this.fetchGeneticInfos()
      this.sowingEstimatedDate = task.estimated_start_date
      this.cycle = task.extra_info.estimated_cycle
      this.companyName = task.extra_info.asset_owner_name
      this.genetic = task.extra_info.variety_name
    },
    openCatalog() {
      this.$router.push({
        path: '/catalog',
        query: {
          category: 'variety',
        },
      })
    },
    openRequestGeneticsModal() {
      this.isGeneticModalOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-modal {
  .genetic__infos {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .genetic__modal {
    color: #39af49;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}
.form__genetic-not-found {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e6e9e6;
  flex-wrap: nowrap;

  p {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.date__caption {
  .green__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #39af49;
  }
  .yellow__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #f2cd12;
  }
  p {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
::v-deep .v-card__text {
  padding: 0px !important;
}
</style>
