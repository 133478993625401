import authModuleTranslation from '@/modules/auth/translation'
import catalogModuleTranslations from '@/modules/catalog/translation'
import comparisonTranslations from '@/modules/comparison/translation'
import diseasesControlTranslations from '@/modules/diseasesControl/translation'
import homeTranslations from '@/modules/home/translation'
import menuModuleTranslations from '@/modules/menu/translation'
import notesModuleTranslations from '@/modules/notes/translation'
import performanceTranslations from '@/modules/performance/translation'
import plansModuleTranslations from '@/modules/plans/translation'
import plotModuleTranslations from '@/modules/plot/translation'
import reportTranslations from '@/modules/reports/translation'
import planningTranslations from '@/modules/sowingsPlanning/translation'
import sowingsTranslations from '@/modules/sowingView/translation'
import sowingsListTranslations from '@/modules/sowingsList/translation'
import bannersTranslations from '@/components/Banners/translation'
import digifarmzComponentsTranslations from '@/lib/components/translation'
import modalTranslations from '@/lib/Modal/translation'
import weatherTranslations from '@/components/Weather/translation'

export default {
  // modules
  ...authModuleTranslation.en,
  ...catalogModuleTranslations.en,
  ...comparisonTranslations.en,
  ...diseasesControlTranslations.en,
  ...homeTranslations.en,
  ...menuModuleTranslations.en,
  ...notesModuleTranslations.en,
  ...performanceTranslations.en,
  ...plansModuleTranslations.en,
  ...plotModuleTranslations.en,
  ...reportTranslations.en,
  ...planningTranslations.en,
  ...sowingsTranslations.en,
  ...sowingsListTranslations.en,
  // components
  ...bannersTranslations.en,
  ...digifarmzComponentsTranslations.en,
  ...modalTranslations.en,
  ...weatherTranslations.en,

  $vuetify: {
    badge: 'Seal',
    close: 'Close',
    loading: 'Loading',
    dataIterator: {
      noResultsText: 'No results found',
      loadingText: 'Loading items...',
    },
    dataTable: {
      itemsPerPageText: 'Lanes per page:',
      ariaLabel: {
        sortDescending: 'Descending order.',
        sortAscending: 'Growing order.',
        sortNone: 'Unordered.',
        activateNone: 'Unactivated.',
        activateDescending: 'Activate to descending order.',
        activateAscending: 'Activate to growing order.',
      },
      sortBy: 'Order by',
    },
    dataFooter: {
      itemsPerPageText: 'Itens per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} over {2}',
    },
    datePicker: {
      itemsSelected: '{0} selected(s)',
      prevYearAriaLabel: 'Previous year',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      nextMonthAriaLabel: 'Next month',
    },
    input: {
      clear: 'Clear',
    },
    noDataText: 'No available data',
    carousel: {
      prev: 'Previous view',
      next: 'Next view',
      ariaLabel: {
        delimiter: 'Slide {0} over {1} from carousel',
      },
    },
    calendar: {
      moreEvents: 'Coming up {0}',
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files (over a total of {1})',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
  },

  // Digifarmz
  language: {
    pt: 'Português',
    es: 'Español',
    en: 'English',
  },
  yes: 'Yes',
  no: 'No',
  or: 'Or',
  close: 'Close',
  password_recovery: {
    temporary_confirmation:
      'Have you requested a password recovery? The temporary password will expire in 1 day. Click "yes" to update your password.',
  },
  configurations: 'Configurations',
  loading: 'Loading...',
  filter: 'Filter',
  category: 'Category',
  register_: 'Register',
  clean_filter: 'Clean filter',
  results_for: 'Results for',
  results: 'Results found',
  sort: 'Sort',
  pageText: 'Page {0} over {1}',
  limpar: 'Wipe out',
  cancelar: 'Cancel',
  excluir: 'Delete',
  editar: 'Edit',
  ver: 'View',
  copiar: 'Copy',
  copiar_para: 'Copy to...',
  salvar: 'Save',
  confirmar: 'Confirm',
  enviar: 'Submit',
  feito: 'Done',
  importar: 'Import',
  criar: 'Create',
  back: 'Back',
  next: 'Next',
  area: 'Area',
  sair: 'Leave',
  saca: 'Bushel',
  get_in_touch: 'Get in touch',
  which_channel_to_contact:
    'Which channel would you like to get in touch with?',
  commercial: 'Commercial',
  support: 'Support',
  relationship: 'Relationship',
  adicionar: 'Add',
  select: 'Select',
  desfazer: 'Undo',
  enable: 'Enable',
  function_temporarily_unavailable: 'Function temporarily unavailable',
  disable: 'Disable',
  she_enabled: 'Enabled',
  she_disabled: 'Disabled',
  he_enabled: 'Enabled',
  he_disabled: 'Disabled',
  enabled_s: 'Enabled',
  disabled_s: 'Disabled',
  she_disable: 'Disable',
  he_disable: 'Disable',
  utilized: 'Utilized',
  contracted: 'Contracted',
  total_area: 'Total Area',
  select_date: 'Select date',
  total: 'Total',
  quote: 'Quote',
  active: 'Active',
  inactive: 'Inactive',
  select_period: 'Choose a period',
  select_another_period: 'Choose another period',
  select_file: 'Select file',
  upload_file: 'Upload file',
  semeadura_em: 'Planting in {0}',
  emergencia_em: 'Emerge in',
  emergency_date: 'Emergence date',
  custo_total_hectares: 'Total cost per acre',
  aplicado_em: 'Applied on',
  desconhecido: 'Unknown',
  campo_obrigatorio: 'Required field',
  date_should_be_after: 'Date should be after',
  set_start_date: 'Set start date',
  required: ' *Required',
  nome_muito_longo: 'Too long name',
  preencha_corretamente_campos: 'Fill required field correctly',
  apply_filter: 'Apply filter',
  aplicar: 'Apply',
  nome: 'Name',
  title: 'Title',
  description: 'Description',
  email: 'Email',
  profile: 'Profile',
  alta: 'High',
  media: 'Moderate',
  baixa: 'Low',
  moeda: 'US$',
  price: 'Price',
  required_price: 'Price is required',
  succesfuly_saved: 'Saved successfully',
  data_ideal: 'Spray time',
  until: 'until',
  day: 'day',
  days: 'days',
  dias_de_atraso: '{0} delayed days',
  sem_registro: 'Unregistered fungicides',
  one_question_before_continue: 'One question before continuing',
  what_is_your_job_title_today: 'What is your role today?',
  we_want_to_know_to_improve_your_experience:
    'We want to know to improve your experience',
  date: 'Date',
  dias_semana: {
    hoje: 'today',
    ter: 'tuesday',
    qua: 'wednesday',
    qui: 'thursday',
    sex: 'friday',
    sab: 'saturday',
    dom: 'sunday',
  },
  of: 'of',
  created_by: 'Created by',
  edited_by: 'Edited by',
  print: 'Print',
  priority: 'priority',
  secondary: 'secondary',
  learn_more: 'Learn more',
  did: 'Did the',
  used: 'Used',
  _to_: '{0} to {1}',
  thousand: 'thousand',
  tour: {
    notes_navbar: 'You can access your notes through this menu.',
    filter_notes:
      'You can filter your notes by <strong>category, field, date and user</strong>.',
    create_note: 'To create a new note, click on the new note button.',
    menu_changed: 'The menu has changed, now you can access here.',
    user_menu: 'Here you can access information about your account',
    skip: 'Leave the tour',
    end: 'End',
  },
  message_area_exceeded:
    'You have exceeded your contracted area. Disable farms and fields or hire more area.',
  message_farm_disabled:
    'This farm is disabled, you will not be able to edit or create anything. Enable to use',
  closure: 'Closing',
  bought: 'Bought',
  own: 'Own',
  localization: 'Location',
  vazio_sanitario: {
    vazio_sanitario: 'Sanitary break',
    disable_navbar_btn: 'Season off due to sanitary break',
    termino_para_semeadura:
      'Waiting ending of sanitary break to start planting',
  },
  indicator: 'Indicator',
  square: 'Square',
  dollar_rate: 'Dollar',
  physical_market: 'Physical soybean supply',
  stock_chicago: 'CME Chicago',
  commodity: 'Commodity',

  toolbar: {
    title_modal_crop_bag_price: 'Crop price',
    price_per_seed: 'Bushels',
  },

  no_data_available: 'No available data',
  data_available_to_farm_enabled: 'Available data only to enabled farm',
  register: {
    register: 'Register',
    price: 'Register price',
    bag_price: 'Register bushel crop price',
    secundary_title_whitout_bag_price: 'Before register',
    secundary_text_whitout_bag_price:
      'To use season step, you need to register the crop price first. With this value you can analyze the cost-benefit of programs',
    secundary_title_did_not_fill_all_harvest:
      'Some seedings are not filled. Do you want to continue anyway?',
    after: 'Yes, register later',
    sowing: 'Create sowing',
    sowing_register: 'Register sowing',
    register_sowing: 'Register your sowings',
    secundary_title_whitout_sowing: 'Register sowings',
    secundary_text_whitout_sowing:
      'To follow your aplications and dates, first, you need to register your sowings at Digifarmz by clicking the button below',
  },

  plan: {
    upgrade_plan: 'Upgrade your plan',
    menu: {
      my_farm: 'My farm',
      planting: 'Plantings',
      notes: 'Notes',
      planning: 'Planning',
      performance: 'Performance',
      catalog: 'Catalog',
      plot: 'Plot',
      no_result: 'No results',
    },
  },

  weather: {
    info: 'Weather information',
    without_weather: 'We are collecting your weather data',
    wait_to_load: 'Wait up to 24 hours to receive weather information',
  },

  crop: {
    crop: 'Crop',
    soybean: 'Soybean',
    wheat: 'Wheat',
    corn: 'Corn',
    save: {
      price: {
        success: 'Price saved successfully',
        error: 'Failure on saving price!',
      },
    },
  },

  profiles: {
    ADMIN: 'General Administrator',
    CONTA: 'Account Administrator',
    FARM: 'Agricultural Engineer',
    ASSISTENTE_TECNICO: 'Observer',
    FINANCEIRO: 'Financial',
    COMERCIAL: 'Commercial',
    ADM_CORP: 'Corp Administrator',
    VEND_CORP: 'Corp Salesperson',
    VEND_DGF: 'Coupon Manager',
    TEC_DGF: 'Rational Manager',
    CON_REV: 'Resale Consultant',
    PESQUISA: 'Research',
    description: {
      ADMIN: 'Can edit, view and create all',
      CONTA: 'Can edit, view and create all',
      FARM: 'Can edit all, except farms and plots',
      ASSISTENTE_TECNICO: 'View only',
      FINANCEIRO: 'Financial',
      COMERCIAL: 'Commercial',
      ADM_CORP: 'Corp Administrator',
      VEND_CORP: 'Corp Salesperson',
      VEND_DGF: 'Coupon Manager',
      TEC_DGF: 'Rational Manager',
      CON_REV: 'Can edit, view and create, except users',
      PESQUISA:
        'Can view users, create and edit programs, sowings, farms and plots',
    },
  },

  files: {
    type_files_allowed:
      'Allowed file types: jpg, jpeg, png, heic, xlsx, xls, pdf, doc, docx',
    size_files_allowed: 'Maximum file size: 5MB',
  },

  variety: {
    list: 'LIST OF VARIETIES',
    hide_created: 'Hide created',
    show_created: 'Show created',
    new: 'New variety',
    variety_info: 'Variety informations',
    create: 'New {0} variety',
    already_created: 'ALREADY CREATED',
    select_variety: 'Select a variety to get detailed information',
    create_selected: 'Create selected',
    unavailable_create: 'UNAVAILABLE TO CREATE',
    unavailable_wheat_region_title:
      'This region is not able to {0} cultivation',
    unavailable_wheat_region_complement:
      'Your region is not recommended to {0} cultivation due to the agroclimatic zoning of this crop',
    not_recommended: 'NOT RECOMMENDED TO YOUR REGION',
    sort: {
      seed_name_az: 'name (A-Z)',
      seed_name_za: 'name (Z-A)',
      GMR_small: 'GMR (Smallest)',
      GMR_strong: 'GMR (Strongest)',
      owner_az: 'Company (A-Z)',
      owner_za: 'Company (Z-A)',
    },
    seed_name: 'Variety name',
    owner: 'Owner',
    request_variety: 'Request variety',
    request_not_catologed_variety: 'Request not cataloged variety',
    not_cataloged_variety: 'Variety not cataloged',
    no_result:
      'If you didn´t find the variety you were looking for, click the button below and make a request',
    no_result_title: 'No results found',
    planned: 'ALREADY PLANNED VARIETIES',
    not_planned: 'OTHER VARIETIES',
  },

  job_titles: {
    farm_administrator: 'Farm Administrator',
    agricultural_technical_consultancy: 'Agricultural Technical Consultancy',
    manager: 'Manager',
    agricultural_engineering_consultancy:
      'Agricultural Engineering Consultancy',
    machine_operator: 'Machine Operator',
    operation_supervisor: 'Operation Supervisor',
    dealer: 'Dealer',
    researcher: 'Researcher',
    student: 'Student',
    other: 'Other',
    manager_admin_digifarmz: 'Digifarmz administrator',
  },

  season: {
    version_previous: 'Previous crop season versions',
    version_actual: 'Current version',
    version: 'Version',
  },

  safra: {
    actual: 'Current crop season',
    previous: 'Previous crop season',
    safra: 'Season',
    inativa: 'Inactive season.',
    alterar_safra: 'Change crop season',
    contratar_safra: 'Contract crop season',
    register_previous_season: 'Register Previous season',
  },
  anterior: 'previous',
  previous: 'previous',
  permissao: {
    erro: 'Error loading permission!',
  },
  ndvi: {
    dates_for_analysis: 'Images for analysis',
    list: {
      no_content:
        'No image has been found for analysis in the selected period.',
    },
    level_label: 'NDVI level:',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    know_more: 'learn more about NDVI',
  },
  integration: {
    integrations: 'Integrations',
    sync: 'Update again',
    sync_manual: 'Update manually',
    sync_success: 'successful synchronism.',
    sync_alert:
      'Farms and plots information still being uploaded from <strong>Climate FieldView</strong> Upload may take a few hours.',
    undo_integration: 'Undo integration',
    error: {
      title: 'Ops, looks like something went wrong.',
      title_exceeded: 'Uploading farms and plots was not possible',
      description: 'Upload process got some issues. Please, try again.',
      description_exceeded:
        'Looks like the total area amount of plots exceeds the purchased amount. But don’t worry! Just contact our sales representative and ask for an upgrade.',
      upgrade: 'Upgrade area',
      try_again: 'Try again',
    },
    fieldview: {
      fieldview: 'Climate FieldView&trade;',
      title_part: 'Connect your account with',
      title_success: 'Integration performed successfully',
      description:
        'In order to link, click below and log in your Climate Field View account. You need to allow the connection',
      description_2:
        'FR0M YOUR ACCOUNT, WE WILL BRING THE FOLLOWING INFORMATION',
      description_success:
        'Upload of Farms still being uploaded from <b>Climate Fieldview</b>  This process may take a few hours. Click refresh at any time.',
      list: {
        farms: 'Farms',
        plots: 'Plots',
      },
      btnBurger: 'Integrate to Climate FieldView&trade;',
      btn: 'Integrate to Climate FieldView&trade',
      btnStart: 'Back to farms',
    },
  },

  user: {
    user: 'Users',
    users: 'User',
    register: 'Register user',
    edit: 'Edit user',
    register_success: 'User registered successfully',
    delete_confirm: 'Are you sure you want to delete this user?',
    delete_success: 'User deleted successfully',
  },

  farm: {
    farm: 'Farm',
    access_farm: 'Access farm',
    my_farm: 'My farm',
    select_farm: 'Select farm',
    see_all_farms: 'See all farms',
  },

  fazenda: {
    NoFarms: {
      welcome_no_farms: "You don't have",
      welcome_no_farms_bold: 'farms registered at this time',
    },
    welcome_no_farms: '<b>No farms registered at this time</b>',
    fazendas: 'Farms',
    excluir: {
      sucesso: 'Farm deleted successfully',
      erro: 'Error to delete farm',
      alerta: {
        titulo: 'Are you sure?',
        mensagem:
          'By removing this farm, all associated programs will be lost and it will not be possible to revert',
      },
    },
    listar: {
      erro: 'Error in farm listing',
    },
    carregar: {
      erro: 'Error in loading farms',
      erro_400: 'Error (400) in loading programs',
    },
    salvar: {
      sucesso: 'Farm saved with success',
      erro: 'Error in saving farm',
    },
    enable: {
      success: 'Farm enabled successfully.',
      warning:
        "When disabling the plot, you won't be able to create new sowings. Do you still want to continue?",
      error:
        'You can no longer enable farms or fields. If you still want to enable it, please contact our support team.',
    },
    disable: {
      success: 'Farm disabled successfully.',
      warning:
        "When disabling the plot, you won't be able to create new sowings. Do you still want to continue?",
    },

    farm_disabled: 'Farm disabled',
    farm_disabled_message_banner_text1: 'Enable farm to register',
    farm_disabled_message_banner_text2:
      'You will only be able to register if your farm is enabled. Click the button below to manage farms.',
    manager_farms: 'Manage farms',
    cadatro_fazenda: 'Farm registration',
    nome_da_fazenda: 'Farm name',
    preco_por_saca: 'Price per bushel',
    create_farm: 'Register farm',
    manual_create_farm: 'Create farm</br>manually',
  },

  cultivar: {
    cultivar: 'Variety',
    gmr: 'GMR',
    populacao: 'Plant population',
    populacao_entre: '{0} - {1} thousand',
    epoca_semeadura: 'Sowing time',
    ciclo: 'Cycle',
    ciclo_dias: '{0} days',
    regiao_adaptativa: 'Adaptive region growing',
    titular: 'Owner',
    asset_owner: 'Owner',
    habito_crescimento: 'Growth habit',
    growth_habit: {
      determinado: 'Determinate',
      semideterminado: 'Semi-Determinate',
      indeterminado: 'Indeterminate',
    },
    listar: {
      erro: 'Error in listing variety',
    },
    excluir: {
      sucesso: 'Variety successfully deleted',
      erro: 'Error in deleting variety',
    },
    salvar: {
      sucesso: 'Variety successfully saved',
      erro: 'error in saving variety',
    },
    solicitacao: {
      sucesso: 'Request successfully sent',
      erro: 'Error in sending request',
    },
    contem: {
      erro: 'Farm does not contain selected variety',
    },
    carregar: {
      erro: 'Error on loading variety',
    },
    copiar_para: {
      copiar_cultivar: 'Copy variety',
      help: 'The varieties copies are adapted to the selected farm. Each scenario might be different',
      selecione_fazenda:
        'To receive a copy of the variety must select the farm',
      selecione_as_fazendas: 'Select the farms',
      sucesso: 'Variety successfully copied',
      erro: 'Error copying variety',
    },
    solicitar: {
      msg: 'Request no cataloged variety',
      label: 'This variety is not cataloged',
    },
    not_recommended: 'Not recommended to this region',
    go_to_planning: 'Plan disease control',
    create_variety: 'Register variety',
  },

  seed: {
    seed: 'Seed',
    seed_grower: 'Seed grower',
    seed_class: 'Seed class',
    select_seed_class: 'Select the seed class...',
    select_seeder_system: 'Select the planting system...',
  },

  seed_class: {
    certified: 'Certified',
    inspected: 'Inspected',
    selected: 'Selected',
  },

  seeder_system: {
    perforated_disk: 'Disk seed plate',
    endless_thread: 'Endless thread',
    pinching_fingers: 'Gripping fingers',
    perforated_belt: 'Perforated strap',
    fluted_cylinder: 'Fluted cylinder',
    pneumatic_seeder: 'Pneumatic planter',
    others: 'Others',
  },

  pesticide: {
    pesticide: 'Pesticide',
  },

  adjuvant: {
    adjuvant: 'Adjuvant',
  },

  sowing: {
    new: 'New sowing',
    active_sowing: 'Active sowing',
    out_of_time: 'Out of time',
    message_heady_to_harvest:
      'Looks like you have <strong>{0} sowings</strong> ready to harvest. Record the harvest information to clear area and record new sowings on the field.',
    message_sowing_heady_to_harvest:
      'This sowing is already in the harvest season. <strong>Please register a harvest</strong>',
  },
  semeadura: {
    era_sowing: 'Sowing time',
    estimated_cycle_in_days: 'Estimated growing cycle (in days)',
    germinative_power_in_percentage: 'Seed germination (%)',
    seed_vigor_in_percentage: 'Seed Vigor (%)',
    line_spacing: 'Planting line spacing (in)',
    operating_speed: 'Operational velocity (mph)',
    population_lines: 'Plant population (plant per linear feet)',
    date_realized: 'Sowing time',
    system_sowing: 'Planter system',
    semeadura: 'Planting',
    carregar: {
      erro: 'Error loading sowing',
    },
    contem: {
      erro: 'Farm does not contain planting selected',
    },
    excluir: {
      sucesso: 'Planting excluded successfully',
      erro: 'Error in deleting planting',
    },
    data_fora_semeadura: 'Planting out of spray time',
    sowing_data: 'Planting data',
    operation_data: 'Operation data',
    origin: 'Origin',
    farm_sowing_time: 'Date outside the Sowing time of the farm',
    inactive_sowing_period: 'Inactive sowing period.',
    escolha_data_prevista: 'Planned date for planting',
    cadastro: {
      selecione_cultivar: 'Select variety',
      warning:
        'In case of associated program, dates, efficacies, and diseases will be recalculated',
      error: {
        estimated_cycle_min: 'Estimated cycle must be higher than 0',
        population_lines_min:
          'Number of plants in the line must be higher than 0',
        population_lines_max: 'Number of plants in the line must be up to 25',
        line_spacing_max: 'Line spacing must be up to 150',
        operating_speed_max: 'Operational velocity must be up to 15 mi/hr',
        rest_sown_area:
          'Planting area cannot be larger than the total lot area',
      },
      notify: {
        sown_area_is_null: 'Planting area is zero',
        quantity_area_is_null: 'Amount of planting area is zero',
        exceeded_area: 'Exceeded area',
      },
    },
    sown_area: 'Planting area (ac)',
    sown_quantity: 'Seed amount planted (bu/ac)',
    inoculant: 'Inoculant',
  },

  validations: {
    in_range: 'Value must be between {min} and {max}',
    max_value: 'Value must be lower than {max}',
    invalid_month: 'Invalid month',
    invalid_year: 'Invalid year',
    month_previous_than_current: 'Month previous than current',
    card_number_length: 'This input requires at least 14 digits',
    text_only: 'This input is text only',
    max_length: `This field has more than the limit of {0} characters`,
    invalid_email: 'Invalid e-mail',
    invalid_document_number: 'Invalid document number',
    document_number_length: 'Document number must have 11 digits',
    invalid_card_number: 'Invalid card number',
  },

  soybean: {
    soybean: 'Soybean',
    growth_habit: 'Growth habit',
    population: 'Plant population',
    seed_tratament: 'Seed treatment',
  },

  growth_habit: {
    determinate: 'Determinate',
    semi_determinate: 'Semi-Determinate',
    indeterminate: 'Indeterminate',
  },

  program: {
    planned_programs: 'Planned programs',
  },

  programa: {
    duplicate: {
      label: 'Duplicate',
    },
    programa: 'Program',
    programas: 'Programs',
    novo_programa: 'New program',
    nome_programa: 'Program name',
    carregar: {
      erro_400: 'Error to load program',
    },
    excluir: {
      sucesso: 'Program successfully excluded',
      erro: 'Error to exclude program',
      erro_400: 'Failure to exclude program',
    },
    salvar: {
      erro_400: 'Failure to save program',
    },
    copiar_para: {
      titulo: 'Copy program',
      help: 'Copies of selected programs are customized to the selected farm because fungicide efficacy will vary among different scenario',
      selecione_cultivares: 'Select varieties',
      marcar_todas: 'Select all varieties',
      instrucao:
        'Selected varieties for each farm in order to generate program copy',
    },
    report: {
      produtos_doses: 'Products and doses per acre',
      projecao_para: 'Projection to',
      em: 'in {0}',
    },
    doencas_prioridade: {
      stepper_titulo: 'Disease priority',
      doencas_recomendadas: 'Recommended diseases',
      descricao:
        'To follow the recommended priorities, maintain the option below activated. If not, select one primary disease and two secondary diseases.',
      select_label: 'Diseases',
      select_error: 'Select just {0} diseases',
      recomendadas: 'Recommended',
      personalizadas: 'Customized',
    },
    numero_aplicacoes_sugeridas: 'Number of suggested sprays',
    desassociar: 'Dissociate programs',
    white_mold_control_title: 'Would you like to manage <b>White Mold</b>',
    white_mold_control_description:
      'By checking "Yes", we will consider specific applications for white mold with specific behavior if compared to others',
    white_mold_total_sprayings: 'for White Mold',
    permissions: {
      cant_create: "You don't have permission to create programas",
    },
  },
  severidade: {
    alta: 'High severity',
    media_alta: 'High average severity',
    media: 'Medium severity',
    media_baixa: 'Low middle severity',
    baixa: 'Low severity',
  },

  application: {
    closets_applications: 'Applications for the next 15 days',
  },

  aplicacao: {
    choose_product: 'Product choice',
    aplicacao: 'Application',
    aplicacoes: 'Applications',
    recalculo_conforme_safra:
      'To each application, efficacy and dates of application are recalculated according to the season weather conditions',
    adicionar_aplicacao: 'Add application',
    nova_aplicacao: 'New application',
    sugerir: {
      erro: 'Error in generating application suggestion',
    },
    doencas_prioridade: {
      select_label: 'Main diseases',
      select_error: 'Select just {0} diseases',
    },
  },
  diseases: {
    soybean_asian_soybean_rust: 'Soybean rust',
    soybean_anthracnosis: 'Anthracnose',
    soybean_target_spot: 'Target spot',
    soybean_powdery_mildew: 'Powdery mildew',
    soybean_cercospora_blight: 'Cercospora blight',
    soybean_septoria_leaf_spot: 'Septoria spot',
    soybean_aerial_web_blight: 'Aerial web blight',
    soybean_white_mold: 'White mold',
    soybean_frogeye_leaf_spot: 'Frogeye leaf spot',
    soybean_diaporthe: 'Diaporthe',
    wheat_leaf_rust: 'Wheat leaf rust',
    wheat_powdery_mildew: 'Wheat powdery mildew',
    wheat_yellow_leaf_spot: 'Yellow leaf spot',
    wheat_fusarium_head_blight: 'Gibberella ear rot',
    wheat_blast: 'Wheat blast',
    alert: {
      white_mold_specific_spraying: 'Specific <b> white mold</b> spray',
      white_mold_mix_spraying:
        'Tank mix to control <b>white mold</b> and another disease',
    },
  },
  spraying: {
    without_efficacy: 'Ineffective',
    specific_white_mold_label: 'white mold',
  },
  pulverizacao: {
    sugestao: 'Suggesting program spray',
  },
  paineis: {
    dados_climaticos_aviso:
      'Climate data along with other informations will be available daily',
  },
  planejamento: {
    succesfuly_removed: 'planning deleted',
    erro: {
      erro_400: 'Failure on planning stage excluding',
    },
    salvar: {
      erro_400: 'Failure on planning stage saving',
    },
  },
  fungicida: {
    selecione_produto: 'Select product',
    informe_dose: 'Inform dosage',
    informe_preco: 'Inform price',
    atualiza_eficacia: 'Efficacy is updated upon saving spray',
    doses: 'Doses',
    doses_recomendadas: 'Recommended doses',
    sem_registro: 'unregistered',
    fungicida: 'Fungicide',
  },
  products_options: {
    mix: 'Tank mix',
    single: 'Single',
    missing_targets:
      'To view the product options it is mandatory to choose at least one disease',
    error: {
      loading: 'Loading product options was not possible',
    },
    show_options: 'Show products options',
    hide_options: 'Hide products options',
    short_options: 'Products options',
    list: {
      title: 'Products',
    },
    description: {
      active_ingredients: 'Active ingredients',
      potencial_efficacy: 'Potential efficacy',
      no_register_info:
        'Some pesticides might show no registration for all diseases. They are highlighted in red! Information about the products follow the approved label by the EPA/US',
    },
  },
  eficacia: {
    eficacias_apresentadas:
      'The presented efficacy consider the use of adjuvants as recommended by the manufacturer',
    ajuda:
      'Expected spray time is used to calculate the efficacy for each program',
    escolha_data_prevista: 'Choose the expected sowing time',
    escolha_severidade_esperada: 'Choose the expected severity of the diseases',
    data_prevista_semeadura: 'Expected sowing time',
    data_prevista_semeadura_nao_recomendada:
      'Expected sowing time is out of recommended season for the variety',
    severidade_esperada: 'Expected diseases severity',
    sem_registro_ajuda:
      'Some pesticides might show no registration for all diseases. They are highlighted in red! Information about the products follow the approved label by the EPA/US',
  },
  menu: {
    my_farm: 'My farm',
    planejamento: 'Planning',
    // CM: Esse cara vai substituir o "cultivares"
    sowings: 'Sowings',
    cultivares: 'Planning Varieties',
    // CM: Esse cara vai substituir o "programas"
    diseases_control: 'Fungicides',
    compare: 'Compare',
    programas: 'Programs',
    diseases: 'Planning Diseases',
    safra: 'Season',
    // CM: Esse cara vai substituir o "semeaduras"
    sowings_list: 'My Sowings',
    semeaduras: 'Planting',
    execucao: 'Execution',
    paineis: 'Panels',
    notes: 'Notes',
    catalog: 'Catalog',
    performance: 'Performance',
    digifarmz_academy: 'DigiFarmz Academy',
    termos_de_uso: 'Terms of use',
    admin_panel: 'Admin Panel',
    fungicides: 'Fungicide',
    nematicide: 'Nematicide',
    plot: 'Plot',
    comparison: 'Comparison',
  },

  contato: {
    informativo: 'Contact us by WhatsApp using the phone number',
    msg_whatsapp_limit_enable_exceeded:
      '?text=Hello!+I+would+like+to+request+help+because+I+can+no+longer+enable+farms+and+plots.+I+use+the+account+{0}.',
    msg_whatsapp_limit_area_exceeded:
      '?text=Hello!+I+would+like+to+request+help+because+my+area+exceeded%2C+the+account+I+use+is+{0}+and+the+area+I+have+is+{1}.',
    msg_whatsapp_login_commercial:
      '?text=Hello!+I+would+like+to+request+help+because+I+want+to+register+on+the+platform.',
    msg_whatsapp_login_support:
      '?text=Hello!+I+would+like+to+request+help+because+I+can+no+longer+log+into+the+platform.',
    msg_whatsapp_hire_plan:
      '?text=Hello!+I+would+like+to+request+help+because+I+want+to+hire+the+{0}+plan.',
  },

  trocar_senha: {
    trocar_senha: 'Change password',
    senha_atual: 'Current password',
    nova_senha: 'New password',
    confirma_nova_senha: 'Confirm the New Password',
    valida_senha_vazio: 'Password is required',
    valida_senha_nova_vazio: 'New password is required',
    valida_senha_confirmacao_vazio: 'Confirm password',
    aceito_termos: 'Read and Accept terms of use of the service',
    acesse_termos: 'Go to terms of use',
    senha_min: 'Current password needs {0} minimum digits.',
    confirme_termos: 'Confirm terms of use',
    erro_generico: 'Failure in update password',
  },
  indique: {
    indique_amigo: 'Invite a friend',
  },
  painel: {
    temperatura_c: 'Temperature (ºF)',
    temperatura_precipitacao: 'Temperature (ºF) and rainfall (in)',
    precipitation: {
      adjust_btn: 'Correct rainfall',
    },
  },
  permission: {
    unauthorized_access: 'You do not have access to this feature',
  },
  pais: {
    pais: 'Country',
  },
  estados: {
    estados: 'States',
    carregar: {
      erro: 'Error in loading state',
    },
  },
  municipios: {
    municipios: 'City',
    carregar: {
      erro: 'Error in loading city',
    },
  },
  talhoes: {
    search_latitude: 'Search latitude',
    search_longitude: 'Search longitude',
    rest_area_plot: 'Remaining plot area',
    quantity_total: 'Total amount',
    talhoes: 'Plots',
    plot: 'Plot',
    salvar: {
      warning:
        'KML format not known or not formatted with system especifications',
      sucesso: 'Plot saved successfully',
    },
    cadastro: 'Register plot',
    text_criar: 'It is a good time to add the plots of your farm account',
    criar_pergunta: 'Add plots?',
    carregar_erro: 'Error in loading plots',
    salvar_erro: 'Error in saving plot',
    mapa: 'Plot map',
    nome_do_talhao: 'Plot name',
    remove_ponto: 'Remove point',
    excluir_title: 'Are you sure?',
    excluir_text:
      'This action is irreversible. Are you sure you want to remove this plot?',
    excluir_erro: 'Error in removing plot',
    text1: 'Map new plots',
    text2:
      'You can add plots from the KML files, or by marking the plot’s area',
    text3: 'KML files must be in Google format',
    text4: 'Load KML files',
    text5: 'or',
    alert_salvar_talhao: 'To save the plot add at least 3 points on the map!',
    area_exceeded:
      'You exceeded the total purchased area for this season, to continue editing please contact the commercial team via the number',
    warnings: {
      insufficient_coordinates: 'Field {0} has wrong coordinates',
      insufficient_polygons: 'Insufficient polygon quantity',
      no_latitude: 'Field {0} does not have latitudes',
      no_longitude: 'Field {0} does not have longitudes',
    },
    config_area: {
      success: 'Area successfully updated',
    },
  },
  exception: {
    planting: {
      date_cant_be_modified: 'Sowing time cannot be changed',
      has_applied_sprayings: 'This Sowing time already present sprays done',
      has_vinculated_programs:
        'Plantings with linked programs cannot be deleted',
      has_program: 'Planting with linked program cannot be deleted',
    },
    region: {
      invalid_variety: 'Variety not indicated for this region',
      no_recommendation: 'Region with no recommendation',
    },
    payment: {
      pending: 'Pending payment',
    },
    action_unauthorized: 'You are not allowed to do that',
    invalid_session: 'Your session has expired, please log in again.',
    unexpected_error:
      'We are sorry for the inconvenience, something unexpected happened. Do not worry, we are working to fix the problem',
    unexpected_error_title:
      'We are sorry for the inconvenience, something unexpected happened.',
    crop: {
      forbidden: 'Unauthorized access to the selected variety.',
    },
    plot: {
      has_planting: 'Plot already has registered planting',
      exceeded_area:
        'You exceeded the total purchased area for this season. If you need to add more, please contact the commercial team via the WhatsApp phone number +55 51 99351-9481',
      invalid_geometry:
        'Be careful, the plot design must follow a valid geometrical shape. Make the necessary adjustments to continue.',
      action_not_allowed: 'Plot disabled, you cannot perform this action',
      has_planting_not_harvested: 'Plot has planting not harvested',
    },
    variety: {
      already_added_in_farm: 'Variety already added to the farm account',
      already_added_in_program:
        'It is not possible to delete variety that have been used in the planning stage, please delete the programs generated in the planning stage that present the variety',
      already_added_in_planting:
        'It’s not possible to delete this variety because it is being used at planting in the season stage',
    },
    report: {
      data_not_avaliable: 'Farm does not have harvest stage',
      plot_no_registered: 'Farm does not have registered plots',
      sowing_no_registered: 'Program does not have registered planting',
    },
    season: {
      limited_access_enable_exceded: 'Enablement limit per harvest exceeded.',
    },
    farm: {
      has_registered_sowing: 'Farm is being used in a planting stage',
      action_not_allowed: 'Farm disabled, you cannot perform this action',
      farm_does_not_belong_in_user: 'Farm does not belong to the user',
    },
    sowing: {
      has_registered_program:
        'Planting stage is being used in a control program',
      has_harvest: 'Sowing already has a registered harvest',
    },
    spray: {
      invalid_white_mold_sprays:
        'DigiFarmz do not recomend more than 3 white mold sprays',
      date_before_last_spray:
        'Previous date or same date related to the last spray',
      date_before_minimal_sowing_date:
        'Prior date related to the date of planting',
      defensive_has_been_duplicated: 'Defensive has been duplicated',
      not_last_exclude: 'The spraying is not the last one registered.',
    },
    program: {
      has_spraying: 'Program has sprays',
    },
    file: {
      size_too_large: 'Maximum file size allowed 5MB',
      mimetype: 'Unsupported file type',
    },
    invalid_credentials:
      'Your email or password is incorrect. Please try again.',
    invalid_email: 'Your email is incorrect. Please try again.',
    invalid_password: 'Password is incorrect. Please try again.',
    password_confirmation_mismatch:
      'The "Confirm New Password" field is different from the "New Password" field.',
    too: {
      many: {
        attempts: 'You must wait 5 minutes before trying again.',
      },
    },
    account: {
      activate: {
        active: {
          title: 'User already active!',
          description:
            'Use the access credentials we sent to the email <strong>{{accountData.context.email}}</strong>. After your first access, you will need to reset your password to one of your choice.',
        },
        activated: {
          title: 'Your account has been activated successfully!',
          description:
            "We've sent an email to <b>{{accountData.email}}</b> with first sign-in credentials. Please check your inbox.",
        },
        code_invalid: {
          title: 'Invalid Code!',
          description:
            'We were unable to activate your account, the activation code has expired. Click the button below to send a new activation email.',
        },
        not_active:
          'Inactive user! To access the platform you need to activate your account. If you do not find the activation email in your inbox or spam folder, click on the contact options below.',
        support:
          'If you do not find the email in your inbox or in the email, click on the contact options below. If the problem persists, contact our support team at <b>suporte@digifarmz.com</b>.',
        resend_email: 'Resend email',
      },
    },
  },
  execucao: {
    estadio_nao_recomendado:
      'Application not recommended due to phenological stage and disease pressure',
    total_delay: 'Accumulated delays {0} days',
    salvar: {
      erro_400: 'error on saving',
    },
    programa: {
      importar: 'Import program',
      novo: 'New program',
    },
  },
  growth_stage: {
    disabled: 'Unavailable',
    no_stage: 'Waiting Sowing time',
    save_sucess: 'Observation saved successfully',
    save_failure: 'Failure to save observation',
    soybean: {
      ve: 'VE',
      vc: 'VC',
      v1: 'V1',
      v2: 'V2',
      v3: 'V3',
      v4: 'V4',
      v5: 'V5',
      v6: 'V6',
      v7_v9: 'V7-V9',
      r1: 'R1',
      r2: 'R2',
      r3: 'R3',
      r4: 'R4',
      r5: 'R5',
      r5_1: 'R5.1',
      r5_2: 'R5.2',
      r5_3: 'R5.3',
      r5_4: 'R5.4',
      r5_5: 'R5.5',
      r6: 'R6',
      r7: 'R7',
      r8: 'R8',
      r9: 'R9',
    },
    wheat: {
      0: 'Germination',
      1: 'First leaves',
      2: 'Beginning of tillering',
      3: 'Tillers formed',
      4: 'Leaf sheath growth',
      5: 'Pseudo-stem strongly erected',
      6: 'First node formed',
      7: 'Second node formed',
      8: 'Last leaf visible',
      9: 'Ligule of last leaf visible',
      10: 'Heading',
      '10_1': 'First ears visible',
      '10_2': 'Quarter of heading process completed',
      '10_3': 'Half of heading process completed',
      '10_4': 'Three-quarters of heading process completed',
      '10_5': 'All ears out of sheath',
      '10_5_1': 'Beginning of flowering',
      '10_5_2': 'Flowering complete to top of ear',
      '10_5_3': 'Flowering over at base of ear',
      '10_5_4': 'Flowering over',
      '11_1': 'Milky ripe',
      '11_2': 'Mealy ripe',
      '11_3': 'Kernel hard',
      '11_4': 'Ripe for cutting',
    },
  },
  irrigation: {
    no_irrigation: 'No irrigation',
  },
  irrigacao: {
    irrigacao: 'Irrigation',
    irrigacoes: 'Irrigations',
    salva_sucesso: 'Irrigation successfully saved',
    excluida_sucesso: 'Irrigation successfully removed',
    valida_data_time:
      'Beginning date of irrigation cannot be prior to the ending',
    valida_lamina: 'Irrigation amount cannot be zero',
    salvar_erro: 'Error in saving irrigation',
    data_inicio: 'Beginning date',
    data_fim: 'Ending date',
    hora_inicio: 'Beginning time',
    hora_fim: 'Ending time',
    precipitacao: 'Rainfall (in)',
  },

  harvest: {
    harvested: 'harvested',
    sucess_message: 'Harvest of {0} Bushel/acre in {1}',
    register_harvest: 'Register harvest',
    register_harvests: 'Register harvests',
    lost_harvest: 'Lost my harvest',
    lost: 'Harvest lost',
    did_desiccation: 'Did the dessication',
    ready_to_harvest: 'Ready to harvest',
  },

  colheita: {
    excluida_sucesso: 'Successfully excluded',
    dessecacao: 'Pre harvest desiccation',
    data_colheita: 'Harvest date',
    sacas_por_hectare: 'Bushel/acre',
    colheita: 'Harvest',
    remover_colheita: 'Remove harvest',
  },

  stage: {
    stage: 'Stage',
    stages: 'Stages',
    real_stage: 'Real stage',
    growth_stage: 'Growth stage',
    growth_stages: 'Growth stages',
  },

  season_report: {
    bags_per_hectare: 'Bushel per Acre',
    harvest_date: 'Harvest date',
    when_harvest: 'When harvest',
    desiccation: 'Desiccation',
    finished: 'Finished',
    insert_harvest_data_title: 'Insert harvest data',
    jump: 'Jump',
  },

  qt_dias: 'day | days',
  tutorial: 'Tutorial',
  account: {
    release_area: 'Release area',
  },
  maintenance: {
    title: 'Platform on maintenance',
    description:
      'In order to offer you a better service, we are doing some improvements on the platform. Please, wait <b> some time </b> and retry',
    verify_availability: 'Verify availability',
  },
  prescription_export: 'Download prescription',
  prescription_export_next: 'Download next prescription',
  test: 'Test',
  academy: {
    title:
      'Looks like you do not have access to <span style="color: #39af49"> DigiFarmz Academy',
    description:
      'Do not worry, contact our customer success team to release your account',
    btn: 'Ask for access',
    whatsapp:
      'Hello DigiFarmz, I would like to request access to the DigiFarmz Academy. My email is',
  },
  planting: {
    area_greather_than_plot:
      'The planting area cannot be greater than the remaining plot area',
    sown_area: 'Sown area',
    sowing_date: 'Sowing date',
  },
  required_fields: {
    special_characters: 'Accents or special characters are not allowed',
    email: 'Please enter a valid email format',
    at_least_one: 'Populate at least one farm',
    harvest: {
      date: 'Harvest date must be filled in',
      productivity:
        'Productivity must be greater than 0. If you lost, select the "crop loss" option.',
      validate_empty_fields_multi_harvest:
        'The date field and "sc/ha" is mandatory if there is a harvest. If you missed it, select the "crop loss" option.',
    },
  },

  disease_control: {
    action: 'What do you want to planning?',
    varieties: 'Varieties',
    diseases: 'Diseases',
    sowings_planning: 'Sowings',
    create_variety: 'Create variety',
    select_variety: 'SELECT VARIETY',
    select_crop: 'SELECT CROP',
    no_created_variety_title_subtitle: 'NECESSARY ACTION',
    no_created_variety_title: 'Register your varieties first',
    no_created_variety_text:
      'To control diseases, first you need to register your varieties',
  },

  date_without_year: 'MM/DD',

  card_info_plan: {
    title_main: 'Upgrade your plan',
    title_description: 'Add new features to increase productivity',
    subtitle_main: 'Starting from',
    button: 'Discover our plans',
    money_unit: '$',
    per_month: '/month',
  },
  help_button: {
    tour: 'Guided tour',
    shortly: 'Shortly',
    contact: {
      title: 'Speak to customer service',
      link: '',
    },
  },
  freemium: {
    dialog: {
      title:
        'You have reached the maximum daily number of accesses to product information.',
      description:
        'Discover our <strong>Light plan</strong> and have unlimited access to all catalog information',
    },
    component: {
      title: 'Free plan',
      subtitle: 'Products viewed daily',
      exceeded_limit: 'Daily limit reached!',
      remaining_limit: 'remaining',
    },
    button: {
      title: 'Buy catalog',
    },
  },

  config_area: {
    header: 'Area Configuration',
    title: 'Select the plots you want to use for the contracted area',
    article:
      'You will use the contracted area to enjoy the benefits of the Full plan on the selected plots.',
    area_contracted: 'Contracted area',
    area_remaning: 'Remaining area',
    info: 'Plots with an area larger than the contracted area cannot be selected. If you wish, edit the area to match the remaining contracted area.',
    wrapper_list: {
      area_total: 'Total area',
      edit_area: 'Edit area',
    },
    actions: {
      save: 'Save',
      cancel: 'Cancel',
    },
  },

  alert_ndvi: {
    title: 'It is not possible to edit the area of this plot',
    message:
      'Plots cannot have their area edited for 1 year if they have NDVI contracted.',
    confirm: 'Got it',
  },

  unsed_area: {
    title: 'You have not used the contracted area for this plot',
    message:
      'Configure your plots or use the benefits of the contracted area in other plots.',
    action: 'Configure area',
  },

  message_date_emergency: {
    dialog: {
      title: 'Attention',
      description:
        'We recommend registering the planting only after confirming the emergence, as an incorrect date may result in errors in application dates. If necessary, the emergence date can be adjusted after registration.',
    },
  },

  modal_full_plan: {
    title: 'Congratulations! You now have the Full plan',
    message:
      'Set up your contracted area for the plots you have registered and enjoy all the benefits of the Full plan',
    actions: {
      config_later: 'Configure later',
      config_now: 'Configure now',
    },
  },
}
