<template>
  <div class="diseases-control-header">
    <df-page-header :title="title">
      <template #actions>
        <df-button
          depressed
          variant="text-green"
          :disabled="isComparisonScreen"
          @click="openCompareProgramsModal"
        >
          {{ $t('DiseasesControl.header.compare_programs') }}
        </df-button>
        <df-button
          :disabled="isAddProgramDisabled"
          @click="openAddProgramModal"
        >
          {{ $t('DiseasesControl.header.add_program') }}
        </df-button>
      </template>
    </df-page-header>
    <div class="filters__wrapper">
      <section class="d-flex align-center header__filters">
        <p class="filter__item">
          {{ $t('DiseasesControl.header.filter_by') }}:
        </p>
        <df-filter-select
          ref="fieldSelect"
          v-model="filteredField"
          search-bar
          :clearable="isComparisonScreen"
          :disabled="!formattedFields.length"
          :items="formattedFields"
          :loading="loadingFields"
          :placeholder="$t('DiseasesControl.header.select_field')"
          :search-placeholder="$t('DiseasesControl.header.search_field')"
          @click="fetchVarietiesAndLog"
          @clear="clearFilteredField"
        />
        <df-filter-select
          ref="varietySelect"
          v-model="filteredVariety"
          search-bar
          :clearable="isComparisonScreen"
          :disabled="shouldDisableVarietiesFilter"
          :items="varieties"
          :loading="loadingVarieties"
          :placeholder="$t('DiseasesControl.header.select_variety')"
          :search-placeholder="$t('DiseasesControl.header.search_variety')"
          @clear="clearFilteredVariety"
          @click="fetchItemsAndLog"
        />
        <div class="filter__period">
          <df-filter-select
            ref="periodFilter"
            v-model="filteredPeriod"
            :disabled="isPeriodFilterDisabled"
            :items="periods"
            :label="$t('DiseasesControl.header.period')"
            @click="selectItem"
          />
        </div>
        <df-button
          v-if="showCleanFiltersButton"
          class="filters__clean"
          variant="text"
          @click="cleanFilters"
        >
          {{ $t('SowingsList.home.clean_filters') }}
        </df-button>
      </section>
    </div>
    <add-program-modal
      v-if="isAddProgramModalOpened"
      @close="closeAddProgramModal"
    />
    <custom-period-modal
      v-if="isCustomDateModalOpened"
      @apply-filter="filterCustomPeriod"
      @close="closeCustomDateModal"
    />
  </div>
</template>

<script>
const AddProgramModal = () =>
  import('@/modules/diseasesControl/components/modal/AddProgramModal.vue')
const CustomPeriodModal = () =>
  import('@/modules/sowingsPlanning/components/modal/CustomPeriodModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfFilterSelect from '@/lib/components/FilterSelect/DfFilterSelect.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService.js'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'DiseasesControlHeader',

  components: {
    AddProgramModal,
    CustomPeriodModal,
    DfButton,
    DfFilterSelect,
    DfPageHeader,
  },

  props: {
    isComparisonScreen: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isAddProgramModalOpened: false,
      isCustomDateModalOpened: false,
      fields: [],
      filteredField: {},
      filteredPeriod: {},
      filteredVariety: {},
      formattedFields: [],
      loadingFields: false,
      loadingVarieties: false,
      originalFields: [],
      varieties: [],
    }
  },

  async created() {
    await this.requestFields()
    this.$root.$on('add-program', this.setFieldAndVariety)

    if (this.isComparisonScreen) {
      this.filteredPeriod = this.initialComparisonFilteredPeriod
    } else {
      this.filteredPeriod = this.initialFilteredPeriod
    }
    this.$emit('set-period', this.filteredPeriod.value)

    const hasQueryParams =
      this.$route.query &&
      Object.keys(this.$route.query).length &&
      this.$route.query.field_id &&
      this.$route.query.variety_id

    if (hasQueryParams) {
      const field = this.originalFields.find(
        (field) => field.id == this.$route.query.field_id
      )
      this.setField({ name: field.name, value: field.id })

      await this.requestVarieties()

      const variety = this.varieties.find(
        (variety) => variety.value == this.$route.query.variety_id
      )
      this.setVariety({ name: variety.name, value: Number(variety.value) })
      this.$emit('list-items')
    } else {
      if (!this.isComparisonScreen) {
        const hasField = this.setMostRecentField(this.fields)
        if (hasField) await this.fetchVarieties()
      }
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    initialFilteredPeriod() {
      return {
        name: this.$t('SowingsPlanning.home.filters.next_6_months'),
        isDefault: true,
        value: '6m',
      }
    },
    initialComparisonFilteredPeriod() {
      return {
        name: 'N/A',
        isDefault: true,
        value: null,
      }
    },
    isAddProgramDisabled() {
      return !this.filteredField?.value || !this.filteredVariety?.value
    },
    isPeriodFilterDisabled() {
      return !this.isComparisonScreen && !this.varieties.length
    },
    periods() {
      const periods = [
        this.initialFilteredPeriod,
        {
          name: this.$t('SowingsPlanning.home.filters.next_12_months'),
          isDefault: false,
          value: '12m',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.next_24_months'),
          isDefault: false,
          value: '24m',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.previous_sowings'),
          isDefault: false,
          value: 'previous_sowings',
        },
        {
          name: this.$t('SowingsPlanning.home.filters.custom_date'),
          isDefault: false,
          value: 'custom_date',
        },
      ]
      if (this.isComparisonScreen)
        periods.unshift(this.initialComparisonFilteredPeriod)
      return periods
    },
    shouldDisableVarietiesFilter() {
      return !this.filteredField.value || !this.varieties.length
    },
    showCleanFiltersButton() {
      return (
        this.isComparisonScreen &&
        (this.filteredField.value ||
          this.filteredPeriod.value ||
          this.filteredVariety.value)
      )
    },
  },

  methods: {
    cleanFilters() {
      this.filteredField = {}
      this.filteredVariety = {}
      this.$refs.periodFilter.cleanFilter(this.initialComparisonFilteredPeriod)
      this.$emit('filter-reset')
    },
    clearFilteredField() {
      this.filteredField = {}
      this.fetchVarietiesAndLog()
    },
    clearFilteredVariety() {
      this.filteredVariety = {}
      this.fetchItemsAndLog()
    },
    closeAddProgramModal() {
      this.isAddProgramModalOpened = false
    },
    closeCustomDateModal() {
      this.isCustomDateModalOpened = false
    },
    fetchItemsAndLog() {
      logEvent(events.diseasesControlModule.DiseasesControl.filtered_by_crop)
      this.$emit('set-variety', this.filteredVariety)
      this.$emit('list-items')
    },
    async fetchVarieties() {
      await this.requestVarieties()
      if (this.varieties.length) {
        this.$set(this.filteredVariety, 'name', this.varieties[0].name)
        this.$set(this.filteredVariety, 'value', this.varieties[0].value)
        this.$emit('set-variety', this.filteredVariety)
        this.$emit('list-items')
      }
    },
    fetchVarietiesAndLog() {
      logEvent(events.diseasesControlModule.DiseasesControl.filtered_by_field)
      this.$emit('set-field', this.filteredField)
      if (this.isComparisonScreen) this.requestVarieties()
      else this.fetchVarieties()
    },
    async filterCustomPeriod(period) {
      const startDate = this.moment(period.startDate).format('DD/MM/YY')
      const endDate = this.moment(period.endDate).format('DD/MM/YY')
      this.filteredPeriod.name = `${startDate} - ${endDate}`
      this.filteredPeriod.value = `${period.startDate}_${period.endDate}`
      this.$emit('set-period', this.filteredPeriod.value)
      this.$emit('list-items')
      this.filteredPeriod.value = 'custom_date'
    },
    openAddProgramModal() {
      logEvent(events.diseasesControlModule.DiseasesControl.new_program)
      this.isAddProgramModalOpened = true
    },
    openCompareProgramsModal() {
      this.$router.push({
        path: '/diseases_control/compare',
      })
    },
    async requestFields() {
      this.loadingFields = true
      const fieldsParams = { farm_id: this.currentFarmId, is_active: true }
      try {
        const { data } = await sowingsPlanningService.getFields(fieldsParams)
        if (data.length) {
          this.fields = data
          this.originalFields = [...data]
          this.formattedFields = data.map((field) => {
            return {
              name: field.name,
              value: String(field.id),
            }
          })
        }
        this.$emit('set-fields', this.formattedFields)
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingFields = false
      }
    },
    async requestVarieties() {
      this.loadingVarieties = true
      const programVarietiesParams = {
        field_id: this.filteredField.value,
      }
      try {
        const { data } = await diseasesControlService.getSowingsVarieties(
          programVarietiesParams
        )
        const varietyIds = []
        this.varieties = data.reduce((acc, variety) => {
          if (!varietyIds.includes(variety.id)) {
            acc.push({
              name: `${this.$t(
                `SowingsPlanning.variety_list_item.${variety.crop_message_key}`
              )} | ${variety.name}`,
              value: String(variety.id),
            })
            varietyIds.push(variety.id)
          }
          return acc
        }, [])
        this.$emit('set-varieties', this.varieties)
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingVarieties = false
      }
    },
    async selectItem({ value }) {
      logEvent(events.diseasesControlModule.DiseasesControl.filtered_by_period)
      if (value === 'custom_date') {
        this.isCustomDateModalOpened = true
      } else {
        this.$nextTick(() => {
          this.$emit('set-period', value)
          this.$emit('list-items')
        })
      }
    },
    setField(field) {
      this.$refs.fieldSelect.setSingleValue(field)
      this.filteredField = field
      this.$emit('set-field', this.filteredField)
    },
    setMostRecentField(fields) {
      const mostRecent =
        fields.length > 0
          ? fields.reduce((latest, current) => {
              return new Date(current.created_at) > new Date(latest.created_at)
                ? current
                : latest
            })
          : {}
      if (!mostRecent || !Object.keys(mostRecent).length) return false
      this.$set(this.filteredField, 'name', mostRecent.name)
      this.$set(this.filteredField, 'value', String(mostRecent.id))
      this.$emit('set-field', this.filteredField)
      return true
    },
    setVariety(variety) {
      this.$refs.varietySelect.setSingleValue(variety)
      this.filteredVariety = variety
      this.$emit('set-variety', this.filteredVariety)
    },
    setFieldAndVariety(params) {
      this.setField(params.field)
      this.setVariety(params.variety)
      this.$emit('list-items')
    },
  },

  beforeDestroy() {
    this.$root.$off('add-program', this.setFieldAndVariety)
  },
}
</script>

<style lang="scss" scoped>
.diseases-control-header {
  .filters__wrapper {
    padding: 16px;
    width: auto;

    @include d(m) {
      padding: 12px 8px;
    }
    .header__filters {
      max-width: 1440px;
      margin: 0px auto;
      gap: 8px;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
      .filter__item {
        flex: 0 0 auto;
      }
      p {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
      .filter__period {
        order: 3;
        display: flex;
        flex-grow: 1;
        justify-content: end;

        @include d(m) {
          order: 1;
        }
      }
      .filters__clean {
        order: 2;
      }
    }
  }
}
::v-deep .filters__clean .v-btn__content .text-personalized {
  color: #39af49 !important;
}
</style>
