<template>
  <v-expansion-panel class="variety-list">
    <v-expansion-panel-header class="d-flex list-header">
      <div class="d-flex align-center list__title">
        <font-awesome-icon icon="seedling" />
        <h2>
          {{
            $t(`SowingsPlanning.variety_list_item.${variety.crop_message_key}`)
          }}
          | {{ variety.variety_name }}
        </h2>
      </div>
      <df-dropdown-list icon="ellipsis-v" :items="varietyActions" />
    </v-expansion-panel-header>
    <v-expansion-panel-content
      v-if="variety.sowings.length"
      class="item__content"
    >
      <div class="content__grid">
        <h3>
          {{ $t('SowingsPlanning.variety_list_item.estimated_sowing_date') }}
        </h3>
        <h3 class="content-end">
          {{ $t('SowingsPlanning.variety_list_item.programs_made') }}
        </h3>
      </div>
      <variety-item
        v-for="(sowing, index) in variety.sowings"
        class="align-center content__grid content__infos"
        :index="index + 1"
        :key="sowing.id"
        :sowing="sowing"
      />
    </v-expansion-panel-content>
    <add-date-sowing-modal
      v-if="isAddDateSowingModalOpened"
      :field-id="fieldId"
      :variety="variety"
      @close="closeAddDateSowingModal"
    />
    <df-delete-modal
      v-if="isDeleteVarietyModalOpened"
      persistent
      :text="$t('SowingsPlanning.delete_variety_modal.text')"
      :title="$t('SowingsPlanning.delete_variety_modal.title')"
      :width="594"
      @action-click="deleteVariety"
      @close="closeDeleteModal"
    >
      <template #content>
        <div class="df-flex-sm df-flex-col delete-variety__content">
          <div class="df-flex-sm align-center variety-content__title">
            <font-awesome-icon icon="seedling" />
            <h2>
              {{ $t(variety.crop_message_key) }} | {{ variety.variety_name }}
            </h2>
          </div>
          <div class="df-flex-m variety-content__text">
            <p>
              {{ $t('SowingsPlanning.delete_variety_modal.created_sowings') }}:
              <span>{{ variety.total_sowings }}</span>
            </p>
            <div></div>
            <p>
              {{ $t('SowingsPlanning.delete_variety_modal.created_programs') }}:
              <span>{{ variety.total_sowings_programs }}</span>
            </p>
          </div>
        </div>
      </template>
    </df-delete-modal>
  </v-expansion-panel>
</template>

<script>
const AddDateSowingModal = () =>
  import('@/modules/sowingsPlanning/components/modal/AddDateSowingModal.vue')
const DfDeleteModal = () => import('@/lib/Modal/DfDeleteModal.vue')
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import VarietyItem from '@/modules/sowingsPlanning/components/list/VarietyItem.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'VarietyList',

  components: {
    AddDateSowingModal,
    DfDeleteModal,
    DfDropdownList,
    VarietyItem,
  },

  props: {
    fieldId: {
      required: true,
      type: Number,
    },
    variety: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isAddDateSowingModalOpened: false,
      isDeleteVarietyModalOpened: false,
    }
  },

  provide() {
    return {
      variety: this.variety,
    }
  },

  inject: ['requestFieldSowingsPlanning'],

  computed: {
    varietyActions() {
      return [
        {
          name: this.$t(
            'SowingsPlanning.variety_list_item.variety_actions.new_sowing_date'
          ),
          icon: 'plus',
          action: () => {
            logEvent(
              events.sowingsPlanningModule.SowingsPlanning.add_new_sowing_date
            )
            this.isAddDateSowingModalOpened = true
          },
        },
        {
          name: this.$t('SowingsPlanning.variety_list_item.delete'),
          color: '#F23528',
          icon: 'trash',
          action: () => {
            logEvent(events.sowingsPlanningModule.SowingsPlanning.delete_sowing)
            this.isDeleteVarietyModalOpened = true
          },
        },
      ]
    },
  },

  methods: {
    closeAddDateSowingModal() {
      this.isAddDateSowingModalOpened = false
    },
    closeDeleteModal() {
      this.isDeleteVarietyModalOpened = false
    },
    async deleteVariety() {
      try {
        const params = {
          field_id: this.fieldId,
          variety_id: this.variety.variety_id,
        }
        await sowingsPlanningService.deleteVariety(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingsPlanning.delete_variety_modal.sowings_deleted')
        )
        this.requestFieldSowingsPlanning()
        this.closeDeleteModal()
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.variety-list {
  &:not(:first-child) {
    border-top: 1px solid #e6e9e6;
  }
  .list-header {
    padding: 16px 24px;
    gap: 16px;

    @include d(m) {
      padding: 16px;
    }
    .list__title {
      gap: 8px;
      flex-grow: 1;

      @include d(m) {
        margin-left: 40px;
      }
      h2 {
        overflow: hidden;
        color: #1a2b46;
        text-overflow: ellipsis;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .v-btn {
      flex-grow: 0;
    }
  }
  .item__content {
    background-color: #f8f9f8;

    .content__grid {
      padding: 16px 24px;
      display: grid;
      grid-template-columns: 3fr 9fr;

      @include d(t) {
        grid-template-columns: 2fr 3fr;
      }
      @include d(m) {
        padding: 16px;
        grid-template-columns: 1fr 1fr;
      }
      .content-end {
        @include d(m) {
          text-align: end;
        }
      }
      h3 {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
      span {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-transform: capitalize;
      }
    }
    .content__infos:not(:last-child) {
      border-bottom: 1px solid #e6e9e6;
    }
  }
}
.delete-variety__content {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e6e9e6;
  background: #f8f9f8;

  .variety-content__title {
    svg {
      height: 14px;
      width: 14px;
      color: #1a2b46;
    }
    h2 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .variety-content__text {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    p {
      font-weight: 500;
      line-height: 16px;
    }
    div {
      width: 1px;
      background-color: #e6e9e6;
    }
    span {
      font-weight: 400;
      line-height: 18px;
    }
  }
}
::v-deep #desktop-actions {
  @include d(m) {
    display: none;
  }
}
::v-deep #mobile-actions {
  display: none;

  @include d(m) {
    display: inline-block;
  }
}
::v-deep .v-expansion-panel-header__icon .v-icon {
  color: #5b6459 !important;
}
::v-deep .v-btn__content svg {
  color: #5b6459;
}
::v-deep .v-expansion-panel-header__icon {
  @include d(m) {
    position: absolute;
  }
}
</style>
