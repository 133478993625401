import http from '@/utils/http'
import { sameNameParamQuery, queryBuilder } from '@/utils/apiQueryBuilder'

const diseasesControlService = {
  getProgramInfos: ({ program_id }) => {
    // CM: ver qual a url correta
    let url = `/diseases-programs/${program_id}/infos`
    return http.get(url)
  },
  getPrograms: ({ field_id, period, program_ids, variety_id }) => {
    // CM: ver qual a url correta
    let url = `/diseases-programs${queryBuilder(
      sameNameParamQuery({ field_id }),
      sameNameParamQuery({ period }),
      sameNameParamQuery({ program_ids }),
      sameNameParamQuery({ variety_id })
    )}`
    return http.get(url)
  },
  getProgramFarms: () => {
    // CM: ver qual a url correta
    let url = '/programs-farms'
    return http.get(url)
  },
  getProgramVarieties: ({ farm_id }) => {
    // CM: ver qual a url correta
    let url = `/programs-varieties${queryBuilder(
      sameNameParamQuery({ farm_id })
    )}`
    return http.get(url)
  },
  getSowingsVarieties: ({ field_id }) => {
    // CM: ver qual a url correta
    let url = `/sowings-varieties${queryBuilder(
      sameNameParamQuery({ field_id })
    )}`
    return http.get(url)
  },
  getProgramsList: ({ farm_id, variety_id }) => {
    // CM: ver qual a url correta
    let url = `/programs-list${queryBuilder(
      sameNameParamQuery({ farm_id }),
      sameNameParamQuery({ variety_id })
    )}`
    return http.get(url)
  },
  getEstimatedSowingDates: ({ variety_id }) => {
    let url = `/program-estimated-sowing-date${queryBuilder(
      sameNameParamQuery({ variety_id })
    )}`
    return http.get(url)
  },
  editProgram: ({
    aerial_web_blight_control,
    expected_pressure,
    program_name,
    estimated_sowing_date,
    program_id,
    white_mold_control,
  }) => {
    // CM: ver qual a url correta
    return http.patch(`/diseases-programs/${program_id}`, {
      aerial_web_blight_control,
      expected_pressure,
      program_name,
      estimated_sowing_date,
      white_mold_control,
    })
  },
  createProgram: ({
    aerial_web_blight_control,
    crop,
    expected_pressure,
    field_id,
    program_name,
    estimated_sowing_date,
    variety,
    white_mold_control,
  }) => {
    // CM: ver qual a url correta
    return http.post(`/diseases-programs`, {
      aerial_web_blight_control,
      crop,
      expected_pressure,
      field_id,
      program_name,
      estimated_sowing_date,
      variety,
      white_mold_control,
    })
  },
  copyProgram: ({ field_ids, program_id }) => {
    // CM: ver qual a url correta
    return http.post(`/diseases-programs/${program_id}/copy`, {
      field_ids,
    })
  },
  executeProgramInSeason: ({ program_id, sowing_ids }) => {
    // CM: ver qual a url correta
    return http.post(`/diseases-programs/${program_id}/execute`, {
      sowing_ids,
    })
  },
  deleteProgram: ({ program_id }) => {
    // CM: ver qual a url correta
    return http.delete(`/delete-program/${program_id}`)
  },
}

export default diseasesControlService
