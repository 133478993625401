import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'
import {
  getRandomEfficacies,
  pesticides,
  seasonDiseases,
} from '@/services/__mocks__/diseasesSeeds'
import {
  getExecutedSowings,
  getPlanningAndExecutedSowings,
} from '@/services/__mocks__/sowingsSeeds'
import { fields } from '@/modules/plot/mock/fieldSeeds'
import { addDaysToDate, getCustomDate } from '@/utils/time'
import {
  executedSprayings,
  getAllSprayings,
  planningSprayings,
} from '@/services/__mocks__/sprayingsSeeds'
import {
  defaultProgramsMock,
  getAllPrograms,
  getExecutedPrograms,
} from '@/modules/diseasesControl/mock/diseasesControlSeeds'

const sowingViewRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/diseases',
      result: ({ queryParams }) => {
        let currentSeasonCrop = []
        if (queryParams.seasonCrop == 6)
          currentSeasonCrop = seasonDiseases.wheat
        else currentSeasonCrop = seasonDiseases.soybean
        return APIFailureWrapper({
          content: currentSeasonCrop,
          errorMessage: 'Erro na listagem de doenças da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/diseases-suggestions',
      result: ({ queryParams }) => {
        let main_disease_id, secondary_disease_id
        switch (Number(queryParams.spraying_number)) {
          case 1:
            main_disease_id = 1
            secondary_disease_id = 2
            break
          case 2:
            main_disease_id = 3
            secondary_disease_id = 4
            break
          case 3:
            main_disease_id = 2
            secondary_disease_id = 5
            break
          case 4:
            main_disease_id = 1
            secondary_disease_id = 3
            break
          default:
            main_disease_id = 1
            secondary_disease_id = 4
            break
        }
        return APIFailureWrapper({
          content: { main_disease_id, secondary_disease_id },
          errorMessage: 'Erro na listagem de sugestões de doenças da aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/diseases-spraying',
      result: ({ queryParams }) => {
        const spraying = getAllSprayings().find(
          (spraying) => spraying.id == queryParams.spraying_id
        )
        return APIFailureWrapper({
          content: {
            main_disease_id: spraying.main_disease_id,
            secondary_disease_id: spraying.secondary_disease_id,
          },
          errorMessage: 'Erro na busca de doenças da aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/sowing-sprayings',
      result: ({ queryParams }) => {
        const sowingIds = queryParams.sowing_ids.split(',').map(Number)
        const sprayings = getAllSprayings().filter((spraying) => {
          if (queryParams.spraying_status == 'all') {
            return sowingIds.includes(spraying.sowing_id)
          } else if (queryParams.spraying_status == 'done') {
            return (
              sowingIds.includes(spraying.sowing_id) && spraying.applied_date
            )
          } else {
            return (
              sowingIds.includes(spraying.sowing_id) &&
              !spraying.applied_date &&
              new Date(spraying.ideal_date) < new Date()
            )
          }
        })
        return APIFailureWrapper({
          content: sprayings,
          errorMessage: 'Erro na listagem de aplicações da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/spraying-efficacies',
      result: ({ queryParams }) => {
        const spraying = getAllSprayings().find(
          (spraying) => spraying.id == queryParams.spraying_id
        )
        return APIFailureWrapper({
          content: spraying.efficacies,
          errorMessage: 'Erro na listagem de eficácias da aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/sowing-infos',
      result: ({ queryParams }) => {
        const currentSowing = getExecutedSowings().find(
          (sowing) => sowing.id == queryParams.sowing_id
        )
        const field = fields.find((field) => field.id == currentSowing.field_id)
        const fieldSowings = getExecutedSowings().filter((sowing) => {
          return (
            sowing.field_id == currentSowing.field_id &&
            sowing.id != currentSowing.id
          )
        })
        const infos = {
          status: currentSowing.harvest_status,
          sown_area: currentSowing.area,
          sown_at: currentSowing.date,
          estimated_harvest_date: currentSowing.estimated_date,
          inputs: {
            total_cost: 500,
            bags: 25,
          },
          growth_stage: currentSowing.growth_stage,
          field: {
            id: field.id,
            area: field.area,
            name: field.name,
            sowings: fieldSowings.reduce((acc, fieldSowing) => {
              if (fieldSowing.sowing_status === 'executed') {
                acc.push({
                  id: fieldSowing.id,
                  crop_name: fieldSowing.crop_message_key,
                  variety_name: fieldSowing.variety_name,
                  status: fieldSowing.harvest_status,
                })
              }
              return acc
            }, []),
          },
        }
        return APIFailureWrapper({
          content: infos,
          errorMessage: 'Erro na listagem de informações da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/pesticide-infos',
      result: ({ queryParams }) => {
        const filteredPesticides = pesticides.reduce((acc, pesticide) => {
          if (queryParams.pesticide_ids.includes(pesticide.id)) {
            acc.push({
              pesticide_id: pesticide.id,
              price: pesticide.price,
              dose: pesticide.dose,
              unit: pesticide.unit,
            })
          }
          return acc
        }, [])
        return APIFailureWrapper({
          content: filteredPesticides,
          errorMessage: 'Erro na busca de informações do defensivo',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/spraying-suggestions',
      result: ({ queryParams }) => {
        let filteredPesticides = pesticides
        if (queryParams.main_defensive_id) {
          filteredPesticides = filteredPesticides.reduce((acc, fPesticide) => {
            if (queryParams.main_defensive_id == fPesticide.id) {
              fPesticide.mixtures.forEach((pesticide) => {
                acc.push({
                  pesticide: {
                    id: pesticide.defensive_id,
                    name: pesticide.defensive_name,
                    active_ingredients: pesticide.active_ingredients,
                  },
                  efficacies: pesticide.efficacies,
                })
              })
            }
            return acc
          }, [])
        } else {
          filteredPesticides = filteredPesticides.reduce((acc, pesticide) => {
            const encounteredDiseases = Object.keys(pesticide.doses).map(
              (diseaseName) => pesticide.doses[diseaseName].target_id
            )
            if (
              encounteredDiseases.includes(Number(queryParams.main_disease_id))
            ) {
              acc.push({
                pesticide: {
                  id: pesticide.id,
                  name: pesticide.name,
                  active_ingredients: pesticide.active_ingredients,
                },
                efficacies: pesticide.efficacies,
              })
            }
            return acc
          }, [])
        }
        return APIFailureWrapper({
          content: filteredPesticides,
          errorMessage:
            'Erro na listagem de sugestões de produtos para aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/spraying-products',
      result: ({ queryParams }) => {
        const filteredPesticides = pesticides.reduce((acc, pesticide) => {
          if (pesticide.crop_id == queryParams.crop_id) {
            acc.push({
              id: pesticide.id,
              name: pesticide.name,
              doses: pesticide.doses,
            })
          }
          return acc
        }, [])
        return APIFailureWrapper({
          content: filteredPesticides,
          errorMessage: 'Erro na listagem de produtos para aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/spraying-date-limits',
      result: ({ queryParams }) => {
        const sowing = getPlanningAndExecutedSowings().find(
          (sowing) => sowing.id == queryParams.sowing_id
        )
        return APIFailureWrapper({
          content: [
            addDaysToDate(sowing.date, 20),
            addDaysToDate(sowing.date, 40),
          ],
          errorMessage:
            'Erro na busca de limite de datas de aplicações da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'patch',
      url: '/spraying/:spraying_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        getAllSprayings().forEach((spraying) => {
          if (spraying.id == params.spraying_id) {
            spraying.main_disease_id = body.main_disease_id
            spraying.secondary_disease_id = body.secondary_disease_id
            if (body.spraying_date) spraying.applied_date = body.spraying_date
            if (body.products?.length) {
              spraying.products = body.products.map((product) => {
                return {
                  name: product.pesticide.name,
                  dose_per_hectare: product.dose,
                  price_per_liter: product.price,
                }
              })
            }
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na edição de aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'patch',
      url: '/sowing/:sowing_id/growth-stage',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        getExecutedSowings().forEach((sowing) => {
          if (sowing.id == params.sowing_id) {
            sowing.growth_stage = body.growth_stage
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na edição do estádio fenológico',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/spraying',
      result: ({ requestBody }) => {
        const body = JSON.parse(requestBody)
        const newSpraying = {
          id: getAllSprayings().length + 1,
          program_id: body.program_id,
          main_disease_id: body.main_disease_id,
          secondary_disease_id: body.secondary_disease_id,
          ideal_date: getCustomDate(10),
          applied_date: null,
          type: 'disease',
          spraying_order: body.spraying_order,
          products: body.products.map((product) => {
            return {
              id: product.pesticide.value,
              name: product.pesticide.name,
              dose_per_hectare: product.dose,
              price_per_liter: product.price,
            }
          }),
          efficacies: getRandomEfficacies([
            seasonDiseases[body.crop_name][0],
            seasonDiseases[body.crop_name][1],
            seasonDiseases[body.crop_name][2],
            seasonDiseases[body.crop_name][3],
            seasonDiseases[body.crop_name][4],
          ]),
        }
        if (!body.program_id && body.sowing_id) {
          const programToAdd = getExecutedPrograms().find(
            (spraying) => spraying.sowing_id == body.sowing_id
          )
          if (programToAdd) {
            newSpraying.program_id = programToAdd.id
          }
          newSpraying.sowing_id = body.sowing_id
          executedSprayings.push(newSpraying)
        } else if (body.program_id) {
          const currentProgram = getAllPrograms().find(
            (program) => program.id == body.program_id
          )
          if (currentProgram.status === 'executed') {
            newSpraying.sowing_id = body.sowing_id
            executedSprayings.push(newSpraying)
          } else {
            newSpraying.sowing_id = null
            if (body.program_id == 100 || body.program_id == 101) {
              const program = defaultProgramsMock.find(
                (program) => program.id == body.program_id
              )
              newSpraying.id =
                getAllSprayings().length +
                defaultProgramsMock[0].sprayings.length +
                defaultProgramsMock[1].sprayings.length +
                1
              program.sprayings.push(newSpraying)
            } else {
              planningSprayings.push(newSpraying)
            }
          }
        }
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na adição de aplicação',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/spraying/:spraying_id',
      result: ({ params }) => {
        // Both sprayings used in DiseasesControlHome, so I need to remove from them too
        for (let index = executedSprayings.length - 1; index >= 0; index--) {
          if (executedSprayings[index].id == params.spraying_id) {
            executedSprayings.splice(index, 1)
          }
        }
        for (let index = planningSprayings.length - 1; index >= 0; index--) {
          if (planningSprayings[index].id == params.spraying_id) {
            planningSprayings.splice(index, 1)
          }
        }
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na listagem de produtos para aplicação',
        })
      },
    },
    'on'
  ),
]

export { sowingViewRoutes }
