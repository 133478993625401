<template>
  <df-modal
    persistent
    width="640px"
    :action-name="$t('SowingView.register_spraying_modal.register')"
    :loading="loading"
    :title="$t('SowingView.register_spraying_modal.register_spraying')"
    @action-click="registerSpraying"
    @close="closeRegisterSprayingModal"
  >
    <template #card-content>
      <v-form ref="registerSprayingForm">
        <df-date-picker
          v-model="sprayingDate"
          :label="$t('SowingView.register_spraying_modal.spraying_date')"
          :loading="loadingSprayingDatesLimits"
          :max="maxSowingSprayingDate"
          :min="minSowingSprayingDate"
          :placeholder="$t('SowingView.register_spraying_modal.select_date')"
          :rules="[required]"
        />
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
import { required } from '@/utils/formRules'

export default {
  name: 'RegisterSprayingModal',

  components: {
    DfDatePicker,
    DfModal,
  },

  props: {
    sowingId: {
      required: true,
      type: Number,
    },
    spraying: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      loadingSprayingDatesLimits: false,
      maxSowingSprayingDate: '',
      minSowingSprayingDate: '',
      required,
      sprayingDate: this.spraying.applied_date,
    }
  },

  inject: {
    requestSowingSprayings: {
      from: 'requestSowingSprayings',
    },
  },

  async created() {
    this.loadingSprayingDatesLimits = true
    const params = {
      sowing_id: this.sowingId,
    }
    try {
      const { data } = await sowingViewService.getSprayingDateLimits(params)
      this.minSowingSprayingDate = data[0]
      this.maxSowingSprayingDate = data[1]
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingSprayingDatesLimits = false
    }
  },

  methods: {
    closeRegisterSprayingModal() {
      this.$emit('close')
    },
    async registerSpraying() {
      if (!this.$refs.registerSprayingForm.validate()) return
      this.loading = true
      const params = {
        spraying_date: this.sprayingDate,
        spraying_id: this.spraying.id,
      }
      try {
        await sowingViewService.editSpraying(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t('SowingView.register_spraying_modal.registered_spraying')
        )
        this.requestSowingSprayings()
        this.closeRegisterSprayingModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
