import { getFieldSowingsList } from '@/modules/sowingsList/mock/sowingsListSeeds'
import {
  executedSowings,
  getExecutedSowings,
  getPlanningAndExecutedSowings,
} from '@/services/__mocks__/sowingsSeeds'
import { APIFailureWrapper } from '@/utils/mock'
import { mockFlag } from '@/utils/mock'
import moment from 'moment'
import { fields } from '@/modules/plot/mock/fieldSeeds'
import { executedPrograms } from '@/modules/diseasesControl/mock/diseasesControlSeeds'
import { getCropNameById } from '@/utils/crops'
import { addDaysToDate } from '@/utils/time'

const sowingsListRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/season-sowings',
      result: ({ queryParams }) => {
        let filteredFieldSowingsList = getFieldSowingsList()
        const activeFieldIds = fields.reduce((acc, field) => {
          if (field.enabled && !field.deleted_at) acc.push(field.id)
          return acc
        }, [])
        filteredFieldSowingsList = filteredFieldSowingsList.filter(
          (fieldSowing) => activeFieldIds.includes(fieldSowing.field_id)
        )
        if (queryParams.farm_id) {
          filteredFieldSowingsList = filteredFieldSowingsList.filter(
            (fieldSowing) => fieldSowing.farm_id == queryParams.farm_id
          )
        }
        if (queryParams.field_id) {
          filteredFieldSowingsList = filteredFieldSowingsList.filter(
            (fieldSowing) => queryParams.field_id.includes(fieldSowing.field_id)
          )
        }
        if (queryParams.date_period) {
          filteredFieldSowingsList = filterByPeriod(
            filteredFieldSowingsList,
            queryParams.date_period
          )
        }
        if (queryParams.status) {
          filteredFieldSowingsList = filterByStatus(
            filteredFieldSowingsList,
            queryParams.status
          )
        }
        if (queryParams.crop_id) {
          filteredFieldSowingsList = filterByCrops(
            filteredFieldSowingsList,
            queryParams.crop_id
          )
        }
        if (queryParams.variety_id) {
          filteredFieldSowingsList = filterByVariety(
            filteredFieldSowingsList,
            queryParams.variety_id
          )
        }
        return APIFailureWrapper({
          content: filteredFieldSowingsList,
          errormessage: 'Erro na listagem de minhas semeaduras',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/harvest-date-limits',
      result: ({ queryParams }) => {
        const sowing = getPlanningAndExecutedSowings().find(
          (sowing) => sowing.id == queryParams.sowing_id
        )
        return APIFailureWrapper({
          content: [
            addDaysToDate(sowing.date, sowing.cycle - 10),
            addDaysToDate(sowing.date, sowing.cycle + 10),
          ],
          errormessage:
            'Erro na busca de limite de datas de colheita da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'patch',
      url: '/season-sowing/:sowing_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        getExecutedSowings().forEach((sowing) => {
          if (sowing.id == params.sowing_id) {
            sowing.area = Number(body.crop_zone.area)
            sowing.date = body.task.started_at
            sowing.sown_at = body.task.started_at
            sowing.emergence_date = body.task.extra_info.emergence_date
            sowing.cycle = body.task.extra_info.cycle
            sowing.seed_class_id = body.task.extra_info.seed_class_id
            sowing.germinative_power = body.task.extra_info.germinative_power
            sowing.seed_vigor = body.task.extra_info.seed_vigor
            sowing.seeder_system_id = body.task.extra_info.seeder_system_id
            sowing.line_spacing = body.task.extra_info.line_spacing
            sowing.population_lines = body.task.extra_info.population_lines
            sowing.sown_quantity = Number(body.task.extra_info.sown_quantity)
            sowing.operating_speed = body.task.extra_info.operating_speed
            sowing.origin = body.task.extra_info.origin
            sowing.seed_treatment = body.task.extra_info.seed_treatment
            sowing.inoculant = body.task.extra_info.inoculant
            sowing.harvested = body.task.extra_info.harvested
            sowing.harvest_quantity = body.task.extra_info.harvest_quantity
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na edição de semeadura de safra',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/season-sowing',
      result: ({ requestBody }) => {
        const body = JSON.parse(requestBody)
        const id = getPlanningAndExecutedSowings().length + 1
        const newExecutedSowing = {
          id: id,
          farm_id: body.crop_zone.farm_id,
          field_id: body.crop_zone.field_id,
          variety_id: body.crop_zone.variety_id,
          variety_name: 'Mock',
          crop_id: body.crop_zone.crop_id,
          crop_message_key: getCropNameById(body.crop_zone.crop_id),
          cycle: body.task.extra_info.cycle,
          sowing_status: 'executed',
          harvest_status: 'scheduled',
          growth_stage: null,
          estimated_date: body.task.estimated_start_date,
          date: body.task.started_at,
          emergence_date: body.task.extra_info.emergence_date,
          harvested_at: null,
          area: body.crop_zone.area,
          seed_class_id: body.task.extra_info.seed_class_id,
          germinative_power: body.task.extra_info.germinative_power,
          seed_vigor: body.task.extra_info.seed_vigor,
          seeder_system_id: body.task.extra_info.seeder_system_id,
          line_spacing: body.task.extra_info.line_spacing,
          population_lines: body.task.extra_info.population_lines,
          sown_quantity: body.task.extra_info.sown_quantity,
          operating_speed: body.task.extra_info.operating_speed,
          origin: body.task.extra_info.origin,
          seed_treatment: body.task.extra_info.seed_treatment,
          inoculant: body.task.extra_info.inoculant,
          harvested: body.task.extra_info.harvested,
          harvest_quantity: body.task.extra_info.harvest_quantity,
        }
        executedSowings.push(newExecutedSowing)
        return APIFailureWrapper({
          content: id,
          errorMessage: 'Erro na criação da semeadura executada',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/season-sowing/:sowing_id/copy',
      result: ({ params, requestBody }) => {
        let cropId,
          cropMessageKey,
          estimatedHarvestDate,
          growthStage,
          harvestedAt,
          productivity,
          sownAt,
          status,
          toSowDate,
          varietyId,
          varietyName

        const body = JSON.parse(requestBody)
        getFieldSowingsList().forEach((fieldSowing) => {
          fieldSowing.sowings.forEach((sowing) => {
            if (sowing.id == params.sowing_id) {
              cropId = sowing.crop_id
              cropMessageKey = sowing.crop_message_key
              estimatedHarvestDate = sowing.estimated_harvest_date
              growthStage = sowing.growth_stage
              harvestedAt = sowing.harvested_at
              productivity = sowing.productivity
              sownAt = sowing.sown_at
              status = sowing.status
              toSowDate = sowing.to_sow_date
              varietyId = sowing.variety_id
              varietyName = sowing.variety_name
            }
          })
        })
        const sowingQtt = sowingQuantity(getFieldSowingsList())
        body.field_id.forEach((fieldId) => {
          getFieldSowingsList().forEach((fieldSowing) => {
            if (fieldSowing.field_id == fieldId) {
              const sowingObj = {
                crop_id: cropId,
                crop_message_key: cropMessageKey,
                estimated_harvest_date: estimatedHarvestDate,
                growth_stage: growthStage,
                harvested_at: harvestedAt,
                id: sowingQtt + 1,
                productivity,
                sown_at: sownAt,
                status,
                to_sow_date: toSowDate,
                variety_id: varietyId,
                variety_name: varietyName,
              }
              fieldSowing.sowings.push(sowingObj)
            }
          })
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na criação/duplicação da semeadura',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'post',
      url: '/register-harvest/:sowing_id',
      result: ({ params, requestBody }) => {
        const body = JSON.parse(requestBody)
        getFieldSowingsList().forEach((fieldSowing) => {
          fieldSowing.sowings.forEach((sowing) => {
            if (sowing.id == params.sowing_id) {
              sowing.status = 'harvested'
              sowing.harvested_at = body.harvest_date
              sowing.productivity = 50
            }
          })
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro no registro da colheita',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'delete',
      url: '/delete-season-sowing/:sowing_id',
      result: ({ params }) => {
        getFieldSowingsList().forEach((fieldSowing) => {
          for (
            let index = fieldSowing.sowings.length - 1;
            index >= 0;
            index--
          ) {
            if (fieldSowing.sowings[index].id == params.sowing_id) {
              fieldSowing.sowings.splice(index, 1)
            }
          }
        })
        return APIFailureWrapper({
          content: null,
          errorMessage: 'Erro na deleção de semeadura',
        })
      },
    },
    'on'
  ),
]

const filterByPeriod = (allFieldSowingsList, period) => {
  let startDate
  let endDate
  if (period.includes('_')) {
    const dates = period.split('_')
    startDate = dates[0]
    endDate = dates[1]
  }
  return allFieldSowingsList.map((obj) => ({
    ...obj,
    sowings: obj.sowings.filter((sowing) => {
      if (startDate && endDate) {
        return isDateBetween(
          sowing.sown_at || sowing.to_sow_date,
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
        )
      }
      return isDateBetween(
        sowing.sown_at || sowing.to_sow_date,
        moment(period, 'YYYY').startOf('year').format('YYYY-MM-DD'),
        moment(period, 'YYYY').endOf('year').format('YYYY-MM-DD')
      )
    }),
  }))
}

const filterByCrops = (allFieldSowingsList, crops) => {
  return allFieldSowingsList
    .map((obj) => ({
      ...obj,
      sowings: obj.sowings.filter((sowing) =>
        crops.includes(String(sowing.crop_id))
      ),
    }))
    .filter((field) => !!field.sowings.length)
}

const filterByVariety = (allFieldSowingsList, varietyId) => {
  return allFieldSowingsList
    .map((obj) => ({
      ...obj,
      sowings: obj.sowings.reduce((acc, sowing) => {
        if (sowing.variety_id == varietyId) {
          const newSowing = getExecutedSowings().find(
            (executedSowing) => executedSowing.id == sowing.id
          )
          const sowingHasProgram = executedPrograms.some(
            (program) => program.sowing_id == sowing.id
          )
          sowing.area = newSowing.area
          sowing.has_program = sowingHasProgram
          acc.push(sowing)
        }
        return acc
      }, []),
    }))
    .filter((field) => !!field.sowings.length)
}

const filterByStatus = (allFieldSowingsList, status) => {
  return allFieldSowingsList
    .map((obj) => ({
      ...obj,
      sowings: obj.sowings.filter((sowing) =>
        status.includes(String(sowing.status))
      ),
    }))
    .filter((field) => !!field.sowings.length)
}

const isDateBetween = (currentDate, startDate, endDate) => {
  const date = moment(currentDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
  const isBetween = moment(date).isBetween(startDate, endDate, undefined, '[)')
  return isBetween
}

const sowingQuantity = (allFieldSowingsList) => {
  return allFieldSowingsList.reduce((acc, fieldSowing) => {
    acc += fieldSowing.sowings.length
    return acc
  }, 0)
}

export { sowingsListRoutes }
