import http from '@/utils/http'
import { queryBuilder, sameNameParamQuery } from '@/utils/apiQueryBuilder'

const sowings = {
  getSowingSprayings: ({ sowing_ids, spraying_status }) => {
    // CM: ver qual a url correta
    return http.get(
      `/sowing-sprayings${queryBuilder(
        sameNameParamQuery({ sowing_ids }),
        sameNameParamQuery({ spraying_status })
      )}`
    )
  },
  getSprayingEfficacies: ({ spraying_id }) => {
    // CM: ver qual a url correta
    return http.get(
      `/spraying-efficacies${queryBuilder(sameNameParamQuery({ spraying_id }))}`
    )
  },
  getSowingInfos: ({ sowing_id }) => {
    // CM: ver qual a url correta
    return http.get(
      `/sowing-infos${queryBuilder(sameNameParamQuery({ sowing_id }))}`
    )
  },
  getProducts: ({ crop_id, farm_id, program_id }) => {
    // CM: ver qual a url correta
    return http.get(
      `/spraying-products${queryBuilder(
        sameNameParamQuery({ crop_id }),
        sameNameParamQuery({ farm_id }),
        sameNameParamQuery({ program_id })
      )}`
    )
  },
  getProductsInfos: ({ product_ids }) => {
    // CM: ver qual a url correta
    return http.get(
      `/pesticide-infos${queryBuilder(
        `pesticide_ids=${product_ids.join(',')}`
      )}`
    )
  },
  getProductSuggestions: ({
    country_id,
    crop_id,
    main_disease_id,
    main_defensive_id,
    season_crop,
    secondary_disease_id,
    spraying_number,
  }) => {
    // CM: ver qual a url correta
    return http.get(
      `/spraying-suggestions${queryBuilder(
        sameNameParamQuery({ main_disease_id }),
        sameNameParamQuery({ country_id }),
        sameNameParamQuery({ crop_id }),
        sameNameParamQuery({ main_defensive_id }),
        sameNameParamQuery({ season_crop }),
        sameNameParamQuery({ secondary_disease_id }),
        sameNameParamQuery({ spraying_number })
      )}`
    )
  },
  getDiseasesSuggestions: ({ spraying_number }) => {
    // CM: ver qual a url correta
    return http.get(
      `/diseases-suggestions${queryBuilder(
        sameNameParamQuery({ spraying_number })
      )}`
    )
  },
  getCurrentDiseases: ({ spraying_id }) => {
    // CM: ver qual a url correta
    return http.get(
      `/diseases-spraying${queryBuilder(sameNameParamQuery({ spraying_id }))}`
    )
  },
  getSprayingDateLimits: ({ sowing_id }) => {
    return http.get(
      `/spraying-date-limits${queryBuilder(sameNameParamQuery({ sowing_id }))}`
    )
  },
  editSpraying: ({
    main_disease_id,
    products,
    secondary_disease_id,
    spraying_date,
    spraying_id,
  }) => {
    // CM: ver qual a url correta
    return http.patch(`/spraying/${spraying_id}`, {
      main_disease_id,
      products,
      spraying_date,
      secondary_disease_id,
    })
  },
  editGrowthStage: ({ growth_stage, sowing_id }) => {
    // CM: ver qual a url correta
    return http.patch(`/sowing/${sowing_id}/growth-stage`, { growth_stage })
  },
  createSpraying: ({
    crop_name,
    main_disease_id,
    products,
    program_id,
    secondary_disease_id,
    sowing_id,
    spraying_order,
  }) => {
    // CM: ver qual a url correta
    return http.post('/spraying', {
      crop_name,
      main_disease_id,
      products,
      program_id,
      secondary_disease_id,
      sowing_id,
      spraying_order,
    })
  },
  deleteSpraying: ({ spraying_id }) => {
    // CM: ver qual a url correta
    return http.delete(`/spraying/${spraying_id}`)
  },
}

export default sowings
