<template>
  <v-menu
    v-model="toggle"
    min-width="auto"
    offset-y
    transition="scale-transition"
    :close-on-content-click="false"
    :disabled="disabled"
  >
    <template #activator="{ on }">
      <div class="df-flex-sm df-flex-col df-date-picker">
        <h3>{{ label }}<span v-if="hasRequiredRule">*</span></h3>
        <v-text-field
          data-id="date-picker-text"
          v-on="on"
          v-model="formattedValue"
          dense
          flat
          outlined
          readonly
          solo
          :background-color="backgroundColor"
          :disabled="disabled"
          :hint="hint"
          :loading="loading"
          :placeholder="placeholder"
          :rules="rules"
        >
          <template #append>
            <font-awesome-icon color="#788476" icon="calendar" />
          </template>
        </v-text-field>
      </div>
    </template>
    <v-date-picker
      data-id="date-picker"
      v-model="date"
      color="#5b6459"
      no-title
      :allowed-dates="allowedDates"
      :event-color="eventColor"
      :events="events"
      :locale="locale"
      :max="max"
      :min="min"
      @click:date="onDateClick"
    >
      <template>
        <div class="df-flex-l df-flex-col actions__wrapper">
          <div class="df-flex-l justify-end picker__actions">
            <df-button
              class="cancel-button"
              variant="text-secondary"
              @click="closeDatePicker"
            >
              {{ $t('Components.date_picker.cancel') }}
            </df-button>
            <df-button
              data-id="confirm-date"
              :loading="loading"
              @click="pickDate"
            >
              {{ $t('Components.date_picker.choose') }}
            </df-button>
          </div>
          <slot name="caption"></slot>
        </div>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'

export default {
  name: 'DfDatePicker',

  components: {
    DfButton,
  },

  props: {
    allowedDates: {
      default: () => [],
      type: Function,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    events: {
      default: () => [],
      type: Function,
    },
    eventColor: {
      default: () => [],
      type: Function,
    },
    hint: {
      default: '',
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    max: {
      default: '',
      type: String,
      validator(value) {
        if (!value) {
          return true
        }
        const datePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
        return datePattern.test(value)
      },
    },
    min: {
      default: '',
      type: String,
      validator(value) {
        if (!value) {
          return true
        }
        const datePattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
        return datePattern.test(value)
      },
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    value: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      hasRequiredRule: false,
      selectedDate: '',
      toggle: false,
    }
  },

  created() {
    this.rules.some((rule) => {
      if (rule.name === 'required') this.hasRequiredRule = true
    })
  },

  watch: {
    value(newValue) {
      this.date = newValue
    },
  },

  computed: {
    backgroundColor() {
      return this.disabled ? '#e6e9e6' : '#f8f9f8'
    },
    date: {
      get() {
        return this.value
      },
      set(value) {
        this.selectedDate = value
      },
    },
    formattedValue() {
      return this.date ? this.date.formatDate() : ''
    },
    locale() {
      const locales = {
        en: 'en-US',
        es: 'es-PY',
        pt: 'pt-BR',
      }
      return locales[localStorage.getItem('language')]
    },
  },

  methods: {
    changeValue() {
      this.$emit('input', this.selectedDate)
      this.$emit('change', this.selectedDate)
    },
    closeDatePicker() {
      this.toggle = false
    },
    onDateClick(_, event) {
      const button = event.currentTarget.closest('button')

      if (button) {
        const allButtons = document.querySelectorAll('button')
        allButtons.forEach((btn) => btn.classList.remove('clicked'))

        button.classList.add('clicked')
      }
    },
    pickDate() {
      this.changeValue()
      this.closeDatePicker()
    },
  },
}
</script>

<style lang="scss" scoped>
.df-date-picker {
  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    span {
      color: #f23528;
    }
  }
}
.actions__wrapper {
  padding: 16px;
  .picker__actions {
    .cancel-button {
      border: 1px solid #e6e9e6;
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
// placeholder
::v-deep .v-text-field__slot input::placeholder {
  overflow: hidden;
  color: #5b6459;
  text-overflow: ellipsis;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
::v-deep .v-input--is-disabled .v-text-field__slot input::placeholder,
::v-deep .v-input--is-disabled .v-input__append-inner svg {
  color: #aab2a9;
}
::v-deep .v-input__slot {
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
}
::v-deep .v-input--is-focused fieldset {
  border-color: #39af49 !important;
}
::v-deep .v-input__slot {
  cursor: pointer !important;
}
::v-deep .v-input--is-disabled .v-input__slot,
::v-deep .v-input--is-disabled .v-text-field__slot input {
  cursor: auto !important;
}
// selected text
::v-deep .v-text-field__slot input {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0px;
  cursor: pointer !important;
}
::v-deep .v-input__append-inner svg {
  color: #5b6459;
}
// error messages
::v-deep .v-text-field__details {
  padding: 0px !important;
  margin: 2px 0px 0px 0px !important;

  .v-messages__message {
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
// picker-menu
.v-menu__content {
  border: 1px solid #e6e9e6;
  border-radius: 8px !important;
  box-shadow: none !important;
}
::v-deep .v-card {
  border-radius: 8px !important;
}
::v-deep .v-picker__body {
  margin: 0px;
  width: 312px !important;
}
::v-deep .v-date-picker-header {
  border-radius: 8px 8px 0px 0px !important;
  padding: 16px;
  display: flex;

  & .v-date-picker-header__value {
    order: 1;
    text-align: start !important;
  }
  & .v-btn {
    order: 2;
    color: #121212;
  }
  & button {
    color: #071505;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
::v-deep .v-date-picker-table {
  height: fit-content;
  padding: 0px 16px;

  &__current {
    border-color: #e6e9e6;
  }
}
::v-deep .v-picker__actions {
  border-radius: 0px 0px 8px 8px !important;
  padding: 0px;
}
::v-deep .v-date-picker-table {
  th {
    color: #5b6459;
  }
  & .v-btn {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    &--active {
      color: #39af49 !important;
      background-color: #d8f2dc !important;
    }
  }
}
::v-deep .v-date-picker-table__events div {
  height: 4px;
  width: 4px;
}
::v-deep .clicked:not(.v-btn--active) {
  border: 1px solid #39af49;
  background-color: #fff;

  & .v-btn__content {
    color: #39af49;
  }
}
</style>
