import authModuleTranslation from '@/modules/auth/translation'
import catalogModuleTranslations from '@/modules/catalog/translation'
import comparisonTranslations from '@/modules/comparison/translation'
import diseasesControlTranslations from '@/modules/diseasesControl/translation'
import homeTranslations from '@/modules/home/translation'
import menuModuleTranslations from '@/modules/menu/translation'
import notesModuleTranslations from '@/modules/notes/translation'
import performanceTranslations from '@/modules/performance/translation'
import plansModuleTranslations from '@/modules/plans/translation'
import plotModuleTranslations from '@/modules/plot/translation'
import reportTranslations from '@/modules/reports/translation'
import planningTranslations from '@/modules/sowingsPlanning/translation'
import sowingsTranslations from '@/modules/sowingView/translation'
import sowingsListTranslations from '@/modules/sowingsList/translation'
import bannersTranslations from '@/components/Banners/translation'
import digifarmzComponentsTranslations from '@/lib/components/translation'
import modalTranslations from '@/lib/Modal/translation'
import weatherTranslations from '@/components/Weather/translation'

export default {
  // modules
  ...authModuleTranslation.pt,
  ...catalogModuleTranslations.pt,
  ...comparisonTranslations.pt,
  ...diseasesControlTranslations.pt,
  ...homeTranslations.pt,
  ...menuModuleTranslations.pt,
  ...notesModuleTranslations.pt,
  ...performanceTranslations.pt,
  ...plansModuleTranslations.pt,
  ...plotModuleTranslations.pt,
  ...reportTranslations.pt,
  ...planningTranslations.pt,
  ...sowingsTranslations.pt,
  ...sowingsListTranslations.pt,
  // components
  ...bannersTranslations.pt,
  ...digifarmzComponentsTranslations.pt,
  ...modalTranslations.pt,
  ...weatherTranslations.pt,

  $vuetify: {
    badge: 'Distintivo',
    close: 'Fechar',
    loading: 'Carregando',
    dataIterator: {
      noResultsText: 'Nenhum dado encontrado',
      loadingText: 'Carregando itens...',
    },
    dataTable: {
      itemsPerPageText: 'Linhas por página:',
      ariaLabel: {
        sortDescending: 'Ordenado decrescente.',
        sortAscending: 'Ordenado crescente.',
        sortNone: 'Não ordenado.',
        activateNone: 'Ative para remover a ordenação.',
        activateDescending: 'Ative para ordenar decrescente.',
        activateAscending: 'Ative para ordenar crescente.',
      },
      sortBy: 'Ordenar por',
    },
    dataFooter: {
      itemsPerPageText: 'Itens por página:',
      itemsPerPageAll: 'Todos',
      nextPage: 'Próxima página',
      prevPage: 'Página anterior',
      firstPage: 'Primeira página',
      lastPage: 'Última página',
      pageText: '{0}-{1} de {2}',
    },
    datePicker: {
      itemsSelected: '{0} selecionado(s)',
      prevYearAriaLabel: 'Ano anterior',
      nextYearAriaLabel: 'Próximo ano',
      prevMonthAriaLabel: 'Mês anterior',
      nextMonthAriaLabel: 'Próximo mês',
    },
    input: {
      clear: 'Limpar',
    },
    noDataText: 'Não há dados disponíveis',
    carousel: {
      prev: 'Visão anterior',
      next: 'Próxima visão',
      ariaLabel: {
        delimiter: 'Slide {0} de {1} do carrossel',
      },
    },
    calendar: {
      moreEvents: 'Mais {0}',
    },
    fileInput: {
      counter: '{0} arquivo(s)',
      counterSize: '{0} arquivo(s) ({1} no total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
  },
  // Digifarmz
  language: {
    pt: 'Português',
    es: 'Español',
    en: 'English',
  },
  yes: 'Sim',
  no: 'Não',
  or: 'Ou',
  close: 'Fechar',
  password_recovery: {
    temporary_confirmation:
      'Você solicitou um pedido de recuperação de senha? A senha temporária expira em 1 dia. Clique em "sim" para atualizar sua senha.',
  },
  configurations: 'Configurações',
  loading: 'Carregando...',
  filter: 'Filtrar',
  category: 'Categoria',
  register_: 'Cadastrar',
  clean_filter: 'Limpar filtro',
  results_for: 'Resultados para',
  results: 'Resultados encontrados',
  sort: 'Ordenar',
  pageText: 'Página {0} de {1}',
  limpar: 'Limpar',
  cancelar: 'Cancelar',
  excluir: 'Excluir',
  editar: 'Editar',
  ver: 'Ver',
  copiar: 'Copiar',
  copiar_para: 'Copiar para...',
  salvar: 'Salvar',
  confirmar: 'Confirmar',
  enviar: 'Enviar',
  feito: 'Feito',
  importar: 'Importar',
  criar: 'Criar',
  back: 'Voltar',
  next: 'Próximo',
  area: 'Área',
  sair: 'Sair',
  saca: 'Saca',
  get_in_touch: 'Entrar em contato',
  which_channel_to_contact:
    'Com qual canal você gostaria de entrar em contato?',
  commercial: 'Comercial',
  support: 'Suporte',
  relationship: 'Relacionamento',
  adicionar: 'Adicionar',
  select: 'Selecione',
  desfazer: 'Desfazer',
  enable: 'Habilitar',
  function_temporarily_unavailable: 'Função temporariamente indisponível',
  disable: 'Desabilitar',
  she_enabled: 'Habilitada',
  she_disabled: 'Desabilitada',
  he_enabled: 'Habilitado',
  he_disabled: 'Desabilitado',
  enabled_s: 'Habilitadas',
  disabled_s: 'Desabilitadas',
  she_disable: 'Desabilitar',
  he_disable: 'Desabilitar',
  utilized: 'Utilizada',
  contracted: 'Contratada',
  total_area: 'Total de área',
  select_date: 'Selecione a data',
  total: 'Total',
  quote: 'Cotação',
  active: 'Ativo',
  inactive: 'Inativo',
  select_period: 'Escolha um período',
  select_another_period: 'Escolha outro período',
  select_file: 'Selecione um arquivo',
  upload_file: 'Carregar arquivos',
  semeadura_em: 'Semeadura em {0}',
  emergencia_em: 'Emergência em',
  emergency_date: 'Data de emergência',
  custo_total_hectares: 'Custo total por hectares',
  aplicado_em: 'Aplicado em',
  desconhecido: 'Desconhecido',
  campo_obrigatorio: 'Campo obrigatório',
  date_should_be_after: 'Data deve ser posterior a',
  set_start_date: 'Coloque data de início',
  required: ' *Obrigatório',
  nome_muito_longo: 'Nome muito longo',
  preencha_corretamente_campos: 'Preencha corretamente os campos',
  apply_filter: 'Aplicar filtro',
  aplicar: 'Aplicar',
  nome: 'Nome',
  title: 'Título',
  description: 'Descrição',
  email: 'E-mail',
  profile: 'Perfil',
  alta: 'Alta',
  media: 'Média',
  baixa: 'Baixa',
  moeda: 'R$',
  price: 'Preço',
  required_price: 'Preço obrigatório',
  succesfuly_saved: 'Salvo com sucesso',
  data_ideal: 'Data ideal',
  until: 'até',
  day: 'dia',
  days: 'dias',
  dias_de_atraso: '{0} dias de atraso',
  sem_registro: 'Fungicidas sem registro no órgão responsável',
  one_question_before_continue: 'Uma pergunta antes de continuar',
  what_is_your_job_title_today: 'Qual é o seu cargo hoje',
  we_want_to_know_to_improve_your_experience:
    'Queremos saber para melhorar sua experiência',
  date: 'Data',
  dias_semana: {
    hoje: 'hoje',
    ter: 'ter',
    qua: 'qua',
    qui: 'qui',
    sex: 'sex',
    sab: 'sab',
    dom: 'dom',
  },
  of: 'de',
  created_by: 'Criado por',
  edited_by: 'Editado por',
  print: 'Imprimir',
  priority: 'prioritária',
  secondary: 'secundária',
  learn_more: 'Saiba mais em',
  did: 'Realizou o',
  used: 'Utilizou',
  _to_: '{0} a {1}',
  thousand: 'mil',
  tour: {
    notes_navbar: 'Você pode acessar suas anotações através deste menu.',
    filter_notes:
      'Você pode filtrar suas anotações por <strong>categoria, talhão, data e usuário</strong>.',
    create_note:
      'Para criar uma nova anotação, clique no botão de nova anotação.',
    menu_changed: 'O menu mudou, agora você pode acessá-lo por aqui. ',
    user_menu: 'Aqui você acessa informações da sua conta',
    skip: 'Sair do Tour',
    end: 'Finalizar',
  },
  message_area_exceeded:
    'Você excedeu a sua área contratada. Desabilite fazendas e talhões ou contrate mais área.',
  message_farm_disabled:
    'Essa fazenda está desabilitada, você não poderá editar ou criar nada. Habilite para utilizar',
  closure: 'Fechamento',
  bought: 'Comprado',
  own: 'Próprio',
  localization: 'Localização',
  vazio_sanitario: {
    vazio_sanitario: 'Vazio sanitário',
    disable_navbar_btn:
      'Safra desabilitada devido o período de vazio sanitário.',
    termino_para_semeadura:
      'Aguardando término do período de vazio sanitário para a semeadura.',
  },
  indicator: 'Indicador',
  square: 'Praça',
  dollar_rate: 'Cotação do dólar',
  physical_market: 'Mercado Físico',
  stock_chicago: 'Bolsa Chicago',
  commodity: 'Commodity',
  communication: 'Comunicados',
  toolbar: {
    title_modal_crop_bag_price: 'Preço das culturas',
    price_per_seed: 'Saca',
  },
  no_data_available: 'Sem dados disponíveis',
  data_available_to_farm_enabled:
    'Dados disponíveis apenas para fazendas habilitadas',
  register: {
    register: 'Registrar',
    price: 'Cadastrar preço',
    bag_price: 'Cadastre o preço da saca da cultura',
    secundary_title_whitout_bag_price: 'Antes de cadastrar',
    secundary_text_whitout_bag_price:
      'Para poder utilizar a etapa de safra, você precisa primeiro cadastrar o preço da cultura. Esse valor permitirá você analisar o custo-benefício dos programas',
    secundary_title_did_not_fill_all_harvest:
      'Algumas semeaduras não estão preenchidas. Deseja continuar mesmo assim?',
    after: 'Sim, registrar depois',
    sowing: 'Cadastrar semeaduras',
    sowing_register: 'Registre sua semeadura',

    register_sowing: 'Registre suas semeaduras',
    secundary_title_whitout_sowing: 'Registre semeaduras',
    secundary_text_whitout_sowing:
      'Para acompanhar suas aplicações e as datas, primeiro você precisa registrar suas semeaduras aqui na DigiFarmz clicando no botão abaixo.',
  },

  plan: {
    upgrade_plan: 'Faça upgrade de plano',
    menu: {
      my_farm: 'Minha Fazenda',
      planting: 'Semeaduras',
      notes: 'Anotações',
      planning: 'Planejamento',
      performance: 'Performance',
      catalog: 'Catálogo',
      plot: 'Talhões',
      no_result: 'Sem resultado',
    },
  },

  weather: {
    info: 'Informações climáticas',
    without_weather: 'Estamos coletando seus dados climáticos',
    wait_to_load: 'Aguarde até 24h para receber informações de clima',
  },

  crop: {
    crop: 'Cultivo',
    soybean: 'Soja',
    wheat: 'Trigo',
    corn: 'Milho',
    save: {
      price: {
        success: 'Preço cadastrado com sucesso!',
        error: 'Erro ao cadastrar preço!',
      },
    },
  },

  profiles: {
    ADMIN: 'Administrador Geral',
    CONTA: 'Administrador de Conta',
    FARM: 'Eng. Agronomo',
    ASSISTENTE_TECNICO: 'Observador',
    FINANCEIRO: 'Financeiro',
    COMERCIAL: 'Comercial',
    ADM_CORP: 'Administrador Corp',
    VEND_CORP: 'Vendedor Corp',
    VEND_DGF: 'Gestor de Cupons',
    TEC_DGF: 'Gestor Racionais',
    CON_REV: 'Consultor de Revenda',
    PESQUISA: 'Pesquisa',
    description: {
      ADMIN: 'Pode editar, visualizar e criar tudo',
      CONTA: 'Pode editar, visualizar e criar tudo',
      FARM: 'Pode editar tudo, exceto fazendas e talhões',
      ASSISTENTE_TECNICO: 'Somente visualização',
      FINANCEIRO: 'Financeiro',
      COMERCIAL: 'Comercial',
      ADM_CORP: 'Administrador Corp',
      VEND_CORP: 'Vendedor Corp',
      VEND_DGF: 'Gestor de Cupons',
      TEC_DGF: 'Gestor Racionais',
      CON_REV: 'Pode editar, visualizar e criar, exceto usuários',
      PESQUISA:
        'Pode visualizar usuários, criar e editar programas, semeaduras, fazendas e talhões',
    },
  },

  files: {
    type_files_allowed:
      'Tipos de arquivos permitidos: jpg, jpeg, png, heic, xlsx, xls, pdf, doc, docx',
    size_files_allowed: 'Tamanho máximo de arquivo: 5MB',
  },

  variety: {
    list: 'LISTA DE CULTIVARES',
    hide_created: 'Ocultar cadastradas',
    show_created: 'Mostrar cadastradas',
    new: 'Nova Cultivar',
    variety_info: 'Informações da cultivar',
    create: 'Cadastrar cultivar de {0}',
    already_created: 'JÁ CADASTRADA',
    select_variety: 'Selecione uma cultivar para ver informações detalhadas',
    create_selected: 'Cadastrar selecionadas',
    unavailable_create: 'CADASTRO INDISPONÍVEL',
    unavailable_wheat_region_title: 'Região não está apta para cultivo de {0}',
    unavailable_wheat_region_complement:
      'Sua região não está recomendada para cultivo de {0} de acordo com o zoneamento agroclimático desta cultura',
    not_recommended: 'NÃO INDICADO PARA REGIÃO',
    sort: {
      seed_name_az: 'nome (A-Z)',
      seed_name_za: 'nome (Z-A)',
      GMR_small: 'GMR (Menor)',
      GMR_strong: 'GMR (Maior)',
      owner_az: 'Empresa (A-Z)',
      owner_za: 'Empresa (Z-A)',
    },
    seed_name: 'Nome da cultivar',
    owner: 'Empresa',
    request_variety: 'Solicitar cultivar',
    request_not_catologed_variety: 'Solicitar cultivar não catalogada',
    not_cataloged_variety: 'Cultivar não catalogada',
    no_result:
      'Se você não encontrou a cultivar que procurava, clique no botão abaixo e faça uma solicitação',
    no_result_title: 'Nenhum resultado encontrado',
    planned: 'CULTIVARES JÁ PLANEJADAS',
    not_planned: 'OUTRAS CULTIVARES',
  },

  job_titles: {
    farm_administrator: 'Administrador(a) da Fazenda',
    agricultural_technical_consultancy: 'Consultoria Técnico Agrícola',
    manager: 'Gerente',
    agricultural_engineering_consultancy: 'Consultoria Engenharia Agrícola',
    machine_operator: 'Operador de Máquinas',
    operation_supervisor: 'Supervisor(a) de Operação',
    dealer: 'Revendedor(a)',
    researcher: 'Pesquisador(a)',
    student: 'Estudante',
    other: 'Outro',
    manager_admin_digifarmz: 'Administrador Digifarmz',
  },

  season: {
    version_previous: 'Versões de safras anteriores',
    version_actual: 'Versão atual',
    version: 'Versão',
  },

  safra: {
    actual: 'Safra Atual',
    previous: 'Safras anteriores',
    safra: 'Safra',
    inativa: 'Safra inativa.',
    alterar_safra: 'Alterar Safra',
    contratar_safra: 'Contratar Safra',
    register_previous_season: 'Cadastrar Safra Anteriror',
  },
  anterior: 'anterior',
  previous: 'Anterior',
  permissao: {
    erro: 'Erro ao carregar permissão!',
  },
  ndvi: {
    dates_for_analysis: 'Imagens para análise',
    list: {
      no_content:
        'Nenhuma imagem foi encontrada para análise no período selecionado.',
    },
    level_label: 'Nível do NDVI:',
    low: 'Baixo',
    medium: 'Médio',
    high: 'Alto',
    know_more: 'Saiba mais sobre NDVI',
  },
  integration: {
    integrations: 'Integrações',
    sync: 'Atualizar novamente',
    sync_manual: 'Atualizar manualmente',
    sync_success: 'Tentativa de sincronismo realizada com sucesso.',
    sync_alert:
      'Fazendas e Talhões ainda estão sendo importados do <strong>Climate FieldView</strong> e este processo pode levar algumas horas.',
    undo_integration: 'Desfazer',
    error: {
      title: 'Ops! Parece que aconteceu algum problema.',
      title_exceeded: 'Não foi possível importar suas fazendas e talhões',
      description:
        'Tivemos algum problema ao importar os seus dados. Por favor, tente novamente.',
      description_exceeded:
        'Parece que seus talhões possuem mais área do que você tem contratado conosco. Mas não se preocupe, é só entrar em contato com nossa equipe de vendas e solicitar o upgrade.',
      upgrade: 'Aumentar minha área',
      try_again: 'Tentar novamente',
    },
    fieldview: {
      fieldview: 'Climate FieldView&trade;',
      title_part: 'Conecte sua conta com a',
      title_success: 'Integração realizada com sucesso',
      description:
        'Para conectar você terá que clicar no botão abaixo e entrar em sua conta Climate FieldView&trade;, autorizando a conexão.',
      description_2: 'DE LÁ, IREMOS TRAZER AS SEGUINTES INFORMAÇÕES PARA VOCÊ:',
      description_success:
        'Fazendas e Talhões ainda estão sendo importados do <b>Climate Fieldview</b> e este processo pode demorar algumas horas. Você pode clicar em atualizar a qualquer momento.',
      list: {
        farms: 'Fazendas',
        plots: 'Talhões',
      },
      btnBurger: 'Integrar com Climate FieldView&trade;',
      btn: 'Integrar Climate FieldView',
      btnStart: 'Voltar para fazendas',
    },
  },

  user: {
    user: 'Usuários',
    users: 'Usuário',
    register: 'Adicionar Usuário',
    edit: 'Editar Usuário',
    register_success: 'Usuário registrado com sucesso',
    delete_confirm: 'Você tem certeza que deseja excluir este usuário?',
    delete_success: 'Usuário excluído com sucesso',
  },

  farm: {
    farm: 'Fazenda',
    access_farm: 'Acesso a Fazenda',
    my_farm: 'Minha Fazenda',
    select_farm: 'Selecione uma fazenda',
    see_all_farms: 'Ver todas as fazendas',
  },

  fazenda: {
    NoFarms: {
      welcome_no_farms: 'Você ainda não possui',
      welcome_no_farms_bold: 'nenhuma fazenda cadastrada',
    },
    fazendas: 'Fazendas',
    excluir: {
      sucesso: 'Fazenda excluída com sucesso',
      erro: 'Erro ao excluir fazenda',
      alerta: {
        titulo: 'Tem certeza?',
        mensagem:
          'Ao excluir a fazenda, os programas associados serão perdidos. Esta operação é irreversível.',
      },
    },
    listar: {
      erro: 'Erro ao listar fazendas.',
    },
    carregar: {
      erro: 'Erro ao carregar fazendas.',
      erro_400: 'Falha ao carregar programas (status 400)',
    },
    salvar: {
      sucesso: 'Fazenda salva com sucesso.',
      erro: 'Erro ao salvar fazendas.',
    },
    enable: {
      success: 'Fazenda habilitada com sucesso.',
      warning:
        'Ao desabilitar o talhão, você não pode criar novas semeaduras, deseja continuar mesmo assim ?',
      error:
        'Você não pode mais habilitar fazendas nem talhões. Caso deseje habilitar mesmo assim, entre em contato com nosso time de suporte.',
    },
    disable: {
      success: 'Fazenda desabilitada com sucesso.',
      warning:
        'Ao desabilitar o talhão, você não pode criar novas semeaduras, deseja continuar mesmo assim ?',
    },

    farm_disabled: 'Fazenda desabilitada',
    farm_disabled_message_banner_text1: 'Habilite a fazenda para registrar',
    farm_disabled_message_banner_text2:
      'Você só poderá registrar se sua fazenda estiver habilitada. Clique no botão abaixo para gerenciar fazendas.',
    manager_farms: 'Gerenciar fazendas',
    cadatro_fazenda: 'Cadastro de Fazenda',
    nome_da_fazenda: 'Nome da fazenda',
    preco_por_saca: 'Preço por saca',
    create_farm: 'Cadastrar fazenda',
    manual_create_farm: 'Cadastrar sua fazenda</br>manualmente',
  },

  cultivar: {
    cultivar: 'Cultivar',
    gmr: 'GMR',
    populacao: 'População',
    populacao_entre: '{0} - {1} mil ',
    epoca_semeadura: 'Época de semeadura',
    ciclo: 'Ciclo',
    ciclo_dias: '{0} dias',
    regiao_adaptativa: 'Região adaptativa',
    titular: 'Titular',
    asset_owner: 'Titular',
    habito_crescimento: 'Hábito de crescimento',
    growth_habit: {
      determinado: 'Determinado',
      semideterminado: 'Semideterminado',
      indeterminado: 'Indeterminado',
    },
    listar: {
      erro: 'Erro ao listar cultivares.',
    },
    excluir: {
      sucesso: 'Cultivar excluída com sucesso',
      erro: 'Erro ao excluir cultivar',
    },
    salvar: {
      sucesso: 'Cultivar salva com sucesso',
      erro: 'Erro ao salvar cultivar',
    },
    solicitacao: {
      sucesso: 'Solicitação enviada com sucesso',
      erro: 'Erro ao enviar solicitção',
    },
    contem: {
      erro: 'Fazenda não contém a cultivar selecionada',
    },
    carregar: {
      erro: 'Erro ao carregar cultivares',
    },
    copiar_para: {
      copiar_cultivar: 'Copiar Cultivar',
      help: 'As cópias da cultivar são adaptadas para as fazendas selecionadas. Por isso, cada cenário poderá apresentar diferenças.',
      selecione_fazenda:
        'Selecione as fazendas para receber uma cópia da cultivar ',
      selecione_as_fazendas: 'Selecione as Fazendas',
      sucesso: 'Cultivar copiada com sucesso!',
      erro: 'Erro ao copiar cultivar',
    },
    solicitar: {
      msg: 'Solicitar cultivar não catalogada',
      label: 'Cultivar não catalogada',
    },
    not_recommended: 'Não recomendado para sua região',
    go_to_planning: 'Planejar controle de doenças',
    create_variety: 'Cadastrar cultivar',
  },

  seed: {
    seed: 'Semente',
    seed_grower: 'Sementeiro',
    seed_class: 'Classe da semente',
    select_seed_class: 'Selecione a classe da semente...',
    select_seeder_system: 'Selecione o sistema da semeadora...',
  },

  seed_class: {
    certified: 'Certificada',
    inspected: 'Fiscalizada',
    selected: 'Selecionada',
  },

  seeder_system: {
    perforated_disk: 'Disco perfurado',
    endless_thread: 'Rosca sem fim',
    pinching_fingers: 'Dedos prensores',
    perforated_belt: 'Correia perfurada',
    fluted_cylinder: 'Cilindro canelado',
    pneumatic_seeder: 'Semeadora pneumática',
    others: 'Outros',
  },

  pesticide: {
    pesticide: 'Defensivo',
  },

  adjuvant: {
    adjuvant: 'Adjuvante',
  },

  sowing: {
    new: 'Nova Semeadura',
    active_sowing: 'Semeaduras ativas',
    out_of_time: 'Fora de época',
    message_heady_to_harvest:
      'Parece que você possui <strong>{0} semeaduras</strong> prontas para colheita. Registre a informação de colheita para liberar área e registrar novas semeaduras no talhão.',
    message_sowing_heady_to_harvest:
      'Essa semeadura já está em época de colheita. <strong>Por favor, registre a colheita</strong>',
  },

  semeadura: {
    era_sowing: 'Época de semeadura',
    estimated_cycle_in_days: 'Ciclo estimado (em dias)',
    germinative_power_in_percentage: 'Poder germinativo (em %)',
    seed_vigor_in_percentage: 'Vigor da semente (em %)',
    line_spacing: 'Espaçamento entre linhas (em cm)',
    operating_speed: 'Velocidade de operação (em km/h)',
    population_lines: 'População na linha (plantas/metro linear)',
    date_realized: 'Data de semeadura realizada',
    system_sowing: 'Sistema da semeadora',
    semeadura: 'Semeadura',
    carregar: {
      erro: 'Erro ao carregar semeaduras',
    },
    contem: {
      erro: 'Fazenda não contém a semeadura selecionada',
    },
    excluir: {
      sucesso: 'Semeadura excluída com sucesso',
      erro: 'Erro ao excluir semeadura.',
    },
    data_fora_semeadura: 'Data fora da época de semeadura',
    sowing_data: 'Dados de semeadura',
    operation_data: 'Dados da operação',
    origin: 'Origem',
    farm_sowing_time: 'Data fora da época de semeadura da fazenda',
    inactive_sowing_period: 'Período de semeadura inativo.',
    escolha_data_prevista: 'Escolha uma data prevista de semeadura',
    cadastro: {
      selecione_cultivar: 'Selecione uma cultivar',
      warning:
        'Caso exista um programa associado, suas datas, eficácias e doenças foram recalculadas',
      error: {
        estimated_cycle_min: 'Ciclo estimado deve ser maior que 0',
        population_lines_min: 'População em linhas deve ser maior que 0',
        population_lines_max: 'População em linhas deve ser no máximo 25,00',
        line_spacing_max: 'Espaçamento entre linhas deve ser no máximo 150',
        operating_speed_max: 'Velocidade de operação deve ser no máximo 25',
        rest_sown_area: 'Área semeada não pode ser maior que o talhão',
      },
      notify: {
        sown_area_is_null: 'Área semeada nula',
        quantity_area_is_null: 'Quantidade da área nula',
        exceeded_area: 'Área excedida',
      },
    },
    sown_area: 'Área semeada (ha)',
    sown_quantity: 'Quantidade semeada (kg/ha)',
    inoculant: 'Inoculante',
  },

  validations: {
    in_range: 'Valor deve estar entre {min} e {max}',
    max_value: 'Valor deve ser menor que {max}',
    invalid_month: 'Mês inválido',
    invalid_year: 'Ano inválido',
    month_previous_than_current: 'Mês menor que o atual',
    card_number_length: 'São necessários pelo menos 14 dígitos',
    text_only: 'O campo aceita somente texto',
    max_length: `O campo ultrapassa o limite de {0} caracteres`,
    invalid_email: 'E-mail inválido',
    invalid_document_number: 'CPF inválido',
    document_number_length: 'CPF deve ter 11 dígitos',
    invalid_card_number: 'Número do cartão inválido',
  },

  soybean: {
    soybean: 'Soja',
    growth_habit: 'Hábito de crescimento',
    population: 'População',
    seed_tratament: 'Tratamento de semente',
  },

  growth_habit: {
    indeterminate: 'Indeterminado',
    determinate: 'Determinado',
    semi_determinate: 'Semi-determinado',
  },

  program: {
    planned_programs: 'Programas planejados',
  },

  programa: {
    duplicate: {
      label: 'Duplicar',
    },
    programa: 'Programa',
    programas: 'Programas',
    novo_programa: 'Novo programa',
    nome_programa: 'Nome do programa',
    carregar: {
      erro_400: 'Falha ao carregar programas (status 400)',
    },
    excluir: {
      sucesso: 'Programa excluído com sucesso',
      erro: 'Erro ao excluir programa.',
      erro_400: 'Falha ao excluir programa (status 400)',
    },
    salvar: {
      erro_400: 'Falha ao salvar programa (status 400)',
    },
    copiar_para: {
      titulo: 'Copiar programa',
      help: 'As cópias do programa são adaptadas para a fazenda e cultivares selecionadas. Por isso, cada cenário poderá apresentar diferenças entre as eficácias.',
      selecione_cultivares: 'Selecione as cultivares',
      marcar_todas: 'Marcar todas',
      instrucao:
        'Selecione as cultivares de cada fazenda para receber uma cópia do programa',
    },
    report: {
      produtos_doses: 'Produtos e doses por ha',
      projecao_para: 'Projeção para',
      em: 'em {0}',
    },
    doencas_prioridade: {
      stepper_titulo: 'Prioridade de doenças',
      doencas_recomendadas: 'Doenças recomendadas',
      descricao:
        'Para seguir as prioridades recomendadas pelo sistema, mantenha a opção abaixo ativada. Caso contrário, selecione uma doença prioritária e duas secundárias.',
      select_label: 'Doenças',
      select_error: 'Selecione apenas {0} doenças',
      recomendadas: 'Recomendadas',
      personalizadas: 'Personalizadas',
    },
    numero_aplicacoes_sugeridas: 'Número de aplicações sugeridas',
    desassociar: 'Desassociar programa',
    white_mold_control_title: 'Você gostaria de controlar <b>Mofo Branco</b>',
    white_mold_control_description:
      'Ao marcar "Sim", vamos considerar aplicações específicas para mofo branco com comportamento específico em relação as demais',
    white_mold_total_sprayings: 'para Mofo Branco',
    permissions: {
      cant_create: 'Você não tem permissão para adicionar programas',
    },
  },
  severidade: {
    alta: 'Alta severidade',
    media_alta: 'Média-Alta severidade',
    media: 'Média severidade',
    media_baixa: 'Média-Baixa severidade',
    baixa: 'Baixa severidade',
  },

  application: {
    closets_applications: 'Aplicações para os próximos 15 dias',
  },

  aplicacao: {
    choose_product: 'Escolha um produto',
    aplicacao: 'Aplicação',
    aplicacoes: 'Aplicações',
    recalculo_conforme_safra:
      'A cada aplicação executada, as eficácias e datas de aplicação são recalculadas conforme a realidade da safra.',
    adicionar_aplicacao: 'Adicionar aplicação',
    nova_aplicacao: 'Nova aplicação',
    sugerir: {
      erro: 'Erro ao gerar sugestão',
    },
    doencas_prioridade: {
      select_label: 'Doenças principais',
      select_error: 'Selecione apenas {0} doenças',
    },
  },
  diseases: {
    soybean_asian_soybean_rust: 'Ferrugem',
    soybean_anthracnosis: 'Antracnose',
    soybean_target_spot: 'Mancha Alvo',
    soybean_powdery_mildew: 'Oídio',
    soybean_cercospora_blight: 'Cercóspora',
    soybean_septoria_leaf_spot: 'Septoriose',
    soybean_aerial_web_blight: 'Mela',
    soybean_white_mold: 'Mofo Branco',
    soybean_frogeye_leaf_spot: 'Mancha olho de rã',
    soybean_diaporthe: 'Crestamento de vagem e haste',
    wheat_leaf_rust: 'Ferrugem',
    wheat_powdery_mildew: 'Oídio',
    wheat_yellow_leaf_spot: 'Mancha Amarela',
    wheat_fusarium_head_blight: 'Giberela',
    wheat_blast: 'Brusone',
    alert: {
      white_mold_specific_spraying:
        'Está aplicação é específica para <b>Mofo Branco</b>',
      white_mold_mix_spraying:
        'Está aplicação é uma mistura para tratar <b>Mofo Branco</b> e outra doença',
    },
  },
  spraying: {
    without_efficacy: 'Sem eficácia',
    specific_white_mold_label: 'mofo branco',
  },
  pulverizacao: {
    sugestao: 'Sugestão de pulverizações',
  },
  paineis: {
    dados_climaticos_aviso:
      'Estarão disponíveis dados climáticos e outras informações do dia-a-dia no campo.',
  },
  planejamento: {
    succesfuly_removed: 'Planejamento excluído',
    erro: {
      erro_400: 'Falha ao excluir planejamento (status 400)',
    },
    salvar: {
      erro_400: 'Falha ao salvar planejamento (status 400)',
    },
  },
  fungicida: {
    selecione_produto: 'Selecione um produto.',
    informe_dose: 'Informe a dose.',
    informe_preco: 'Informe o preço.',
    atualiza_eficacia: 'As eficácias são atualizadas após salvar a aplicação.',
    doses: 'Doses',
    doses_recomendadas: 'Doses recomendadas',
    sem_registro: 'Sem registro',
    fungicida: 'Fungicida',
  },
  products_options: {
    mix: 'Mistura',
    single: 'Único',
    missing_targets:
      'Para visualizar as opções de produto é obrigatória a escolha de pelo menos uma doença.',
    error: {
      loading: 'Não foi possível carregar as opções de produtos.',
    },
    show_options: 'Mostrar opções de produtos',
    hide_options: 'Esconder opções de produtos',
    short_options: 'Opções de produtos',
    list: {
      title: 'Produtos',
    },
    description: {
      active_ingredients: 'Ingredientes ativos',
      potencial_efficacy: 'Eficácia potencial',
      no_register_info:
        'Há fungicidas que não possuem registro para todas as doenças indicadas, sendo estas destacadas em vermelho! As informações dos produtos seguem a bula registrada no MAPA (Ministério da Agricultura Pecuária e Abastecimento).',
    },
  },
  eficacia: {
    eficacias_apresentadas:
      'As eficácias apresentadas já consideram o uso dos adjuvantes recomendados pelo fabricante',
    ajuda:
      'Utilizamos a data prevista para calcular a eficácia esperada por cada programa.',
    escolha_data_prevista: 'Escolha a data prevista',
    escolha_severidade_esperada: 'Escolha uma severidade esperada',
    data_prevista_semeadura: 'Data prevista de semeadura',
    data_prevista_semeadura_nao_recomendada:
      'Data prevista de semeadura fora da época recomendada para a cultivar',
    severidade_esperada: 'Severidade esperada',
    sem_registro_ajuda:
      'Nesta aplicação há fungicidas que não possuem registro para todas as doenças indicadas, sendo estas destacadas em vermelho! As informações dos produtos seguem a bula registrada no MAPA (Ministério da Agricultura Pecuária e Abastecimento).',
  },
  menu: {
    my_farm: 'Minha Fazenda',
    planejamento: 'Planejamento',
    // CM: Esse cara vai substituir o "cultivares"
    sowings: 'Semeaduras',
    cultivares: 'Planejamento de Cultivares',
    // CM: Esse cara vai substituir o "programas"
    diseases_control: 'Fungicidas',
    compare: 'Comparar',
    programas: 'Programas',
    diseases: 'Planejamento de Doenças',
    safra: 'Safra',
    // CM: Esse cara vai substituir o "semeaduras"
    sowings_list: 'Minhas Semeaduras',
    semeaduras: 'Semeaduras',
    execucao: 'Execução',
    paineis: 'Paineis',
    notes: 'Anotações',
    catalog: 'Catálogo',
    performance: 'Performance',
    digifarmz_academy: 'DigiFarmz Academy',
    termos_de_uso: 'Termos de Uso',
    admin_panel: 'Painel Administrativo',
    fungicides: 'Fungicidas',
    nematicide: 'Nematicidas',
    plot: 'Talhões',
    comparison: 'Comparação',
  },

  contato: {
    informativo:
      'Entrar em contato conosco por ligação ou Whatsapp pelo número',
    msg_whatsapp_limit_enable_exceeded:
      '?text=Ol%C3%A1!+Gostaria+de+auxilio+pois+n%C3%A3o+consigo+mais+habilitar+fazendas+e+talh%C3%B5es. Utilizo+a+conta {0} .',
    msg_whatsapp_limit_area_exceeded:
      '?text=Ol%C3%A1%2C+gostaria+de+solicitar+auxilio+pois+minha+%C3%A1rea+excedeu%2C+a+conta+que+utilizo+%C3%A9 {0} e+a+%C3%A1rea+que+possuo+%C3%A9 {1}.',
    msg_whatsapp_login_commercial:
      '?text=Ol%C3%A1%2C+gostaria+de+solicitar+auxilio+pois+quero+me+cadastrar+na+plataforma.',
    msg_whatsapp_login_support:
      '?text=Ol%C3%A1%2C+gostaria+de+solicitar+auxilio+pois+n%C3%A3o+consigo+mais+logar+na+plataforma.',
    msg_whatsapp_hire_plan:
      '?text=Ol%C3%A1%2C+gostaria+de+solicitar+auxilio+pois+quero+contratar+o+plano+{0}.',
  },

  trocar_senha: {
    trocar_senha: 'Trocar Senha Atual',
    senha_atual: 'Senha atual',
    nova_senha: 'Nova Senha',
    confirma_nova_senha: 'Confirma Nova Senha',
    valida_senha_vazio: 'Senha atual não pode ser vazio',
    valida_senha_nova_vazio: 'Senha nova não pode ser vazio',
    valida_senha_confirmacao_vazio: 'Senha confirma não pode ser vazio.',
    aceito_termos: 'Li e Aceito os Termos de utilização do serviço',
    acesse_termos: 'Clique aqui para acessar o termo.',
    senha_min: 'Senha atual não pode ser menor que {0} caracteres.',
    confirme_termos: 'Confirme os termos de uso.',
    erro_generico: 'Falha ao alterar senha.',
  },

  indique: {
    indique_amigo: 'Indique um Amigo',
  },

  painel: {
    temperatura_c: 'Temperatura c°',
    temperatura_precipitacao: 'Temperatura e Precipitação (mm/dia)',
    precipitation: {
      adjust_btn: 'Corrigir precipitação',
    },
  },

  permission: {
    unauthorized_access: 'Você não possui acesso a essa funcionalidade.',
  },

  pais: {
    pais: 'país',
  },

  estados: {
    estados: 'Estados',
    carregar: {
      erro: 'Erro ao carregar estados.',
    },
  },

  municipios: {
    municipios: 'Municipios',
    carregar: {
      erro: 'Erro ao carregar municipios.',
    },
  },

  talhoes: {
    search_latitude: 'Buscar latitude',
    search_longitude: 'Buscar longitude',
    rest_area_plot: 'Área restante do talhão',
    quantity_total: 'Quantidade Total',
    talhoes: 'Talhões',
    plot: 'Talhão',
    salvar: {
      warning:
        'Formato do KML não conhecido ou formatado sem as especificações do sistema',
      sucesso: 'Talhão salvo com sucesso',
    },
    cadastro: 'Cadastro de Talhões',
    text_criar:
      'Talvez este seja um bom momento para criar os talhões de sua fazenda.',
    criar_pergunta: 'Criar talhões?',
    carregar_erro: 'Erro ao carregar talhão',
    salvar_erro: 'Erro ao salvar talhão',
    mapa: 'Mapa do Talhão',
    nome_do_talhao: 'Nome do talhão',
    remove_ponto: 'Remover ponto',
    excluir_title: 'Tem certeza?',
    excluir_text:
      'Esta é uma operação irreversível. Você quer excluir o talhão?',
    excluir_erro: 'Erro ao excluir talhão',
    text1: 'Mapeie novos talhões',
    text2:
      'Você pode adicionar talhões a partir de arquivos KML, ou marcando a área do talhão.',
    text3: 'O arquivo KML deve estar no formato Google.',
    text4: 'Carregar KML',
    text5: 'ou',
    alert_salvar_talhao:
      'Para salvar o talhão, adicione pelo menos 3 pontos no mapa!',
    area_exceeded:
      'Você ultrapassou a área total contratada da safra, para continuar as alterações entre em contato com o comercial pelo número ',
    enable: {
      success: 'Talhão habilitado com sucesso.',
      error:
        'Você não pode mais habilitar o talhão. Caso deseje habilitar mesmo assim, entre em contato com nosso time de suporte.',
    },
    disable: {
      success: 'Talhão desabilitado com sucesso.',
    },
    warnings: {
      insufficient_coordinates: 'Talhão {0} está com as coordenadas erradas',
      insufficient_polygons: 'Quantidade insuficiente de polígonos',
      no_latitude: 'O talhão {0} não possui latitudes',
      no_longitude: 'O talhão {0} não possui longitude',
    },
    config_area: {
      success: 'Área atualizada com sucesso',
    },
  },

  exception: {
    planting: {
      date_cant_be_modified: 'Data de semeadura não pode ser alterada.',
      has_applied_sprayings: 'Esta semeadura já possui aplicações realizadas.',
      has_vinculated_programs:
        'Semeaduras com programas vinculados não podem serem excluídas.',
      has_program: 'Essa semeadura possui programa vinculado.',
    },
    region: {
      invalid_variety: 'Região inválida para cultivar.',
      no_recommendation: 'Região sem recomendação',
    },
    payment: {
      pending: 'Pagamento pendente.',
    },
    action_unauthorized: 'Você não tem permissão para realizar essa ação.',
    invalid_session: 'Sua sessão expirou, faça login novamente.',
    unexpected_error:
      'Nos desculpe pelo inconveniente, aconteceu algo inesperado. Mas não se preocupe que nossa equipe já foi alertada e está trabalhando pra corrigir este problema.',
    unexpected_error_title:
      'Nos desculpe pelo inconveniente, aconteceu algo inesperado.',
    crop: {
      forbidden: 'Você não tem permissão para acessar o cultivo selecionado.',
    },
    plot: {
      has_planting: 'Talhão já possui semeaduras cadastradas.',
      exceeded_area:
        'Você ultrapassou a área total contratada da safra. Caso queira contratar mais, entre em contato pelo WhatsApp +55 51 99351-9481',
      invalid_geometry:
        'Cuidado! O desenho do talhão deve representar uma figura geométrica válida. Faça o ajuste no mapa para conseguir concluir.',
      action_not_allowed: 'Talhão desabilitado. Esta ação não é permitida.',
      has_planting_not_harvested: 'Talhão possui semeadura não colhida.',
    },
    variety: {
      already_added_in_farm: 'Cultivar já adicionada à fazenda.',
      already_added_in_program:
        'Não é possível deletar cultivares que possuam planejamento, por favor exclua os que contem a cultivar.',
      already_added_in_planting:
        'Não é possível deletar esta cultivar, pois ela está sendo utilizada em uma semeadura na etapa de safra.',
    },
    report: {
      data_not_avaliable: 'Fazenda não possui semeaduras colhidas.',
      plot_no_registered: 'Fazenda não possui talhões cadastrados.',
      sowing_no_registered: 'Programa não possui semeaduras cadastradas.',
    },
    season: {
      limited_access_enable_exceded:
        'Limite de habilitação por safra excedido.',
    },
    farm: {
      has_registered_sowing: 'Fazenda está sendo utilizada em uma semeadura',
      farm_does_not_belong_in_user: 'Fazenda não pertence ao usuário',
      action_not_allowed: 'Fazenda desabilitada. Esta ação não é permitida.',
    },
    sowing: {
      has_registered_program: 'Semeadura está sendo utilizada em um programa',
      has_harvest: 'Semeadura já possui colheita cadastrada',
    },
    spray: {
      invalid_white_mold_sprays:
        'DigiFarmz não recomenda mais que 3 pulverizações de Mofo Branco',
      date_before_last_spray:
        'Data anterior ou igual à data da última pulverização',
      date_before_minimal_sowing_date:
        'Data anterior à data minima de semeadura',
      defensive_has_been_duplicated: 'Defensivo foi duplicado',
      not_last_exclude: 'A aplicação não é a última cadastrada.',
    },
    program: {
      has_spraying: 'Programa possui aplicações',
    },
    file: {
      size_too_large: 'Tamanho máximo de arquivo permitido 5MB',
      mimetype: 'Tipo de arquivo não suportado',
    },
    invalid_credentials: 'Seu e-mail ou senha está incorreto. Tente novamente.',
    invalid_email: 'Seu e-mail está incorreto. Tente novamente.',
    invalid_password: 'Senha incorreta. Tente novamente.',
    password_confirmation_mismatch:
      'O campo de "Confirma Nova Senha" está diferente do campo "Nova Senha"',
    too: {
      many: {
        attempts: 'Você deve esperar 5 minutos antes de tentar novamente.',
      },
    },
    account: {
      activate: {
        active: {
          title: 'Usuário já ativo!',
          description:
            'Utilize as credenciais de acesso que enviamos para o e-mail <strong>{{accountData.context.email}}</strong>. Após relizar seu primeiro acesso será necessário redefinir senha para uma de sua preferência.',
        },
        activated: {
          title: 'Sua conta foi ativada com sucesso!',
          description:
            'Enviamos um e-mail para <b>{{accountData.email}}</b> com credenciais de primeiro acesso. Verifique sua caixa de entrada.',
        },
        code_invalid: {
          title: 'Código Inválido!',
          description:
            'Não foi possivel ativar sua conta, o código de ativação encontra-se expirado. Clique no botão abaixo para enviar um novo e-mail de ativação.',
        },
        not_active:
          'Usuário inativo! Para acessar a plataforma você precisa realizar ativação da sua conta. Se você não encontrar o e-mail de ativação na caixa de entrada ou na spam, clique nas opções de contato abaixo.',
        support:
          'Se você não encontrar o e-mail na caixa de entrada ou na clique nas opções de contato abaixo. Caso o problema contato contato com nosso suporte pelo e-mail <b>suporte@digifarmz.com</b>.',
        resend_email: 'Reenviar e-mail',
      },
    },
  },
  execucao: {
    estadio_nao_recomendado:
      'Aplicação não recomendada devido ao estádio fenológico e pressão de doenças.',
    total_delay: 'Acumulado de atrasos: {0} dias',
    salvar: {
      erro_400: 'Erro ao salvar',
    },
    programa: {
      importar: 'Importar programa',
      novo: 'Novo programa',
    },
  },
  growth_stage: {
    disabled: 'Indisponível',
    no_stage: 'Aguardando semeadura',
    save_sucess: 'Observação salva com sucesso',
    save_failure: 'Falha ao salvar observação',
    soybean: {
      ve: 'VE',
      vc: 'VC',
      v1: 'V1',
      v2: 'V2',
      v3: 'V3',
      v4: 'V4',
      v5: 'V5',
      v6: 'V6',
      v7_v9: 'V7-V9',
      r1: 'R1',
      r2: 'R2',
      r3: 'R3',
      r4: 'R4',
      r5: 'R5',
      r5_1: 'R5.1',
      r5_2: 'R5.2',
      r5_3: 'R5.3',
      r5_4: 'R5.4',
      r5_5: 'R5.5',
      r6: 'R6',
      r7: 'R7',
      r8: 'R8',
      r9: 'R9',
    },
    wheat: {
      0: 'Germinação',
      1: 'Primeiras folhas',
      2: 'Início do perfilhamento',
      3: 'Afilhos formados',
      4: 'Crescimento das bainhas',
      5: 'Pseudocaule bem desenvolvido',
      6: '1º Nó formado',
      7: '2º Nó formado',
      8: 'Folha bandeira visível',
      9: 'Lígula da folha bandeira visível',
      10: 'Emborrachamento',
      '10_1': '1ª Espiga visível',
      '10_2': '25% do espigamento',
      '10_3': '50% do espigamento',
      '10_4': '75% do espigamento',
      '10_5': '100% do espigamento',
      '10_5_1': 'Início do florescimento',
      '10_5_2': 'Florescimento completo até o topo da espiga',
      '10_5_3': 'Florescimento concluído na base da espiga',
      '10_5_4': 'Florescimento finalizado',
      '11_1': 'Grãos leitosos',
      '11_2': 'Grãos em massa',
      '11_3': 'Grãos duros',
      '11_4': 'Maturação',
    },
  },
  irrigation: {
    no_irrigation: 'Nenhuma irrigação realizada',
  },
  irrigacao: {
    irrigacao: 'Irrigação',
    irrigacoes: 'Irrigações',
    salva_sucesso: 'Irrigação salva com sucesso',
    excluida_sucesso: 'Irrigação excluída com sucesso',
    valida_data_time: 'O inicio da irrigação não pode ser menor que o término',
    valida_lamina: 'O volume da lamina não pode ser zero',
    salvar_erro: 'Erro ao salvar irrigação',
    data_inicio: 'Data de Inicio',
    data_fim: 'Data de Término',
    hora_inicio: 'Hora de Inicio',
    hora_fim: 'Hora de Término',
    precipitacao: 'Precipitação mm',
  },

  harvest: {
    harvested: 'colhido',
    sucess_message: 'Colheita de {0} sc/ha em {1}',
    register_harvest: 'Registrar colheita',
    register_harvests: 'Registrar colheitas',
    lost_harvest: 'Perdi minha colheita',
    lost: 'Perdeu colheita',
    did_desiccation: 'Fez a dessecação',
    ready_to_harvest: 'Pronto para colheita',
  },

  colheita: {
    excluida_sucesso: 'Excluída com sucesso',
    dessecacao: 'Dessecação pré-colheita',
    data_colheita: 'Data colheita',
    sacas_por_hectare: 'Sacas por hectare',
    colheita: 'Colheita',
    remover_colheita: 'Remover colheita',
  },

  stage: {
    stage: 'Estádio',
    stages: 'Estádios',
    real_stage: 'Estádio Atual',
    growth_stage: 'Estádio Fenológico',
    growth_stages: 'Estádios Fenológicos',
  },

  season_report: {
    bags_per_hectare: 'Sacas por hectare',
    harvest_date: 'Data da colheita',
    when_harvest: 'Quando colheu',
    desiccation: 'Dessecação',
    finished: 'Concluir',
    insert_harvest_data_title: 'Inserir dados da colheita',
    jump: 'Pular',
  },

  qt_dias: 'dia | dias',
  tutorial: 'Tutorial',
  account: {
    release_area: 'Liberar área',
  },

  maintenance: {
    title: 'No momento a plataforma está em manutenção',
    description:
      'Para melhor atendermos, estamos realizando algumas melhorias na plataforma. Pedimos que aguarde <b>alguns minutos</b> e tente acessar novamente.',
    verify_availability: 'Verificar disponibilidade',
  },
  prescription_export: 'Baixar prescrição',
  prescription_export_next: 'Baixar próxima prescrição',
  test: 'Teste',
  academy: {
    title:
      'Parece que você não possui acesso ao <span style="color: #39af49">DigiFarmz Academy</span>',
    description:
      'Mas não se preocupe, entre em contato com nosso time de sucesso do cliente para fazer a liberação da sua conta',
    btn: 'Solicitar acesso',
    whatsapp:
      'Olá DigiFarmz, gostaria de solicitar acesso ao DigiFarmz Academy, meu e-mail é ',
  },
  planting: {
    area_greather_than_plot:
      'A area semeada não pode ser maior que a area restante do talhão',
    sown_area: 'Área semeada',
    sowing_date: 'Data de semeadura',
  },

  required_fields: {
    special_characters: 'Não é permitido acentos ou caracteres especiais',
    email: 'Digite um formato de e-mail válido',
    farms: 'Preencha ao menos uma Fazenda',
    at_least_one: 'Preencha ao menos uma fazenda',
    harvest: {
      date: 'Data de colheita deve ser preenchida',
      productivity:
        'Produtividade deve ser maior que 0. Caso tenha perdido, selecione a opção de "perda de colheita".',
      validate_empty_fields_multi_harvest:
        'O campo de data e "sc/ha" é obrigatório se houver colheita. Caso tenha perdido, selecione a opção de "perda de colheita".',
    },
  },

  disease_control: {
    action: 'O que deseja planejar?',
    varieties: 'Cultivares',
    diseases: 'Doenças',
    sowings_planning: 'Semeaduras',
    create_variety: 'Cadastrar cultivar',
    select_variety: 'SELECIONE CULTIVAR',
    select_crop: 'SELECIONE CULTIVO',
    no_created_variety_title_subtitle: 'AÇÃO NECESSÁRIA',
    no_created_variety_title: 'Cadastre suas cultivares primeiro',
    no_created_variety_text:
      'Para possibilitar o controle de doenças, você precisa primeiro cadastrar suas cultivares',
  },

  date_without_year: 'DD/MM',

  card_info_plan: {
    title_main: 'Evolua seu plano',
    title_description:
      'Contrate novas funcionalidades para aumentar a produtividade',
    subtitle_main: 'A partir de',
    button: 'Conheça os planos',
    money_unit: 'R$',
    per_month: '/mês',
  },
  help_button: {
    tour: 'Tour guiado',
    shortly: 'Em breve',
    contact: {
      title: 'Falar com atendimento',
      link: 'https://api.whatsapp.com/send/?phone=5551994567668&text=Ol%C3%A1%2C+estou+vindo+da+plataforma+e+preciso+de+ajuda',
    },
  },
  freemium: {
    dialog: {
      title:
        'Você atingiu o número máximo diário de acessos a informações do produto.',
      description:
        'Conheça nosso <strong>plano Light</strong> e tenha acesso ilimitado a todas as informações do catálogo',
    },
    component: {
      title: 'Plano grátis',
      subtitle: 'Produtos visualizados diariamente',
      exceeded_limit: 'Limite diário atingido!',
      remaining_limit: 'restantes',
    },
    button: {
      title: 'Comprar catálogo',
    },
  },

  config_area: {
    header: 'Configuração da Área',
    title: 'Selecione os talhões que deseja utilizar a área contratada',
    article:
      'Você utilizará a área contratada para usufruir dos benefícios do plano Full nos talhões selecionados.',
    area_contracted: 'Área contratada',
    area_remaning: 'Área restante',
    info: 'Talhões que possuem uma área maior que a área contratada não poderão ser selecionados. Caso queira, edite a área para se adequar à área restante contratada.',
    wrapper_list: {
      area_total: 'Área total',
      edit_area: 'Editar área',
    },
    actions: {
      save: 'Salvar',
      cancel: 'Cancelar',
    },
  },

  alert_ndvi: {
    title: 'Não é possível editar a área desse talhão',
    message:
      'Talhões não poderão ter a área editada no período de 1 ano se tiverem NDVI contratado.',
    confirm: 'Entendi',
  },

  unsed_area: {
    title: 'Você não utilizou a área contratada para esse talhão',
    message:
      'Configure seus talhões ou utilize os benefícios da área contratada em outros talhões.',
    action: 'Configurar área',
  },
  message_date_emergency: {
    dialog: {
      title: 'Atenção',
      description:
        'Recomendamos cadastrar o plantio somente após a confirmação da emergência, pois uma data incorreta pode resultar em erros nas datas de aplicação. Se necessário, a data de emergência poderá ser ajustada posteriormente ao cadastro.',
    },
  },

  modal_full_plan: {
    title: 'Parabéns! Você agora possui o plano Full',
    message:
      'Configure a sua área contratada para os talhões que você tem cadastrado e utilize todos os benefícios do plano Full',
    actions: {
      config_later: 'Configurar depois',
      config_now: 'Configurar agora',
    },
  },
}
