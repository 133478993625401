<template>
  <df-modal
    persistent
    :action-name="$t('SowingsPlanning.add_date_sowing_modal.add')"
    :loading="loadingContent"
    :title="$t('SowingsPlanning.add_date_sowing_modal.new_sowing_date')"
    @action-click="addSowing"
    @close="closeAddDateSowingModal"
  >
    <template #card-content>
      <v-form ref="addDateSowingForm">
        <df-date-picker
          v-model="sowingEstimatedDate"
          :allowed-dates="allowedDates"
          :events="eventDates"
          :hint="
            $t('SowingsPlanning.date_picker_component.estimated_sowing_hint')
          "
          :label="
            $t('SowingsPlanning.date_picker_component.estimated_sowing_date')
          "
          :loading="loadingDates"
          :placeholder="$t('SowingsPlanning.date_picker_component.select_date')"
          :max="maxEstimatedSowingDate"
          :min="minEstimatedSowingDate"
          :rules="[required]"
        >
          <template #caption>
            <div class="df-flex-xm df-flex-col align-start date__caption">
              <div class="df-flex-m align-center justify-end">
                <div class="green__circle"></div>
                <p>
                  {{ $t('SowingsPlanning.sowing_form.recommended_dates') }}
                </p>
              </div>
              <div class="df-flex-m align-center justify-end">
                <div class="yellow__circle"></div>
                <p>
                  {{ $t('SowingsPlanning.sowing_form.has_existing_sowing') }}
                </p>
              </div>
            </div>
          </template>
        </df-date-picker>
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { required } from '@/utils/formRules'
import { logEvent, events } from '@/services/analytics'
import { isDateBetween } from '@/utils/time'

export default {
  name: 'AddDateSowingModal',

  components: {
    DfDatePicker,
    DfModal,
  },

  props: {
    fieldId: {
      required: true,
      type: Number,
    },
    variety: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      datesWithPlanningSowing: [],
      loadingContent: false,
      loadingRecommendedDates: false,
      loadingExistingSowings: false,
      maxEstimatedSowingDate: '',
      maxRecommendedDate: '',
      minEstimatedSowingDate: '',
      minRecommendedDate: '',
      required,
      sowingEstimatedDate: '',
    }
  },

  inject: {
    requestFieldSowingsPlanning: {
      from: 'requestFieldSowingsPlanning',
    },
  },

  async created() {
    await this.fetchEstimatedSowingDates()
    await this.fetchExistingSowings()
  },

  computed: {
    loadingDates() {
      return this.loadingRecommendedDates || this.loadingExistingSowings
    },
  },

  methods: {
    async addSowing() {
      if (!this.$refs.addDateSowingForm.validate()) return
      logEvent(
        events.sowingsPlanningModule.SowingsPlanning.finish_add_new_sowing_date
      )
      this.loadingContent = true
      try {
        const params = {
          is_planning: true,
          sowing_date: this.sowingEstimatedDate,
          sowing_id: this.variety.sowings[0].id,
        }
        await sowingsPlanningService.copySowingPlanning(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t(
            'SowingsPlanning.add_date_sowing_modal.successful_request_title'
          )
        )
        this.requestFieldSowingsPlanning()
        this.closeAddDateSowingModal()
      } catch (error) {
        console.error(error)
        this.$root.$emit(
          'notify',
          'error',
          this.$t('exception.unexpected_error_title'),
          this.$t('SowingsPlanning.exceptions.add_sowing')
        )
      } finally {
        this.loadingContent = false
      }
    },
    allowedDates(date) {
      return (
        !this.datesWithPlanningSowing.includes(date) &&
        isDateBetween(
          date,
          0,
          0,
          this.minEstimatedSowingDate,
          this.maxEstimatedSowingDate
        )
      )
    },
    closeAddDateSowingModal() {
      this.$emit('close')
    },
    eventDates(date) {
      const currentDate = new Date(date)
      const maxDate = new Date(this.maxRecommendedDate)
      const minDate = new Date(this.minRecommendedDate)

      const isInInterval = minDate <= currentDate && currentDate <= maxDate
      const hasSowing = this.datesWithPlanningSowing.includes(date)

      if (isInInterval && hasSowing) {
        return ['#39AF49', '#F2CD12']
      } else if (isInInterval) {
        return '#39AF49'
      } else if (hasSowing) {
        return '#F2CD12'
      }
    },
    async fetchEstimatedSowingDates() {
      this.loadingRecommendedDates = true
      const params = {
        variety_id: this.variety.variety_id,
      }
      try {
        const { data } = await sowingsPlanningService.getEstimatedSowingDates(
          params
        )
        this.minEstimatedSowingDate = data.limit_dates[0]
        this.maxEstimatedSowingDate = data.limit_dates[1]
        this.minRecommendedDate = data.recommended_dates[0]
        this.maxRecommendedDate = data.recommended_dates[1]
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingRecommendedDates = false
      }
    },
    async fetchExistingSowings() {
      this.loadingExistingSowings = true
      const params = {
        field_ids: [this.fieldId],
        variety_id: Number(this.variety.variety_id),
        range: [this.minEstimatedSowingDate, this.maxEstimatedSowingDate],
      }
      try {
        const { data } = await sowingsPlanningService.getPlanningSowings(params)
        data.forEach((sowing) => {
          this.datesWithPlanningSowing.push(sowing.estimated_date)
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingExistingSowings = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.date__caption {
  .green__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #39af49;
  }
  .yellow__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #f2cd12;
  }
  p {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
