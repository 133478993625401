<template>
  <v-expansion-panel class="field-sowing-planning">
    <v-expansion-panel-header class="d-flex planning__header">
      <div class="df-flex-sm align-center header__title">
        <h1>
          {{ fieldSowingPlanning.field_name }}
        </h1>
        <span>
          {{
            sowingQuantities +
            ' ' +
            $t('SowingsPlanning.field_sowing_planning.sowings')
          }}
        </span>
      </div>
      <df-button depressed variant="text-green" @click="redirectToField">
        {{ $t('SowingsPlanning.field_sowing_planning.see_field') }}
      </df-button>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="planning-content">
      <without-item-card
        v-if="showEmptyStateFilter"
        name="filter"
        text="SowingsPlanning.filter_not_found_text"
        title="SowingsPlanning.filter_not_found_title"
      />
      <without-item-card
        v-else-if="showEmptyStateAddField"
        name="add-sowing"
        text="SowingsPlanning.field_sowing_planning.sowing_not_found_text"
        title="SowingsPlanning.field_sowing_planning.sowing_not_found_title"
      />
      <v-expansion-panels v-else accordion flat multiple>
        <variety-list
          v-for="variety in fieldSowingPlanning.varieties"
          :key="variety.variety_id"
          :field-id="fieldSowingPlanning.field_id"
          :variety="variety"
        />
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import VarietyList from '@/modules/sowingsPlanning/components/list/VarietyList.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'FieldSowingPlanning',

  components: {
    DfButton,
    VarietyList,
    WithoutItemCard,
  },

  props: {
    currentFarmId: {
      required: true,
      type: Number,
    },
    fieldSowingPlanning: {
      required: true,
      type: Object,
    },
    periodFilter: {
      required: true,
      type: Object,
    },
  },

  inject: {
    getAllFields: { from: 'getFields' },
  },

  provide() {
    return {
      fieldObj: {
        name: this.fieldSowingPlanning.field_name,
        value: String(this.fieldSowingPlanning.field_id),
      },
      getFields: this.getFilteredFields,
    }
  },

  computed: {
    showEmptyStateAddField() {
      return (
        !this.fieldSowingPlanning.varieties.length &&
        this.periodFilter.value !== 'previous_sowings'
      )
    },
    showEmptyStateFilter() {
      return (
        !this.fieldSowingPlanning.varieties.length &&
        this.periodFilter.value === 'previous_sowings'
      )
    },
    sowingQuantities() {
      return this.fieldSowingPlanning.varieties.reduce((acc, variety) => {
        acc += variety.sowings.length
        return acc
      }, 0)
    },
  },

  methods: {
    getFilteredFields() {
      const fields = this.getAllFields()
      return fields.filter(
        (field) => field.id != this.fieldSowingPlanning.field_id
      )
    },
    redirectToField() {
      logEvent(events.sowingsPlanningModule.SowingsPlanning.see_field)
      this.$router.push({
        path: '/plot',
        query: {
          plot_id: this.fieldSowingPlanning.field_id,
          fazenda_id: this.currentFarmId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.field-sowing-planning {
  border-radius: 8px;
  border: 1px solid #e6e9e6;

  .v-expansion-panel {
    border-radius: 0px 0px 8px 8px;
  }
  .planning__header {
    padding: 16px 24px;

    @include d(m) {
      padding: 16px;
    }
    .header__title {
      flex-grow: 1;

      h1 {
        font-family: 'Rubik';
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #1a2b46;
      }
      span {
        font-family: 'Rubik';
        color: #5b6459;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .v-btn {
      margin: auto 8px auto 0px;
      flex-grow: 0;
    }
    p {
      color: #39af49;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .planning-content {
    border-top: 1px solid #e6e9e6;
  }
}
.without-item-card {
  border: none;
}
::v-deep .v-expansion-panel-header--active {
  min-height: 60px;
}
::v-deep .v-expansion-panel-header__icon {
  margin: 0px;

  & .v-icon {
    color: #5b6459 !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
