export default {
  SowingsPlanning: {
    phytosanitary_category: {
      disease: 'Fungicida',
      insect: 'Insetos',
      nematode: 'Nematóides',
      weed: 'Herbicida',
    },
    search_for_field: 'Pesquise por um talhão',
    search_for_crop: 'Pesquise por uma cultura',
    filter_not_found_text: 'Tente mudar os filtros',
    filter_not_found_title: 'Não encontramos resultados',
    date_picker_component: {
      estimated_sowing_date: 'Data da semeadura estimada',
      select_date: 'Selecionar data',
      estimated_sowing_hint: 'Período que você pretende semear',
    },
    home: {
      plan_sowings: 'Planejar semeaduras',
      filter_by: 'Filtre por',
      crop: 'Cultura',
      field: 'Talhão',
      plannings: 'Planejamentos',
      period: 'Período',
      without_field_text:
        'Crie um talhão para começar a realizar os planejamentos de suas semeaduras',
      without_field_title: 'Você ainda não tem um talhão',
      without_sowing_text:
        'Adicione uma semeadura e comece a realizar seus planejamentos',
      without_sowing_title: 'Você ainda não possui semeaduras nesse talhão',
      add_sowing: 'Adicionar semeadura',
      clean_filters: 'Limpar filtros',
      filters: {
        next_6_months: 'Próximos 6 meses',
        next_12_months: 'Próximos 12 meses',
        next_24_months: 'Próximos 24 meses',
        previous_sowings: 'Semeaduras passadas',
        custom_date: 'Data personalizada',
      },
    },
    field_sowing_planning: {
      see_field: 'Ver talhão',
      sowings: 'semeaduras',
      sowing_not_found_text:
        'Adicione uma semeadura e comece a realizar seus planejamentos',
      sowing_not_found_title: 'Você ainda não possui semeaduras neste talhão',
    },
    variety_list_item: {
      none: 'Nenhum',
      crop: {
        wheat: 'Trigo',
        corn: 'Milho',
        soybean: 'Soja',
      },
      programs_made: 'Planejamentos feitos',
      estimated_sowing_date: 'Data de semeadura estimada',
      executed: 'Executado',
      see_plannings: 'Ver planejamentos',
      delete: 'Excluir',
      sowing_actions: {
        edit: 'Editar',
        execute_in_season: 'Executar em safra',
        copy_to: 'Copiar para',
      },
      variety_actions: {
        new_sowing_date: 'Nova data de semeadura',
      },
    },
    add_sowing_modal: {
      add_sowing: 'Adicionar semeadura',
      add: 'Adicionar',
      edit_sowing: 'Editar semeadura',
      edit: 'Editar',
      successful_request_title: 'Semeadura adicionada',
    },
    sowing_form: {
      field: 'Talhão',
      select_one_field: 'Selecione um talhão',
      crop: 'Cultura',
      select_one_crop: 'Selecione uma cultura',
      genetic: 'Genética',
      select_one_genetic: 'Selecione uma genética',
      insert_genetic: 'Insira o nome da genética',
      company: 'Empresa',
      insert_company: 'Insira o nome da empresa',
      genetic_infos: 'Se precisar de mais informações sobre genéticas, ',
      click_here: 'clique aqui',
      request: 'Solicitar',
      genetic_not_found:
        'Não encontrou uma genética? Solicite ao nosso time de atendimento',
      days: 'Dias',
      estimated_cycle: 'Ciclo estimado',
      enter_estimated_cycle: 'Digite o ciclo estimado',
      recommended_dates: 'Datas recomendadas',
      has_existing_sowing: 'Data com semeadura',
    },
    add_date_sowing_modal: {
      new_sowing_date: 'Nova data de semeadura',
      add: 'Adicionar',
      successful_request_title: 'Data de semeadura criada',
    },
    custom_period_modal: {
      custom_period: 'Personalizar período',
      insert_date: 'Insira uma data',
      start: 'Início',
      end: 'Fim',
    },
    request_genetic_modal: {
      request: 'Solicitar',
      request_genetic: 'Solicitar genética',
      genetic: 'Genética',
      insert_genetic_name: 'Insira o nome da genética',
      company: 'Empresa',
      insert_company_name: 'Insira o nome da empresa',
      successful_request_title: 'Genética solicitada',
      successful_request_text:
        'Vamos analisar internamente sua solicitação e em breve traremos mais informações.',
    },
    delete_variety_modal: {
      title: 'Tem certeza que deseja excluir todas as semeaduras e programas?',
      text: 'Todos os dados e programas de planejamentos contidos nessa semeadura serão perdidos e essa ação não poderá ser desfeita.',
      created_sowings: 'Semeaduras criadas',
      created_programs: 'Programas criados',
      sowings_deleted: 'Semeaduras excluídas',
    },
    delete_sowing_modal: {
      title: 'Tem certeza que deseja excluir essa data de semeadura?',
      text: 'Caso tenha programas vinculados, eles também serão excluídos.',
      sowing_date: 'Data de semeadura',
      created_programs: 'Programas criados',
      sowing_date_deleted: 'Data de semeadura excluída',
    },
    copy_sowing_modal: {
      copy_sowing: 'Copiar semeadura',
      copy: 'Fazer cópia',
      created_plannings: 'Planejamentos feitos',
      select_field: 'Selecione o talhão',
      select_field_description:
        'Selecione abaixo os talhões que você deseja fazer uma cópia dessa semeadura',
      successful_request_title:
        'Semeadura copiada para os talhões selecionados',
    },
    execute_season: {
      title: 'Executar semeadura em safra',
      warning:
        'Atenção, você estará somente executando a semeadura. Caso você tenha feito planejamentos e deseja executá-los também, você precisa acessar os programas de planejamento desejado e executá-los individualmente.',
      execute: 'Executar em safra',
      sown_area: 'Área semeada',
      insert_area: 'Insira uma área',
      sowing_date: 'Data da semeadura',
      emergency_sowing_date: 'Data de emergência',
      complementary_data: 'Dados complementares',
      seed_class: 'Classe da semente',
      select: 'Selecione',
      germinative_power: 'Poder germinativo',
      type: 'Digite',
      seed_vigor: 'Vigor da semente',
      seed_system: 'Sistema de semeadura',
      line_spacing: 'Espaçamento entre linhas',
      population_line: 'População na linha',
      population_line_suffix: 'Plantas/m linear',
      sown_quantity: 'Quantidade semeada',
      operating_speed: 'Velocidade de operação',
      origin: 'Origem:',
      bought: 'Comprado',
      owned: 'Próprio',
      seed_treatment: 'Realizou tratamento de semente?',
      yes: 'Sim',
      no: 'Não',
      inoculant: 'Utilizou inoculante?',
      harvested: 'Já realizou a colheita?',
      harvested_quantity: 'Quanto foi colhido?',
      successful_request_title: 'Semeadura executada em safra',
      successful_request_text:
        'Você pode ver mais detalhes dessa semeadura em Minhas Semeaduras',
      saved_changes: 'Alterações salvas',
    },
    exceptions: {
      list_error:
        'Não conseguimos listar os planejamento de semeaduras. Tente novamente.',
      add_sowing: 'Não conseguimos registrar sua semeadura. Tente novamente.',
      request_genetic: 'Não conseguimos fazer a solicitação. Tente novamente.',
      no_field_selected: 'Nenhum talhão selecionado',
      over_field_max_area: 'O valor passa da área máxima do talhão',
    },
  },
}
