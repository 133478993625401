import http from '@/utils/http'
import {
  cropIdQuery,
  farmIdQuery,
  sameNameParamQuery,
  periodQuery,
  queryBuilder,
} from '@/utils/apiQueryBuilder'

const sowingsPlanning = {
  getSeedClasses: () => http.get('/seed-class'),
  getSeederSystems: () => http.get('/seeder-system'),
  getFieldSowingsPlanning: ({
    farm_id,
    crop_id,
    date_period,
    field_id,
    group_by,
    include,
    is_planning,
    program_categories,
    start_period,
    end_period,
    sowing_id,
  }) => {
    let url = `/sowing-tasks${queryBuilder(
      sameNameParamQuery({ is_planning }),
      farmIdQuery(farm_id),
      sameNameParamQuery({ group_by }),
      sameNameParamQuery({ include }),
      sameNameParamQuery({ date_period }),
      sameNameParamQuery({ crop_id }),
      sameNameParamQuery({ field_id }),
      sameNameParamQuery({ program_categories }),
      sameNameParamQuery({ sowing_id }),
      periodQuery(start_period, end_period)
    )}`
    return http.get(url)
  },
  getPlanningSowings: ({ field_ids, variety_id, range }) => {
    let url = `/planning-sowings${queryBuilder(
      sameNameParamQuery({ field_ids }),
      sameNameParamQuery({ variety_id }),
      sameNameParamQuery({ range })
    )}`
    return http.get(url)
  },
  getVarieties: ({ farm_id, filters, crop }) => {
    const baseUrl = `/varieties${queryBuilder(
      farmIdQuery(farm_id),
      cropIdQuery(crop)
    )}`
    let url = baseUrl
    if (Object.keys(filters).length) {
      url += `&filters=${filters}`
    }
    return http.get(url)
  },
  getCrops: () => http.get('/crops'),
  getEstimatedCycle: ({ variety_id }) =>
    http.get(
      `/estimated-cycle${queryBuilder(sameNameParamQuery({ variety_id }))}`
    ),
  getEstimatedSowingDates: ({ variety_id }) =>
    http.get(
      `/estimated-sowing-dates${queryBuilder(
        sameNameParamQuery({ variety_id })
      )}`
    ),
  getEmergenceDateLimits: ({ sowing_date, variety_id }) =>
    http.get(
      `emergence-date-limits${queryBuilder(
        sameNameParamQuery({ sowing_date }),
        sameNameParamQuery({ variety_id })
      )}`
    ),
  getFields: ({ farm_id, is_active }) => {
    const baseUrl = `/fields${queryBuilder(
      farmIdQuery(farm_id),
      sameNameParamQuery({ is_active })
    )}`
    return http.get(baseUrl)
  },
  getGenetics: () => http.get('/genetics'),
  editSowingPlanning: ({
    estimated_cycle,
    estimated_start_date,
    sowing_id,
  }) => {
    return http.patch(`/sowing-tasks/${sowing_id}`, {
      estimated_cycle,
      estimated_start_date,
    })
  },
  executePlanningSowing: ({ crop_zone, sowing_id, task }) => {
    return http.post(`/sowing-tasks/${sowing_id}`, {
      crop_zone,
      task,
    })
  },
  postSowingPlanning: ({ crop_zone, task }) => {
    return http.post('/sowing-tasks', {
      crop_zone,
      task,
    })
  },
  copySowingPlanning: ({ field_ids, is_planning, sowing_date, sowing_id }) => {
    return http.post(`/sowing-tasks/${sowing_id}/copy`, {
      // CM: ver parametros que backend precisa
      is_planning: is_planning,
      estimated_start_date: sowing_date,
      estimated_completed_date: sowing_date,
      field_id: field_ids,
    })
  },
  requestGenetic: ({ company_name, variety_name }) => {
    return http.post('/request-genetic', { company_name, variety_name })
  },
  deleteVariety: ({ field_id, variety_id }) => {
    return http.delete(
      `/delete-variety/${variety_id}${queryBuilder(
        sameNameParamQuery({ field_id })
      )}`
    )
  },
  deleteSowing: ({ sowing_id }) => {
    return http.delete(`/delete-sowing/${sowing_id}`)
  },
}

export default sowingsPlanning
