<template>
  <v-menu
    v-model="menu"
    bottom
    offset-y
    :close-on-content-click="!multiple && !searchBar"
    :max-width="menuWidth"
    :width="menuWidth"
    :min-width="220"
  >
    <template #activator="{ on }">
      <div ref="slotContainer">
        <slot :on="on"></slot>
      </div>
    </template>
    <v-list class="filter__list">
      <div v-if="searchBar" class="list__filter">
        <df-input
          v-model="searchTerm"
          class="filter__search-input"
          left-icon="search"
          :loading="loadingSearchTerm"
          :placeholder="searchPlaceholder"
          @change="debouncedFunction"
        />
      </div>
      <div v-if="filteredItems.length" class="list__content">
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          @click="clickItem(item)"
        >
          <v-list-item-title>
            <p>
              {{ item.name }}
            </p>
            <div
              v-if="showEmptyCheckbox(item)"
              class="content__checkbox-empty"
            ></div>
            <font-awesome-icon
              v-if="showSelectedCheckbox(item)"
              class="content__checkbox-selected"
              icon="check"
            />
            <font-awesome-icon
              v-if="showCheckIcon(item)"
              icon="check"
              :style="{ color: greenIconColor }"
            />
          </v-list-item-title>
        </v-list-item>
      </div>
      <div v-if="!filteredItems.length" class="filter__not-found">
        <p>
          {{ $t('Components.select_dropdown.results_not_found') }}
        </p>
      </div>
      <div v-if="showActions" class="df-flex-m filter__list-action">
        <v-btn
          v-if="clearable"
          class="df-col-6 action__button"
          plain
          @click="internalFilterClear"
        >
          {{ $t('Components.select_dropdown.clean') }}
        </v-btn>
        <df-button
          v-if="multiple"
          data-id="apply-filter-button"
          class="df-col-6"
          @click="applyFilter"
        >
          {{ $t('Components.select_dropdown.apply') }}
        </df-button>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import { debounce } from '@/utils/lodash'

export default {
  name: 'DfSelectDropdown',

  components: {
    DfButton,
    DfInput,
  },

  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return (
            typeof item.name === 'string' &&
            (typeof item.value === 'string' || item.value === null)
          )
        })
      },
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    searchBar: {
      default: false,
      type: Boolean,
    },
    searchPlaceholder: {
      default: '',
      type: String,
    },
    value: {
      required: true,
      type: [Array, Object],
    },
  },

  data() {
    return {
      componentValue: this.multiple ? [] : this.value,
      debouncedFunction: () => {},
      debouncedSearchTerm: '',
      loadingSearchTerm: false,
      menu: false,
      menuWidth: 220,
      searchTerm: '',
    }
  },

  created() {
    this.debouncedFunction = debounce(
      () => this.updateSearch(this.searchTerm),
      800,
      () => (this.loadingSearchTerm = true),
      () => (this.loadingSearchTerm = false)
    )
  },

  watch: {
    menu(value) {
      this.changeBackgroundColor(this.menu)
      if (value) {
        this.$nextTick(() => this.updateMenuWidth())
      }
    },
    value(newValue, oldValue) {
      if (
        this.multiple &&
        !this.componentValue.length &&
        Array.isArray(newValue) &&
        newValue[0] &&
        newValue[0].value
      ) {
        this.componentValue.push(newValue[0].value)
      } else if (!this.multiple && !Object.keys(oldValue).length) {
        this.componentValue = newValue
      } else if (!newValue || !Object.keys(newValue).length) {
        this.componentValue = this.multiple ? [] : newValue
      }
    },
  },

  computed: {
    filteredItems() {
      const searchTerm = this.debouncedSearchTerm.toLowerCase()
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      )
    },
    greenIconColor() {
      return '#39AF49'
    },
    showActions() {
      return this.filteredItems.length && (this.clearable || this.multiple)
    },
  },

  methods: {
    applyFilter() {
      this.$emit('apply-filter', [...this.componentValue])
      this.menu = false
    },
    changeBackgroundColor(isExpanded) {
      const element = this.$refs.slotContainer.firstChild
      if (element && element.type === 'button' && isExpanded) {
        element.setAttribute('style', 'background-color: #D8F2DC !important')
      } else element.setAttribute('style', 'background-color: #fff')
    },
    clickItem(item) {
      if (this.multiple) {
        if (this.componentValue.includes(item.value)) {
          const index = this.componentValue.indexOf(item.value)
          this.componentValue.splice(index, 1)
        } else {
          this.componentValue.push(item.value)
        }
      } else {
        this.componentValue = item
        this.$emit('click', item)
        this.menu = false
      }
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    externalFilterClear(value) {
      if (value || (value && Object.keys(value).length))
        this.componentValue = value
      else this.componentValue = []
      this.$emit('clear', this.componentValue)
    },
    internalFilterClear() {
      if (this.multiple) this.componentValue = []
      else this.componentValue = null
      this.$emit('clear', this.componentValue)
    },
    showCheckIcon(item) {
      return !this.multiple && item.name === this.componentValue.name
    },
    showEmptyCheckbox(item) {
      return this.multiple && !this.componentValue.includes(item.value)
    },
    showSelectedCheckbox(item) {
      return this.multiple && this.componentValue.includes(item.value)
    },
    updateMenuWidth() {
      const slotElement = this.$refs.slotContainer.children[0]
      this.menuWidth = slotElement ? slotElement.offsetWidth : 220
    },
    updateSearch(debouncedTerm) {
      this.debouncedSearchTerm = debouncedTerm
    },
  },
}
</script>

<style lang="scss" scoped>
.filter__list {
  .list__filter {
    padding: 16px;
    border-bottom: 1px solid #e6e9e6;
  }
  .list__content {
    max-height: 204px;
    overflow-y: auto;

    .content__checkbox-empty {
      max-height: 16px;
      min-height: 16px;
      max-width: 16px;
      min-width: 16px;
      border: 1px solid #aab2a9;
      border-radius: 4px;
    }
    .content__checkbox-selected {
      max-height: 16px;
      min-height: 16px;
      max-width: 16px;
      min-width: 16px;
      padding: 3px;
      color: #fff;
      background-color: #39af49;
      border-radius: 4px;
    }
  }
  .filter__not-found {
    padding: 16px 0px;

    p {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
  .filter__list-action {
    padding: 12px 16px;
    border-top: 1px solid #e6e9e6;

    .action__button {
      height: 40px;
      padding: 12px;
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      background-color: #fff;
      border: 1px solid #e6e9e6;
      border-radius: 4px;
      text-transform: capitalize;
      letter-spacing: 0px;
    }
  }
}
.v-menu__content {
  width: 100%;
  margin-top: 4px;
  border-radius: 8px !important;
  border: 1px solid #e6e9e6;
  box-shadow: 6px 4px 7px 0px rgba(219, 219, 219, 0.4) !important;
}
.df-button {
  height: 40px !important;
}
::v-deep .action__button .v-btn__content {
  opacity: 1 !important;
}
::v-deep .v-list {
  padding: 0px;
}
::v-deep .v-list-item {
  min-height: 16px;
  padding: 12px 16px;
}
::v-deep .v-list-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  p {
    text-wrap: wrap;
  }
}
::v-deep .filter__search-input .v-text-field__details {
  display: none;
}
.v-btn::before {
  background-color: transparent !important;
}
::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
  border: 4px #fff solid;
  background-clip: padding-box;
  border-radius: 9999px;
}
::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #fff;
}
</style>
